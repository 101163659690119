import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Usuarios() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)

  const [comboGrupoUsu, setComboGrupoUsu] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'PK_USU', numeric: true, label: 'Código', align: 'left', minWidth: 80 },
    { id: 'NOME', numeric: false, label: 'Nome', align: 'left', minWidth: 200 },
    { id: 'ULTIMO_LOGIN', numeric: false, label: 'Último Login', align: 'left', minWidth: 150 },
    { id: 'ATIVO', numeric: false, label: 'Ativo', align: 'left', minWidth: 100 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboGrupoUsu(await api.getComboData('grupo_usuarios'))
      setComboAtivo([

        {
          "value": "S",
          "display": "Sim",
        },
        {
          "value": "N",
          "display": "Não",
        },

      ])

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/macropecas_app/usuarios?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/usuarios', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/macropecas_app/usuarios');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Notas Fiscais</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.nome || ''}
                style={{ width: 330 }}
                name="nome"
                id="nome"
                color="#F1873B"
                label="Nome"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrSelect
                value={filter.pk_gru || ''}
                style={{ width: 170 }}
                name="pk_gru"
                id="pk_gru"
                label='Grupo de Usuário'
                data={comboGrupoUsu}
                onChange={handleChange}
              />
              <FrSelect
                value={filter.ativo || ''}
                style={{ width: 100 }}
                name="ativo"
                id="ativo"
                label='Ativo'
                data={comboAtivo}
                onChange={handleChange}
                />
                {filter.pk_gru == 2?
                <FrSelect
                value={filter.fk_pve || ''}
                style={{ width: 170 }}
                name="fk_pve"
                id="fk_pve"
                label='Grupo de Usuário'
                data={comboGrupoUsu}
                onChange={handleChange}
                  />
                  :
                  <></>
                }

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              <Link to="/macropecas_app/usuarios/registro">
                <button>
                  <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
              </Link>
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="Usuarios" columns={columns} searched={filtered} codeName="PK_UEX" page={'Usuarios'} data={data} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Usuarios;
