import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const MainSwitch = withStyles({
  switchBase: {
    color: '#389492',
    '&$checked': {
      color: '#389492',
    },
    '&$checked + $track': {
      backgroundColor: '#389492',
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '6px',
    marginBottom: '10px',
    marginTop: '-5px',
  },
  label: {
    margin: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),
    backgroundColor: '#F7EFEF',
    zIndex: 3,
    top: '-2px',
    '&.MuiInputLabel-shrink' : {
      padding: '5px',
      top: '0',
      color: '#389492'
    },
  },

}));

export default function SwitchLabels(props) {
  
  const classes = useStyles();

  return (
      <FormControlLabel
        control={
          <MainSwitch
            checked={props.checked}
            id={props.id}
            onChange={props.handleChange}
            name={props.name}
            {... props}
          />
        }
        label={props.label}
        className={classes.margin}
        style={props.style}
      />
  );
}