import React, { useContext, useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'
import { iosPeople } from 'react-icons-kit/ionicons/iosPeople'
import { iosPerson } from 'react-icons-kit/ionicons/iosPerson'
import { ic_work } from 'react-icons-kit/md/ic_work'
import {documentInverted} from 'react-icons-kit/entypo/documentInverted'
import {wrench} from 'react-icons-kit/icomoon/wrench'
import { ic_launch } from 'react-icons-kit/md/ic_launch'
import {ic_assignment_ind} from 'react-icons-kit/md/ic_assignment_ind';
import Slide from '@material-ui/core/Slide';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'
import { calculator } from 'react-icons-kit/icomoon/calculator'
import 'bootstrap-css-only/css/bootstrap.min.css';
import {ic_menu_book_twotone} from 'react-icons-kit/md/ic_menu_book_twotone'
import Tooltip from '@material-ui/core/Tooltip';
import UserOptions from '../components/UserOptions'
import { Link } from 'react-router-dom'
import './styles/Home.css';
import FooterDelphus from '../components/FooterDelphus';
import {balanceScale} from 'react-icons-kit/fa/balanceScale'
import {document} from 'react-icons-kit/iconic/document'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {ic_people} from 'react-icons-kit/md/ic_people'
import { mDATA } from '../utils/masks';
import { CombosContext } from '../App';
import api from '../services/api';



const swal = withReactContent(Swal)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Home(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authMacroApp'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [atalhos, setAtalhos] = useState([
    {
      label: 'Produtos',
      icon: wrench,
      path: '/produtos'
    },
    {
      label: 'Clientes',
      icon: ic_people,
      path: '/clientes'
    },
    {
      label: 'Notas Fiscais',
      icon: document,
      path: '/notas_fiscais'
    },
    {
      label: 'Pedidos/Orçamentos',
      icon: balanceScale,
      path: '/pedidos'
    },
    {
      label: 'Catálogos',
      icon: ic_menu_book_twotone,
      path: '/catalogos'
    },
    // {
    //   label: 'Termos de Associação',
    //   icon: documentInverted,
    //   path: '/associado_plano'
    // },
    // {
    //   label: 'Itens de Cobrança',
    //   icon: ic_work,
    //   path: '/itens_cobranca'
    // },
   
  ])

  useEffect(() => {
    // console.log(comboPro.length)
    // if (comboPro.length <= 1)
    //   setComboPro(api.getComboData('produtos'))
    // if (comboCli.length <= 1)  
    //   setComboCli(api.getComboData('clientes'))
  },[])

  function handleEmDesenvolvimento(){
    swal.fire('Em desenvolvimento!', '', 'warning')
  }

  
  const controles = (item, index) => {
    return (
      <Nav.Link href={"/macropecas_app/" + String(item.origem).toLowerCase() + '/registro?codigo=' + Number(item.cod_origem)} target="_blank" className="atalhoControle">
        <Icon icon={ic_launch} size={20} />
        <strong>{'[' + item.origem + '] ' + item.modelo + ' - ' + item.tipo + ' (Prazo Limite: ' + item.prazo_limite_formatado + ')'}</strong>
      </Nav.Link>
    )
  }

  return (
    <div className="Home">
      {/* {console.log(props)} */}
      <NavBar />
      <div className="HomeContent">
        <div className="defaultHeader">
          <h3>Seja bem vindo(a). Último login em: {mDATA(auth.last_activity)}.</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <div className="atalhos">

          {/*////////////////////////// ADICIONAR OBJETOS NA CONST 'ATALHOS' SEGUINDO PADRÃO */}
          
          {atalhos.map(atalho => {
            return (
            <Tooltip title="Segure Ctrl para abrir em nova aba" key={atalho.path}>
              <Link to={"/macropecas_app"+atalho.path} className="atalho" role="button">
                <div className='iconeAtalho'>
                  <Icon icon={atalho.icon} size={atalho.size || 40} />
                  <strong>{atalho.label}</strong>

                </div>
              </Link>
            </Tooltip>)
          })}
            
            <Tooltip title="Segure Ctrl para abrir em nova aba">
              <div className="atalho" onClick={handleEmDesenvolvimento}>
                <div className='iconeAtalho'>
                  <Icon icon={iosPerson} size={40} />
                  <strong>{'Gestor'}</strong>
                </div>
              </div>
            </Tooltip>
          
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Home;
