import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import FrTextInput from '../components/FrTextInput'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import AutoComp from '../components/AutoComp'
import FooterDelphus from '../components/FooterDelphus'

import api from '../services/api'
import { paramsToObject, objectToQueryString, onlyNumbers, populateForm, formToObject, isEmpty, getUserCode } from '../utils/functions'
import { mCNPJ, mCPF } from '../utils/masks'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import { CombosContext } from '../App';

const swal = withReactContent(Swal)

function Pedidos() {
  let history = useHistory()
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboRep, setComboRep] = useState([{ value: '', display: '' }])
  const [comboTipo, setComboTipo] = useState([{ value: 0, display: 'Todos' }, { value: 'S', display: 'Orçamento' }, { value: 'N', display: 'Pedido' },])
  const [comboRepresentante, setComboRepresentante] = useState([{ value: '', display: '' }])
  const [cli, setCli] = useState()
  const {comboCli} = useContext(CombosContext)

  const columns = [
    
    { id: 'PK_PED', numeric: false, label: 'Nr Pedido', align: 'left', minWidth: 80, },
    { id: 'NRO_BOSCH', numeric: false, label: 'Nr Bosch', align: 'left', minWidth: 80, },
    { id: 'RAZAO_SOCIAL', numeric: false, label: 'Cliente', align: 'left', minWidth: 300, },
    { id: 'FORMATDATA', numeric: false, label: 'Data', align: 'left', minWidth: 200, order: 'DATA'},
    { id: 'STRSTATUS', numeric: false, label: 'Status', align: 'left', minWidth: 150 },
    { id: 'STRTIPO', numeric: false, label: 'Tipo', align: 'left', minWidth: 150 },
    { id: 'VALORPED', numeric: true, label: 'Valor', align: 'left', minWidth: 150 , format: 'currency'},
    { id: 'ENVIADO', numeric: false, label: 'Enviado', align: 'left', minWidth: 150 },
  ]

  const columnsDetail = [
    { id: 'CODIGO_MACROPECAS', numeric: false, label: 'Código', align: 'left', minWidth: 300, },
    { id: 'NOME_MACROPECAS', numeric: false, label: 'Descrição', align: 'left', minWidth: 150 },
    { id: 'QUANTIDADE', numeric: false, label: 'Quantidade', align: 'left', minWidth: 150 },
    { id: 'IPI', numeric: false, label: 'IPI', align: 'left', minWidth: 200 },
    { id: 'VALOR', numeric: false, label: 'Valor', align: 'left', minWidth: 150 },
    { id: 'VALOR_STICMS', numeric: false, label: 'Valor ICMS', align: 'left', minWidth: 150 },
  ]


  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Pedidos'
      let consulta = 'S'
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              history.push('/macropecas_app/home')
            }
          })
      } else {
        const x = new Date();
        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        
        query.buscar = 'true'
        if(x){
          x.setMonth(x.getMonth()-1)
          query.data_ini = x.toISOString().split('T')[0]
        }

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))

        setComboRep(await api.getComboData('representadas'))
        // setenableAdd('S')
        // setenableDel('S')
        // setenableEdit('S')

        
        setFilter(query)
        
        populateForm(document.getElementById('searchForm'), query)
        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  
  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // var queryObject = { ...filter }

    if  (1 == 1) {//(!isEmpty(queryObject)) {
      queryObject.buscar = true
      queryObject.usu = getUserCode()
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/macropecas_app/pedidos?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


      api.get('/pedidos', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/macropecas_app/pedidos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case 'cnpj_cpf':
        auxValues[e.target.name] = onlyNumbers(e.target.value).length <= 11 ? mCPF(e.target.value) : mCNPJ(e.target.value)
        break
      // case 'telefone':
      //   auxValues[e.target.name] = mTel(e.target.value)
      //   break

      default: auxValues[e.target.name] = e.target.value;
    }
    console.log(auxValues)
    setFilter(auxValues);
  };

  
  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_cli"] = cli;
      // setFilter(state => auxValues)
      auxValues["nomecli"] = ''
      if (cli > 0) {
        await api.get("/clientes?pk_cli=" + cli).then((r) => {
          console.log(cli)
          if (r.data[0])
            auxValues["nomecli"] = r.data[0].RAZAO_SOCIAL;
            setFilter(auxValues)
        });
      }
    };
    fetchMdc();
  }, [cli]);


  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Pedidos</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div className='filters'>
              <div>
                <AutoComp
                  value={filter.nomecli || ""}
                  valor={filter.fk_cli || ''}
                  name="fk_cli"
                  id="fk_cli"
                  label='Cliente'
                  width={350}
                  data={comboCli}
                  setValue={setCli}
                />

                <FrDatePicker
                  value={(filter.data_ini || '')}
                  name="data_ini"
                  id="data_ini"
                  color="#528b46"
                  label="Data"
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  style={{ width: 190 }}
                />

                <FrDatePicker
                  value={(filter.data_fim || '')}
                  name="data_fim"
                  id="data_fim"
                  color="#528b46"
                  label="Data"
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  style={{ width: 190 }}
                />

                <FrSelect
                  value={filter.tipo || ''}
                  style={{ width: 160 }}
                  name="tipo"
                  id="tipo"
                  label='Tipo'
                  data={comboTipo}
                  onChange={handleChange} 
                />


              </div>
              
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/macropecas_app/pedidos/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable 
            width={160} 
            replicar={true} 
            regName="pedidos" 
            columns={columns} 
            searched={filtered} 
            codeName="PK_PED" 
            page={'Pedidos'} 
            data={data} 
            startOrderBy={'DATA'}
            startOrder={'desc'}
            // detail
            downloadPdf={true}
            // detailName={'itens_pedido'}
            // columnDetails={columnsDetail}
            enableEdit={enableEdit} 
            enableDel={enableDel} 
            rowsPerPage={25} 
            orderBy={'razao_social'} 
          />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Pedidos;