import React, { useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left'
import { ic_assignment } from 'react-icons-kit/md/ic_assignment'
import { ic_desktop_mac } from 'react-icons-kit/md/ic_desktop_mac'
import { Scrollbars } from 'react-custom-scrollbars';
import { ic_work } from 'react-icons-kit/md/ic_work'
import { ic_build } from 'react-icons-kit/md/ic_build'
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'
import Dialog from '@material-ui/core/Dialog';
import { FrDragFile } from '../FrDragFile';

import Nav from 'react-bootstrap/Nav'
import Collapse from 'react-bootstrap/Collapse'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import {GeracaoAnuidades} from '../GeracaoAnuidades'
import icon from '../../imgs/logo.JPG';
import logo from '../../imgs/logo.JPG';
import { Link } from 'react-router-dom'
import { NavBarLink } from '../NavBarLink';
import { routes } from '../../routes';
import {getUserCode} from '../../utils/functions.js'
import withReactContent from 'sweetalert2-react-content';
import { ImportaDados } from '../ImportaDados';
import Swal from 'sweetalert2';
import api from '../../services/api';
import FrLoading from '../FrLoading';

const swal = withReactContent(Swal)

function NavBar(props) {
    const [hide, setHide] = useState(true);
    const [animationStart, setAnimationStart] = useState(false)
    const [openCadastros, setOpenCadastros] = useState(false)
    const [openSistema, setOpenSistema] = useState(false)
    const [openOperacional, setOpenOperacional] = useState(false)
    const [openRelatorios, setOpenRelatorios] = useState(false)
    const [openAuxiliares, setOpenAuxiliares] = useState(false)
    const [openGerais, setOpenGerais] = useState(false)
    const [openFinanceiros, setOpenFinanceiros] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [fk_usu, setFk_usu] = useState(getUserCode())
    const [arquivo, setArquivo] = useState()

    const cadastros = routes.filter(item =>{
        return item.type == 'cadastro'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const operacional = routes.filter(item =>{
        return item.type == 'operacional'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const financeiros = routes.filter(item =>{
        return item.type == 'financeiros'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const gerais = routes.filter(item =>{
        return item.type == 'gerais'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const auxiliares = routes.filter(item =>{
        return item.type == 'auxiliares'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const relatorios = routes.filter(item =>{
        return item.type == 'relatorios'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })

    function toggleNavBarHide(e, item) {
        e.preventDefault();

        if (!hide) {
            setOpenCadastros(false)
            setOpenOperacional(false)
            setOpenSistema(false)
            setOpenAuxiliares(false)
            setOpenRelatorios(false)
        }
        setAnimationStart(true)
        setHide(!hide)
    }

    function abreCadastro(e, item) {
        e.preventDefault();
        setHide(false)
        setAnimationStart(true)
        setOpenCadastros(!openCadastros)

    }

    function abreOperacional(e, item) {
        e.preventDefault();
        setAnimationStart(true)
        setHide(false)
        setOpenOperacional(!openOperacional)

    }

    function abreFinanceiros(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenFinanceiros(!openFinanceiros)

    }

    function abreGerais(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenGerais(!openGerais)

    }

    function abreAuxiliares(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenAuxiliares(!openAuxiliares)

    }
    function abreSistema(e, item) {
        e.preventDefault();
        // setAnimationStart(true)
        setHide(false)
        setOpenSistema(!openSistema)

    }
    function abreRelatorios(e, item) {
        e.preventDefault();
        // setAnimationStart(true)
        setHide(false)
        setOpenRelatorios(!openRelatorios)

    }
    function mostraModal(){
        setShowModal(true)
    }

    function escondeModal(){
        setShowModal(false)
    }
    
    function handleEmDesenvolvimento(){
        swal.fire('Em desenvolvimento!', '', 'warning')
    }

    const handleSelectedFile = (file) => {
    
        // alert(file.name)
        
        if (file) {
            let ext = file.name.split(".")[1].toUpperCase()
            // console.log(ext.substr(-4))
            console.log(ext == "XLSX", ext == "XLS", ext == "XLS" || ext == "XLSX", ext)
          if((ext == "XLS" || ext == "XLSX")){
            let formData = new FormData();
            formData.append('file', file, file.name)
            console.log(file.name)
      
         
            setArquivo(file)
          } else {
            setShowModal(false)
            return swal.fire('Arquivo inválido', 'Arquivo deve ter extensão .XLS ou .XLSX!', 'error').then(r=>{
              setShowModal(true)
            })
    
          }
        }
        else {
          setArquivo('Nenhum arquivo selecionado')
        }
      }

      function confirmaModal(){
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
    
        setShowModal(false)
        let formData = new FormData();
          formData.append('file', arquivo, arquivo.name)
          api.post('/produtos/importacao/' , formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(r => {
            console.log('foi')
            // setArquivo(file.name)
            if(r.data == 'Importação Concluida!'){
              swal.fire("Sucesso!", "Arquivo importado com sucesso!", 'success')
            } else {
              swal.fire("Erro!", "Falha na importação do arquivo", 'error')
            }
          }).catch(r => {
            swal.fire("Erro!", "Falha na importação do arquivo", 'error')
          })
        console.log(arquivo)
      }

    return (
        <div className={"Navbar" + (animationStart ? '-a': '')+(hide ? '-hide no-print' : ' no-print')}>
            
            <div className={'fixedBar' + (hide ? '-hide ' : '')}>


                <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />}
                            renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
                            renderView={props => <div {...props} className="view" />}
                            autoHide style={{ width: "100%", height: "100%" }}>
                    <button onClick={toggleNavBarHide} className="toggle_navbar">
                        {hide ? <Icon icon={ic_chevron_right} size={40} /> : <Icon icon={ic_chevron_left} size={40} />}
                    </button>
                    <Nav defaultActiveKey="/macropecas_app/home" className="flex-column" >
                        <Tooltip title="Tela Principal">
                            <Link to="/macropecas_app/home">
                                {hide ? <img alt='Expandir' src={icon} className={"fr_icon_navbar"+ (animationStart ? '-a': '')} /> : <img alt='Encolher' src={logo} className={"fr_logo_navbar"+ (animationStart ? '-a': '')} />}
                            </Link>
                        </Tooltip>

                        <button onClick={abreCadastro} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openCadastros ? " Open" : "")}>

                            <Icon icon={ic_assignment} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Cadastros'}</strong>}
                        </button>
                        <Collapse in={openCadastros} >

                            <div className="toggleMenu">
                                {cadastros.map((item, key) =>{
                                    return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                })}

                               
                                
                            </div>
                        </Collapse>

                        <button onClick={abreOperacional} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openOperacional ? " Open" : "")}>

                            <Icon icon={ic_work} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Operacional'}</strong>}
                        </button>
                        <Collapse in={openOperacional}>
                            <div className="toggleMenu">
                                {operacional.map((item, key) =>{
                                    return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                })}
                                
                                <Tooltip title={'Gestor' }>
                                    <div style={{marginLeft: -10 }} className={(hide ? 'hide-' : '') + "toggleMenuItem"} onClick={handleEmDesenvolvimento}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Gestor'}</strong>}
                                    </div>
                                </Tooltip>
                               
                                <button onClick={abreRelatorios} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openRelatorios ? " Open" : "")}>

                                    <Icon icon={ic_assignment} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Relatórios'}</strong>}
                                </button>
                                <Collapse in={openRelatorios}>
                                    <div className="toggleMenu">
                                        {relatorios.map((item, key) =>{
                                            return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                        })}

                                    </div>
                                </Collapse>
                            </div>
                        </Collapse>

                        <button onClick={abreSistema} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openSistema ? " Open" : "")}>
                            <Icon icon={ic_desktop_mac} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Sistema'}</strong>}
                        </button>
                        <Collapse in={openSistema}>
                            <div className="toggleMenu">
                                
                                <NavBarLink caption='Grupo de Usuários' path='/grupo_usuarios' hide={hide}/>
                                <NavBarLink caption='Usuários' path='/usuarios' hide={hide}/>
                                <Tooltip title={'Importação planilha Bosch' }>
                                    <div className={(hide ? 'hide-' : '') + "toggleMenuItem"} onClick={mostraModal} style={{cursor: 'pointer'}}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Importar XLS'}</strong>}
                                    </div>
                                </Tooltip>
                                <ImportaDados fk_usu={fk_usu} />

                                <div>
                                <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >
                                    <div style={{ padding: '20px', paddingTop: '40px', backgroundColor:'var(--cor-tema)'  }}>
                                    <div className="defaultHeader">
                                        <h3>Importação de Planilha E-commerce</h3>
                                    </div>
                                    <br />

                                    <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start', backgroundColor:'var(--cor-tema)' }}>
                                        <FrDragFile 
                                        onChange={handleSelectedFile}
                                        value={arquivo || ''}
                                        />
                                    </div>
                                    </div>
                                    <div className="FrModalFooter">
                                    <div className="FrModalButtonsDiv" >
                                        <button onClick={escondeModal}> Sair </button>

                                        <button onClick={confirmaModal}> Confirmar </button>
                                    </div>
                                    </div>
                                </Dialog>
                                </div>
                            
                            </div>
                        </Collapse>

                    </Nav>
                </Scrollbars>
            </div>    

        </div>
    );
}

export default NavBar;