import styled from 'styled-components'



export const DivAdicionaFoto = styled.div`
    height: ${props => props.size? props.size+'px' : '300px'};
    width: ${props => props.size? props.size+'px' : '300px'};
    border-width: 1px;
    border-style: solid;
    border-color: #389492;
    border-radius: 6px; 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const DivMostraFoto = styled.div`
    height: ${props => props.size? props.size+'px' : '300px'};
    width: ${props => props.size? props.size+'px' : '300px'};
    border-width: 1px;
    border-style: solid;
    border-color: #389492;
    border-radius: 6px;
    gap: 30px;
    &:hover{
        button {
            position: relative;
            top: ${props => props.size ? `-${props.size}px` : '-300px'};
            right: ${props => props.size ? `${Number((props.size/2)-15)}px` : '135px'};
            border: none; 
            background-color: #389492; 
            font-size: 14;
            height: 40px;
            widht: 40px;
            opacity: 1;
            border-radius: 6px;
            transition: opacity 0.3s ease-in;
            margin-top: 1px;
            z-index: 999;
            margin-left: 1px;
        }
    }

    button {
        position: relative;
        top: ${props => props.size ? `-${props.size}px` : '-300px'};
        right: ${props => props.size ? `${Number((props.size/2)-15)}px` : '135px'};
        border: none; 
        background-color: #389492; 
        font-size: 14;
        height: 40px;
        widht: 40px;
        opacity: 0;
        z-index: 999;
        border-radius: 6px;
        margin-top: 1px;
        margin-left: 1px;
        transition: opacity 0.3s ease-out;
    }
`

export const Foto = styled.img`
    object-fit: cover; 
    height: ${props => props.size? props.size+'px' : '300px'};
    width: ${props => props.size? props.size+'px' : '300px'};
    border-radius: 6px; 
    border-style:solid; 
    border-color: #389492; 
    border-width: 1px;

`

export const BtnDelete = styled.button`
    svg{
        color: white;
    }
    &: hover{
        svg {
            color: black;
        }
    }
`

export const DivFotos = styled.div`
    display: flex;
    flex: 1;
    gap: 30px;
`