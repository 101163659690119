import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import AutoComp from '../components/AutoComp'
import FrDatePicker from '../components/FrDatePicker'

import api from '../services/api'
import { paramsToObject, objectToQueryString, onlyNumbers, populateForm, formToObject, isEmpty } from '../utils/functions'
import { mCNPJ, mCPF } from '../utils/masks'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import { CombosContext } from '../App';
import { SentimentSatisfiedAlt } from '@material-ui/icons';

const swal = withReactContent(Swal)

function NotasFiscais() {
  let history = useHistory()
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboVen, setComboVen] = useState([{ value: '', display: '' }])
  const {comboCli, comboPro, setComboCli} = useContext(CombosContext)
  const [auth, setAuth]  = useState(JSON.parse(localStorage['authMacroApp'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
  // const [comboCli, setComboCli] = useState()
  // const [comboPro, setComboPro] = useState(JSON.parse(localStorage.getItem('comboPro')))
  const [comboEst, setComboEst] = useState([{ value: '', display: '' }])
  const [ven, setVen] = useState(0)
  const [pro, setPro] = useState(0)
  const [cli, setCli] = useState(0)
  const [est, setEst] = useState(0)
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' },])
  const [comboEmp, setComboEmp] = useState([{ value: '', display: '' }])
  const [comboRepresentante, setComboRepresentante] = useState([{ value: '', display: '' }])
  const [isAdm, setIsAdm] = useState(false)
	// Nº da Nota / Data Emissão / Cliente / Total NF / Cancelada ( sim/não )
  const columns = [
    { id: 'NUMDOC', numeric: false, label: 'Nº Nota', align: 'left', minWidth: 100 },
    { id: 'FORMATDATAEMIS', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 200, orderBy: 'data' },
    { id: 'NOMECLI', numeric: false, label: 'Cliente', align: 'left', minWidth: 150 },
    { id: 'VALOR_TOTAL', numeric: true, label: 'Total NF', align: 'left', minWidth: 100, format: 'currency'},
    { id: 'STRCANCELADA', numeric: false, label: 'Cancelada', align: 'left', minWidth: 120 },
    

  ]


  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Notas Fiscais'
      let consulta = 'S'
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              history.push('/macropecas_app/home')
            }
          })
      } else {


        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        // setenableAdd('S')
        // setenableDel('S')
        // setenableEdit('S')

        // setComboCli(await api.getComboData('clientes'))
        let vendedores = await api.getComboData('vendedores')
        setComboVen(vendedores)
        setComboEst(await api.getComboData('estado'))
        
        console.log(vendedores)
       
        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)
        if(auth.fk_gpu != 1 ){
          console.log(auth.fk_gpu)
          const auxValues = { ...filter };
          setVen(auth.fk_ven)
          auxValues["fk_ven"] = auth.fk_ven;
          auxValues["nomeven"] = ''
          if (auth.fk_ven > 0) {
            await api.get("/vendedores?pk_ven=" + auth.fk_ven).then((r) => {
              console.log(r.data[0])
              if (r.data[0])
                auxValues["nomeven"] = r.data[0].RAZAO_SOC;
            });
          }
          console.log(auxValues)
          setFilter(auxValues);
          setIsAdm(false)
          query.fk_rep = auth.fk_ven
        } else {
          setIsAdm(true)
        }


        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
  }, [comboPro])

  
  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // var queryObject = { ...filter }
    console.log(queryObject)
    if (!isEmpty(queryObject)){ //(1 == 1) {//(!isEmpty(queryObject))
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/macropecas_app/notas_fiscais?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


      api.get('/notas_fiscais', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/macropecas_app/notas_fiscais');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case 'cnpj_cpf':
        auxValues[e.target.name] = onlyNumbers(e.target.value).length <= 11 ? mCPF(e.target.value) : mCNPJ(e.target.value)
        break
      // case 'telefone':
      //   auxValues[e.target.name] = mTel(e.target.value)
      //   break

      default: auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };

  // function handleChangeCombo(e) {
  //   async function getComboData(){
  //     console.log(e.target.value, 'ta chamando')
  //     if(e.target.value.length > 3)
  //       setComboCli(await api.getComboData("clientes", e.target.value))

  //   } 

  //   getComboData()
  // }

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_pro"] = pro;
      auxValues["nomepro"] = ''
      if (pro > 0) {
        await api.get("/Produtos?pk_pro=" + pro).then((r) => {
          if (r.data[0])
            auxValues["nomepro"] = r.data[0].NOME_MACROPECAS;
        });
      }
      setFilter(auxValues);
    };
    fetchMdc();
  }, [pro]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_est"] = est;
      auxValues["nomeest"] = ''
      if (est > 0) {
        await api.get("/estados?pk_est=" + pro).then((r) => {
          if (r.data[0])
            auxValues["nomeest"] = r.data[0].NOME;
        });
      }
      setFilter(auxValues);
    };
    fetchMdc();
  }, [est]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_cli"] = cli;
      auxValues["nomecli"] = ''
      if (cli > 0) {
        await api.get("/clientes?pk_cli=" + cli).then((r) => {
          if (r.data[0])
            auxValues["nomecli"] = r.data[0].RAZAO_SOCIAL;
        });
      }
      setFilter(auxValues);
    };
    fetchMdc();
  }, [cli]);

  useEffect(() => {
    const fetchMdc = async () => {
      console.log(ven)
      const auxValues = { ...filter };
      auxValues["fk_ven"] = ven;
      auxValues["nomeven"] = ''
      if (ven > 0) {
        await api.get("/vendedores?pk_ven=" + ven).then((r) => {
          console.log(r.data[0])
          if (r.data[0])
            auxValues["nomeven"] = r.data[0].RAZAO_SOC;
        });
      }
      console.log(auxValues)
      setFilter(auxValues);
    };
    fetchMdc();
  }, [ven]);

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Notas Fiscais</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div className='filters'>
              <div>
            
                
              <FrTextInput
                  value={filter.nr_nota || ''}
                  style={{ width: 100 }}
                  name="nr_nota"
                  id="nr_nota"
                  color="#49573B"
                  label="Nr. Nota"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                />

                <FrDatePicker
                  value={(filter.data_ini_emissao || '')}
                  name="data_ini_emissao"
                  id="data_ini_emissao"
                  color="#528b46"
                  label="Data Emissão Inicial"
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  style={{ width: 190 }}
      
                />
                <FrDatePicker
                  value={(filter.data_fim_emissao || '')}
                  name="data_fim_emissao"
                  id="data_fim_emissao"
                  color="#528b46"
                  label="Data Emissão Final"
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  style={{ width: 190 }}
      
                  />
                <AutoComp
                  value={filter.nomecli || ""}
                  valor={filter.fk_cli || ''}
                  width={406}
                  name="fk_cli"
                  id="fk_cli"
                  label='Cliente'
                  data={comboCli}
                  setValue={setCli}
                />

                </div>
                <div>
                  <FrTextInput
                    value={filter.nr_pedido || ''}
                    style={{ width: 100 }}
                    name="nr_pedido"
                    id="nr_pedido"
                    color="#49573B"
                    label="Nr. Pedido"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    />

                  <AutoComp
                    value={filter.nomepro || ""}
                    valor={filter.fk_pro || ''}
                    width={406}
                    name="fk_pro"
                    id="fk_pro"
                    label='Produto'
                    data={comboPro}
                    setValue={setPro}
                    />
                  <AutoComp
                    disabled={!isAdm}
                    value={filter.nomeven || ""}
                    valor={filter.fk_ven || ''}
                    width={406}
                    name="fk_ven"
                    id="fk_ven"
                    label='Representante'
                    data={comboVen}
                    setValue={setVen}
                  />
                </div>
                  
                <div>
                  <AutoComp
                    value={filter.nomeest || ""}
                    valor={filter.fk_est || ''}
                    width={406}
                    name="fk_est"
                    id="fk_est"
                    label='estado'
                    data={comboEst}
                    setValue={setEst}
                  />
                </div>





            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {/* {enableAdd !== 'S' ? '' :
                <Link to="/macropecas_app/notas_fiscais/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>} */}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable 
            widthOptions={130} 
            visualizar={'S'}
            downloadPdf={'S'}
            regName="notasfiscais" 
            columns={columns} 
            searched={filtered} 
            codeName="PK_NTF" 
            page={'Notas_Fiscais'} 
            data={data} 
            enableDel={'N'} 
            rowsPerPage={25} 
          />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default NotasFiscais;