import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  withStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content/";
import {externalLink} from 'react-icons-kit/fa/externalLink'
import api from "../../services/api";
import "./index.css";
import { Icon } from "react-icons-kit";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import { ic_description } from "react-icons-kit/md/ic_description";
import { dataHoraSql, getUserCode } from "../../utils/functions";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import { folderDownload } from "react-icons-kit/icomoon/folderDownload";
import FrTextInput from "../FrTextInput";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {colors} from '../../utils/vars'
import Tooltip from '@material-ui/core/Tooltip';
import {camera} from 'react-icons-kit/icomoon/camera'

const swal = withReactContent(Swal);

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#389492",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#389492",
    },
    width: "100%",
  },
  container: {
    maxHeight: "45vh",
    minHeight: "45vh",
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#389492",
    color: 'var(--cor-fonte)'
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableContainer = withStyles((theme) => ({
  root: {
    backgroundColor: 'var(--cor-tabela)',
    color:'var(--cor-fonte-hover)',
    transitionDuration:"0.4s",
   
},
}))(TableContainer)

const StyledTablePagination = withStyles((theme) => ({
  root: {
    backgroundColor: 'var(--cor-tabela)',
    transitionDuration:"0.4s",
   
},
}))(TablePagination)

const StyledTableRow = withStyles((theme) => ({
  root: {
      backgroundColor: 'var(--cor-tabela)',
      color:'var(--cor-fonte-hover)',
      transitionDuration:"0.4s",
      hover:{
        
        backgroundColor: 'var(--cor-tabela-hover)',
      }
  },
  container: {
    backgroundColor:'var(--cor-tabela)'
  }
  
}))(TableRow);

export default function FrTable(props) {
  const classes = useStyles();
  const scroll = useRef(null);
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // let history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 25);
  const [data, setData] = useState(props.data);
  const [columns, setColumns] = useState(props.columns);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(props.columns[0].id);

  useEffect(() => {
    if (props.regName == 'cliente_produto'){
      console.log('to aqui')
      setRowsPerPage(100)
    }
  },[])

  useEffect(() => {
    setData(props.data);
    if (props.regName == 'cliente_produto'){
      setPage(props.page)
    } else {
      setPage(0)
    }
  }, [props.data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.setPage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  function handleEdit(e, row) {
    e.preventDefault();
    console.log()

    if (props.setfk_pro)
      props.setfk_pro(row["fk_pro"]);

    if (props.setOrdenacao)
      props.setOrdenacao(row["ordenacao"]);

    if (props.setNomePro)
      props.setNomePro(row["nomepro"]);

    if (props.setPreco)
      props.setPreco(row["preco"]);

    if (props.setEdi)
      props.setEdi(row["edi"]);
      
    if (props.setFator)
      props.setFator(row["fator"]);
      
    if (props.setfk_und)
      props.setfk_und(row["fk_und"]);

    if (props.setpk_ass) {
      console.log(row)
      props.setpk_ass(row['pk_ass'])
      props.setShowModal(true);
    }
    if (props.setPk_asp) {
      props.setPk_asp(row['pk_asp'])
      props.setShowModal(true);
    }
    if(props.setPkModal){
      props.setPkModal(row["pk"]);
    }
    props.setUpdateModal(true);
    props.setModal(true);
  }

  function handleDelete(e, name, pk) {
    e.preventDefault();
    console.log(props.desvinculaCtr)
    swal
      .fire({
        title: `<h2 style='color: ${colors.info900}'>ATENÇÃO</h2>`,
        text: "Deseja excluir o registro selecionado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (props.desvinculaCtr){
          let i = []
            props.itens.forEach(item =>{
              if(item != name){
                i.push(item)
              }
            })
            console.log(i)
            props.setItens(i)
          api.post('itens_cobranca/desvincula?pk='+ name ).then(r =>{
            
          })
        } else {
          if (result.value) {
            // alert(props.regName)
            if (props.regName == 'cliente_produto') {
              api.post('/cliente_produto/delete/' + pk).then(r => {
                // console.log(r.data)
                if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
                  swal.fire({
                    title: `<h2 style='color:${colors.info900}'>Registro Excluído</h2>`,
                    text: "O registro foi excluído com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.location.reload()
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi excluído! " + r.data,
                    icon: 'warning',
                  })
                }
              }).catch(e => {
                console.log(e.response.data)
                if (e.response?.data?.sqlMessage && e.response?.data?.sqlMessage.indexOf('Cannot delete or update a parent row: a foreign key constraint fails') >= 0) {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi excluído pois possui outros registros vinculados! ",
                    icon: 'warning',
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "Houve um problema na exclusão do registro!",
                    icon: 'warning',
                  })
                }
              })
  
            } else {
  
              var sliced = [];
              for (var i = 0; i < data.length; i++)
                if (pk > 0) {
                  if (data[i].pk !== pk && data[i].pk > 0) {
                    sliced.push(data[i]);
                  }
                } else {
                  if (data[i].display !== name && data[i].display !== "") {
                    sliced.push({
                      value: data[i].value,
                      display: data[i].display,
                    });
                  }
                }
              if (sliced.length === 0) {
                sliced.push({
                  value: "",
                  display: "",
                  pk: 0,
                });
              }
  
              if (props.setDataGrid) {
                props.setDataGrid(sliced);
              }
            }
          }

        }
      });
  }

  const handleClick = (event, name, campo, row) => {
    console.log(event, name, campo, row)
    if (props.setPro) props.setPro(name);
    props.escondeModal()
  };

  return (
    <ThemeProvider theme={theme}>
      {data.length === 0 ? (
        <div>{props.searched ? props.emptyMessage : ""}</div>
      ) : (
        <Paper className={classes.root}>
          <StyledTableContainer className={classes.container}>
            {/* <Table stickyHeader aria-label="sticky table"> */}
            <Table
              stickyHeader={props.regName == 'cliente_produto'}
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >

              <TableHead >
                <StyledTableRow>
                  {!props.readOnly ? (
                    <StyledTableCell
                      style={{ minWidth: 100, width: 100 }}
                    ></StyledTableCell>
                  ) : (
                    <></>
                  )}
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                       {props.regName == 'cliente_produto' ?<TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(
                          column.order ? column.order : column.id
                        )} 
                       > 
                      {column.label}

                      </TableSortLabel> : <>{column.label}</>}
                    </StyledTableCell>
                  ))}

                  <StyledTableCell style={{ minWidth: 30, width: 30 }} />
                </StyledTableRow>
              </TableHead>
              <TableBody
                ref={scroll}
              >
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                   
                    //  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <StyledTableRow
                        tabIndex={-1}
                        onDoubleClick={(event) =>
                          handleClick(
                            event,
                            row[props.codeName],
                            props.codeName,
                            row
                          )
                        }
                        key={row[props.codeName]}
                      >
                        {!props.readOnly &&
                          (row[props.codeName] !== "" || row["pk"] > 0) ? (
                          <TableCell>
                            <Icon
                              className="tableDelete"
                              icon={ic_delete}
                              size={18}
                              onClick={(e) =>
                                handleDelete(e, row[props.codeName], row["pk"])
                              }
                            />
                            {props.editar ? (
                              <Icon
                                  className="tableDelete"
                                  icon={ic_mode_edit}
                                  size={18}
                                  onClick={(e) => handleEdit(e, row)}
                                />
                                ) : (
                                  <></>
                            )}
                            {console.log(data)}
                            {props.verFotos ? (
                              <Tooltip title='Fotos do produto'>
                                <a href={'/macropecas_app/fotos?codigo='+row.PRO} target='_blank'>
                                  <Icon
                                    className="tableDelete"
                                    icon={camera}
                                    size={18}
                                   
                                  />
                                </a>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                            {props.verLink ? (
                              <Tooltip title='Link do produto'>

                                <a href={row.link} target='_blank'>
                                  <Icon
                                    className="tableDelete"
                                    icon={externalLink}
                                    size={18}
                                  />
                                </a>
                              </Tooltip>

                            ) : (
                              <></>
                            )}
                          </TableCell>
                        ) : (
                          <></>
                        )}

                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })
                        }
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <StyledTablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </ThemeProvider>
  );
}
