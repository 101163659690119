import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import { Icon } from 'react-icons-kit'
import {plusSquareO} from 'react-icons-kit/fa/plusSquareO'
import {colors} from '../utils/vars'
import Dialog from '@material-ui/core/Dialog';
import Tabs from '../components/Tabs';
import Barcode from 'react-barcode';
import AutoComp from '../components/AutoComp'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Hidden } from '@material-ui/core';
import Fotos from '../components/Fotos';
import FrameF2 from '../components/FrameF2';
import { CombosContext } from '../App';
import { useHistory } from 'react-router-dom';

const swal = withReactContent(Swal)

function Produtos(props) {
  let history = useHistory()
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [lin, setLin] = useState(0)
  const [gru, setGru] = useState(0)
  const [rep, setRep] = useState(0)
  const [uni, setUni] = useState(0)
  const [img, setImg] = useState(0)
  const [proOrigem, setProOrigem] = useState(0)
  const [proSubs, setProSubs] = useState(0)
  const {comboPro} = useContext(CombosContext)
  const [imagens, setImagens] = useState([])
  const [showModalImg, setShowModalImg] = useState(false)
  const [comboLin, setComboLin] = useState([{ value: '', display: '' }])
  const [comboRep, setComboRep] = useState([{ value: '', display: '' }])
  const [comboGru, setComboGru] = useState([{ value: '', display: '' }])
  const [comboUni, setComboUni] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboProdutos, setComboProdutos] = useState([{}])

  // representada
	// observações
	// preco promo R1
	// preco promo R2
	// preco promo R3
	// preco promo R4
	// data validade promo
	// data validade desc

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Produtos', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Produtos', 'Inclusao'))
      }

      setComboLin(await api.getComboData('linhas_produtos'))
      setComboGru(await api.getComboData('grupos_produtos'))
      setComboUni(await api.getComboData('unidades'))
      setComboRep(await api.getComboData('representadas'))
      setComboProdutos(comboPro)

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/produtos?pk_pro=' + query.codigo).then(async (r) => {
          let auxValues={...r.data[0]}
          if (r.data[0]) {
            let dadosImagens = []
            
            
          //    api.get('/produtos/dadosFoto?foto=1&pk_pro=' + query.codigo).then(res=> {
          //     if(res.data[0]){
          //       api.get('/produtos/foto?pk_ipr=' + res.data[0].PK_IPR+'&nome='+res.data[0].NOME_ARQUIVO, {responseType: 'blob', contentType: 'image/jpeg"'}).then(r=>{
          //         dadosImagens.push({arquivo: res.data[0].NOME_ARQUIVO, url: URL.createObjectURL(r.data),  link: res.data[0].LINK, key: res.data[0].PK_IPR})
          //        
          //       })
          //     }
          //   })
          //   api.get('/produtos/dadosFoto?foto=2&pk_pro=' + query.codigo).then(res=> {
          //    if(res.data[0]){
          //      api.get('/produtos/foto?pk_ipr=' + res.data[0].PK_IPR+'&nome='+res.data[0].NOME_ARQUIVO).then(r=>{
          //        dadosImagens.push({arquivo: res.data[0].NOME_ARQUIVO, file: r.data,  link: res.data[0].LINK, key: res.data[0].PK_IPR})
          //      })
          //    }
          //   })
          //   api.get('/produtos/dadosFoto?foto=3&pk_pro=' + query.codigo).then(res=> {
          //    if(res.data[0]){
          //      api.get('/produtos/foto?pk_ipr=' + res.data[0].PK_IPR+'&nome='+res.data[0].NOME_ARQUIVO).then(r=>{
          //        dadosImagens.push({arquivo: res.data[0].NOME_ARQUIVO, file: r.data,  link: res.data[0].LINK, key: res.data[0].PK_IPR})
          //      })
          //    }
          //  })
          //    api.get('/produtos/dadosFoto?foto=4&pk_pro=' + query.codigo).then(res=> {
          //     if(res.data[0]){
          //       api.get('/produtos/foto?pk_ipr=' + res.data[0].PK_IPR+'&nome='+res.data[0].NOME_ARQUIVO).then(r=>{
          //         dadosImagens.push({arquivo: res.data[0].NOME_ARQUIVO, file: r.data,  link: res.data[0].LINK, key: res.data[0].PK_IPR})
          //       })
          //     }
          //   })
          //    api.get('/produtos/dadosFoto?foto=5&pk_pro=' + query.codigo).then(res=> {
          //     if(res.data[0]){
          //       api.get('/produtos/foto?pk_ipr=' + res.data[0].PK_IPR+'&nome='+res.data[0].NOME_ARQUIVO).then(r=>{
          //         dadosImagens.push({arquivo: res.data[0].NOME_ARQUIVO, file: r.data,  link: res.data[0].LINK, key: res.data[0].PK_IPR})
          //       })
          //     }
          //   })

            // setImagens([...dadosImagens])
            // await api.get("/linhas_Produtos?pk_lin=" + lin).then((res) => {
            //   if (res.data[0]){
            //     auxValues["NOMELIN"] = res.data[0].DESCRICAO;
    
            //   }
            // });
            // await api.get("/grupos_Produtos?pk_gru=" + gru).then((res) => {
            //   if (res.data[0]){
            //     auxValues["NOMEGRU"] = res.data[0].DESCRICAO;
    
            //   }
            // });
            // await api.get("/unidades?pk_uni=" + uni).then((res) => {
            //   if (res.data[0]){
            //     auxValues["UNIDADE"] = res.data[0].DESCRICAO;
            //     setData(auxValues);
            //   }
            // });
            // setLin(r.data[0].FK_LIN)
            setProOrigem(r.data[0].FK_PRO_ORIGEM)
            setProSubs(r.data[0].FK_PRO_SUBSTITUICAO)
            let auxValues = r.data[0]
            console.log(auxValues)

            setData(auxValues)
            swal.close()
            let imagem = {}
            imagem = await api.getFoto(1, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
              
            }
            imagem = await api.getFoto(2, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
              
            }
            imagem = await api.getFoto(3, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
              
            }
            imagem = await api.getFoto(4, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
              
            }
            imagem = await api.getFoto(5, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
            }
            setImagens(dadosImagens)
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  history.push('/macropecas_app/produtos')
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'CODIGO_MACROPECAS',
      'NOME_MACROPECAS',
      'CODIGO_REPRESENTADA',
      'NOME_REPRESENTADA',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function abreModalNovaImg(imagem) {
    setShowModalImg(true)
    setImg(imagem)
  }


  function escondeModalImg() {
    setShowModalImg(false)
    setImg({arquivo: '', link: ''})
  }



  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
       
		    const formData = new FormData();
        formData.append('PK_PRO', auxValues.PK_PRO|| '')
        formData.append('CODIGO_MACROPECAS', auxValues.CODIGO_MACROPECAS|| '')
        formData.append('NOME_MACROPECAS', auxValues.NOME_MACROPECAS|| '')
        formData.append('LINK', auxValues.LINK|| '')
        formData.append('CODIGO_REPRESENTADA', auxValues.CODIGO_REPRESENTADA|| '')
        formData.append('NOME_REPRESENTADA', auxValues.NOME_REPRESENTADA|| '')
        formData.append('OBSERVACAO', auxValues.OBSERVACAO|| '')
        formData.append('QUANT_MINIMA', auxValues.QUANT_MINIMA|| '')
        formData.append('ATIVO', auxValues.ATIVO|| '')
        formData.append('DATA_DESATIVACAO', auxValues.DATA_DESATIVACAO|| '')
        formData.append('FK_LIN', auxValues.FK_LIN|| '')
        formData.append('FK_UNI', auxValues.FK_UNI|| '')
        formData.append('FK_REP', auxValues.FK_REP|| '')
        formData.append('FK_GRU', auxValues.FK_GRU|| '')
        formData.append('FK_PRO_ORIGEM', proOrigem|| '')
        formData.append('FK_PRO_SUBSTITUICAO', proSubs || '')
        formData.append('OBSERVACAO_INATIVO', auxValues.OBSERVACAO_INATIVO|| '')
        formData.append('CLASSIFICACAO_FISCAL', auxValues.CLASSIFICACAO_FISCAL|| '')
        formData.append('CODIGO_BARRAS', auxValues.CODIGO_BARRAS|| '')
        formData.append('IPI', auxValues.IPI|| '')
        formData.append('PRECO_VENDA_LISTA', auxValues.PRECO_VENDA_LISTA|| '')
        formData.append('PRECO_REGIAO_1', auxValues.PRECO_REGIAO_1|| '')
        formData.append('PRECO_REGIAO_2', auxValues.PRECO_REGIAO_2|| '')
        formData.append('PRECO_REGIAO_3', auxValues.PRECO_REGIAO_3|| '')
        formData.append('PRECO_REGIAO_4', auxValues.PRECO_REGIAO_4|| '')
        formData.append('PRECO_VENDA_PROMO', auxValues.PRECO_VENDA_PROMO|| '')
        formData.append('PRECO_PROM_REGIAO_1', auxValues.PRECO_PROM_REGIAO_1|| '')
        formData.append('PRECO_PROM_REGIAO_2', auxValues.PRECO_PROM_REGIAO_2|| '')
        formData.append('DATA_VALID_PROMO', auxValues.DATA_VALID_DESC ? auxValues.DATA_VALID_PROMO.split('T')[0] : '')
        formData.append('PRECO_PROM_REGIAO_3', auxValues.PRECO_PROM_REGIAO_3|| '')
        formData.append('PRECO_PROM_REGIAO_4', auxValues.PRECO_PROM_REGIAO_4|| '')
        formData.append('PERC_DESC_PROMO', auxValues.PERC_DESC_PROMO|| '')
        formData.append('DATA_VALID_DESC', auxValues.DATA_VALID_DESC ? auxValues.DATA_VALID_DESC.split('T')[0] : '')
        formData.append('OBS_PROMOCIONAL', auxValues.OBS_PROMOCIONAL|| '')
        if (imagens[0]){
          formData.append('foto1', imagens[0].file, imagens[0].arquivo)
          formData.append('link1', imagens[0].link)
        }
        if (imagens[1]){
          formData.append('foto2', imagens[1].file, imagens[1].arquivo)
          formData.append('link2', imagens[1].link)

        }
        if (imagens[2]){
          formData.append('foto3', imagens[2].file, imagens[2].arquivo)
          formData.append('link3', imagens[2].link)

        }
        if (imagens[3]){
          formData.append('foto4', imagens[3].file, imagens[3].arquivo)
          formData.append('link4', imagens[3].link)

        }
        if (imagens[4]){
          formData.append('foto5', imagens[4].file, imagens[4].arquivo)
          formData.append('link5', imagens[4].link)

        }

        console.log(auxValues)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/produtos/edit', formData).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    // window.history.back()
                    history.push('/macropecas_app/produtos')
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/produtos/add', formData).then(r => {
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      // window.history.back()
                      history.push('/macropecas_app/produtos')
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleSelectedFile(e){
    let file = e.target.files[0];
    let i = {...img}
    if (file) {
        i.arquivo = file.name
        i.url = URL.createObjectURL(file)
        i.file = file
        setImg(i)
      }
    
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        // window.history.back()
        history.push('/macropecas_app/produtos')
      }
    })
  }

  useEffect(() => {
    if(comboPro){
      console.log(comboPro[0], 'combo pro primeiro item')
      
    }

  },[comboPro])


  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      case 'FK_PRO_ORIGEM': setProOrigem(e.target.value);
        break;
      case 'FK_PRO_SUBS': setProOrigem(e.target.value);
        break;
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const handleChangeImg = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setImg(auxValues);
  };

    

  useEffect(() => {
    const fetchAss = async () => {

      // setComboProdutos(comboPro)

      const auxValues = { ...data };
      if (proOrigem > 0) {
        auxValues['FK_PRO_ORIGEM'] = proOrigem;

        setData(auxValues);
      }
    }
    fetchAss()
  }, [proOrigem])

  useEffect(() => {
    const fetchAss = async () => {

      // setComboProdutos(comboPro)

      const auxValues = { ...data };
      if (proSubs > 0) {
        auxValues['FK_PRO_SUBSTITUICAO'] = proSubs;

        setData(auxValues);
      }
    }
    fetchAss()
  }, [proSubs])

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_REP"] = rep;
      auxValues["NOMEREP"] = ''
      if (rep > 0) {
        await api.get("/representadas?pk_rep=" + rep).then((r) => {
          if (r.data[0]){
            auxValues["NOMEREP"] = r.data[0].RAZAO_SOCIAL;
            setData(auxValues);

          }
        });
      }
    };
    fetchMdc();
  }, [rep]);

  function adicionaImagem(){
    let imgs = [...imagens]
    let i = {arquivo: img.arquivo, file: img.file, url: img.url, link: img.link, key: imgs[imgs.length-1]?.key+1 || 1}

    imgs.push(i)
    setImagens(imgs)
    setImg({arquivo: '', link: '', file: '', url: ''})
    setShowModalImg(false)
  }

  function excluirImagem(key){
    let imgs = imagens.filter(item => {return item.key !== key})
    setImagens(imgs)
  }

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_GRU"] = gru;
      auxValues["NOMEGRU"] = ''
      if (gru > 0) {
        await api.get("/grupos_Produtos?pk_gru=" + gru).then((r) => {
          if (r.data[0]){
            auxValues["NOMEGRU"] = r.data[0].DESCRICAO;
            setData(auxValues);
            
          }
        });
      }
    };
    fetchMdc();
  }, [gru]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_UNI"] = uni;
      auxValues["UNIDADE"] = ''
      if (uni > 0) {
        await api.get("/unidades?pk_uni=" + uni).then((r) => {
          if (r.data[0]){
            auxValues["UNIDADE"] = r.data[0].DESCRICAO;
            setData(auxValues);
          }
        });
      }
    };
    fetchMdc();
  }, [uni]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_LIN"] = lin;
      auxValues["NOMELIN"] = ''
      if (lin > 0) {
        await api.get("/linhas_produtos?pk_lin=" + lin).then((r) => {
          if (r.data[0]){
            auxValues["NOMELIN"] = r.data[0].DESCRICAO;
            setData(auxValues);
          }
        });
      }
    };
    fetchMdc();
  }, [lin]);


  return (
    <div>

      <div className="defaultReg">
        <NavBar />
        <div className="defaultRegContent">
          <div className="defaultHeader">
            <h3>{updateType ? 'Edição' : 'Inclusão'} de Produtos</h3>
            <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
          </div>
          <br />
          <form id="regForm" onSubmit={handleSubmit} className='regForm'>
          <Tabs aba1_caption="Informações Básicas" aba1_hidden={false} aba2_caption="informações Comerciais" aba2_hidden={false} aba3_hidden aba4_hidden aba5_hidden aba6_hidden aba7_hidden Aba1={
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginTop: 20}}>
              
              <div style={{display: 'flex'}}>  
                <div style={{maxWidth: 700, display: 'flex', justifyContent: 'flex-start',  flexDirection: 'column'}}>
                  
                  <div>
                    <FrTextInput
                      maxLength='20'
                      value={data.CODIGO_MACROPECAS || ''}
                      onChange={handleChange}
                      name="CODIGO_MACROPECAS"
                      id="CODIGO_MACROPECAS"
                      color="#389492"
                      label="Cód. Macropeças"
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: 180 }}
                    />
                    <FrTextInput
                      maxLength='100'
                      value={data.NOME_MACROPECAS || ''}
                      onChange={handleChange}
                      name="NOME_MACROPECAS"
                      id="NOME_MACROPECAS"
                      color="#389492"
                      label="Nome Macropeças"
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: 500 }}
                    />
                  </div>
                  <div>

                    <FrTextInput
                      maxLength='20'
                      value={data.CODIGO_REPRESENTADA || ''}
                      onChange={handleChange}
                      name="CODIGO_REPRESENTADA"
                      id="CODIGO_REPRESENTADA"
                      color="#389492"
                      label="Cód. Representada"
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: 180 }}
                    />
                    <FrTextInput
                      maxLength='100'
                      value={data.NOME_REPRESENTADA || ''}
                      onChange={handleChange}
                      name="NOME_REPRESENTADA"
                      id="NOME_REPRESENTADA"
                      color="#389492"
                      label="Nome Representada"
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: 500 }}
                    />
                  <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <AutoComp
                      value={data.NOMEREP || ""}
                      valor={data.FK_REP || ''}
                      width={350}
                      name="FK_REP"
                      id="FK_REP"
                      label='Representada'
                      data={comboRep}
                      reg
                      setValue={setRep}
                    />
                  </div>
                  </div>
                    <FrTextInput
                      value={data.OBSERVACAO || ''}
                      onChange={handleChange}
                      name="OBSERVACAO"
                      id="OBSERVACAO"
                      color="#389492"
                      multiline
                      rows="8"
                      rowsMax="8"
                      label="Observações"
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: 690 }}
                    />
                  <div style={{display: 'flex'}}>
                    <FrTextInput
                      maxLength='20'
                      value={data.QUANT_MINIMA || ''}
                      onChange={handleChange}
                      name="QUANT_MINIMA"
                      id="QUANT_MINIMA"
                      color="#389492"
                      label="Qtd. Mínima"
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: 150 }}
                    />

                    <FrSelect
                      value={data.ATIVO || ''}
                      style={{ width: 130 }}
                      name="ATIVO"
                      id="ATIVO"
                      label='Ativo'
                      data={[{DISPLAY: 'Sim', VALUE: "S"}, {DISPLAY: "Não", VALUE: 'N'}]}
                      onChange={handleChange}
                    /> 
                    <FrTextInput
                      maxLength='400'
                      value={data.LINK || ''}
                      onChange={handleChange}
                      name="LINK"
                      id="LINK"
                      color="#389492"
                      label="Link do Produto"
                      variant="outlined"
                      size="small"
                      required
                      style={{  width: 398 }}
                    />
                    </div>
                    <div style={{display: 'flex'}}>
                      <FrSelect
                        value={proOrigem || ''}
                        style={{ width: 530 }}
                        name="FK_PRO_ORIGEM"
                        id="FK_PRO_ORIGEM"
                        label='Produto Origem'
                        data={comboProdutos}
                        onChange={handleChange}
                      /> 
                      <FrameF2 tipoModal={'Produtos'} data={data} pro={proOrigem} setPro={setProOrigem} ></FrameF2>
                    </div>
                    <div style={{display: 'flex'}}>
                      <FrSelect
                        value={proSubs || ''}
                        style={{ width: 530 }}
                        name="FK_PRO_SUBSTITUICAO"
                        id="FK_PRO_SUBSTITUICAO"
                        label='Substituido Por'
                        data={comboProdutos}
                        onChange={handleChange}
                      /> 
                      <FrameF2 tipoModal={'Produtos'} data={data} pro={proSubs} setPro={setProSubs} ></FrameF2>
                    </div>
                    <div style={{display: 'flex'}}>


                    <FrDatePicker
                      value={(data.DATA_DESATIVACAO || '')}
                      name="DATA_DESATIVACAO"
                      id="DATA_DESATIVACAO"
                      color="#528b46"
                      label="Data Desativação"
                      variant="outlined"
                      onChange={handleChange}
                      size="small"
                      style={{ width: 190 }}
          
                    />

                    

                  </div>
                  <div>
                  <FrTextInput
                      value={data.OBSERVACAO_INATIVO || ''}
                      onChange={handleChange}
                      name="OBSERVACAO_INATIVO"
                      id="OBSERVACAO_INATIVO"
                      color="#389492"
                      multiline
                      rows="8"
                      rowsMax="8"
                      label="Observações Inativação"
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: 690 }}
                    />
                  </div>
                  
                </div>
                <div style={{maxWidth: 400, display: 'flex', flexDirection: 'column'}}>

                  <AutoComp
                    value={data.NOMELIN || ""}
                    valor={data.FK_LIN || ''}
                    width={250}
                    name="FK_LIN"
                    id="FK_LIN"
                    label='Linha do Produto'
                    reg
                    data={comboLin}
                    setValue={setLin}
                  />
                  <AutoComp
                    value={data.UNIDADE || ""}
                    valor={data.FK_UNI || ''}
                    width={250}
                    name="FK_UNI"
                    id="FK_UNI"
                    reg
                    label='Unidade de Medida'
                    data={comboUni}
                    setValue={setUni}
                  />
                  
                  <AutoComp
                    value={data.NOMEGRU || ""}
                    valor={data.FK_GRU || ''}
                    reg
                    width={250}
                    name="FK_GRU"
                    id="FK_GRU"
                    label='Grupo do Produto'
                    data={comboGru}
                    setValue={setGru}
                  />
                  <FrTextInput
                    maxLength='100'
                    value={data.CLASSIFICACAO_FISCAL || ''}
                    onChange={handleChange}
                    name="CLASSIFICACAO_FISCAL"
                    id="CLASSIFICACAO_FISCAL"
                    color="#389492"
                    label="Classificação Fiscal"
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: 250 }}
                  />

                  <div style={{maxWidth: 250}}>
                    <FrTextInput
                      maxLength='20'
                      value={data.CODIGO_BARRAS || ''}
                      onChange={handleChange}
                      name="CODIGO_BARRAS"
                      id="CODIGO_BARRAS"
                      color="#389492"
                      label="Código de Barras"
                      variant="outlined"
                      size="small"
                      required
                      style={{ width: 250 }}
                    />
                    { 
                      // https://www.npmjs.com/package/react-barcode ------------> doc do componente
                        data.CODIGO_BARRAS && <Barcode value={data.CODIGO_BARRAS} height={40}/>
                      

                    }

                  </div>

                </div>
              </div>
              {/* <div style={{ width: '100%', display: 'flex', flex: 1, gap: 20,}}> */}
                <Dialog aria-labelledby="simple-dialog-title" open={showModalImg} fullWidth={true} maxWidth={'sm'} >
                  <div style={{ padding: '20px', paddingTop: '20px', backgroundColor:'var(--cor-tema)'  }}>

                    <div className="defaultHeader">
                      <h3>Edição de Foto</h3>
                    </div>
                    <br />


                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column',  backgroundColor:'var(--cor-tema)' }}>

                      
                      <FrTextInput
                        maxLength='200'
                        value={img.link || ''}
                        onChange={handleChangeImg}
                        name="link"
                        id="link"
                        color="#389492"
                        label={'Link'}
                        variant="outlined"
                        size="small"
                        style={{ width: '100%' }}
                      />  
                      {/* <FrTextInput
                        value={img.arquivo || ''}
                        onChange={handleChangeImg}
                        name="img"
                        id="img"
                        color="#389492"
                        label={'Arquivo'}
                        variant="outlined"
                        size="small"
                        style={{width: '100%' }}
                      />   */}
                      <input
                        type="file"
                        name="arquivo"
                        id={"html_btn_foto"}
                        accept=".png, .jpg, .jpeg"
                        onChange={handleSelectedFile}

                      />

                      

                    </div>
                  </div>
                  <div className="FrModalFooter">
                    <div className="FrModalButtonsDiv" >
                      <button onClick={adicionaImagem}> Confirmar </button>
                      <button onClick={escondeModalImg}> Sair </button>
                    </div>
                  </div>
                </Dialog>
                <Fotos onOpen={abreModalNovaImg} imagens={imagens} onDelete={excluirImagem} size={150} sizeFoto={150}/>

                
                
                
              {/* </div> */}
            
            </div>        


          }
          Aba2={
            <div style ={{display: 'flex', alignItems: 'left', marginTop: 20}}>
              <div style={{marginRight: 50}}>

                <FrTextInput
                  value={data.IPI || ''}
                  onChange={handleChange}
                  name="IPI"
                  id="IPI"
                  color="#389492"
                  label="IPI"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 150 }}
                />
                
                <FrTextInput
                  value={data.PRECO_VENDA_LISTA || ''}
                  onChange={handleChange}
                  name="PRECO_VENDA_LISTA"
                  id="PRECO_VENDA_LISTA"
                  color="#389492"
                  label="Preço PR"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 150 }}
                />
                
                <br/>
                <FrTextInput
                  value={data.PRECO_REGIAO_1 || ''}
                  onChange={handleChange}
                  name="PRECO_REGIAO_1"
                  id="PRECO_REGIAO_1"
                  color="#389492"
                  label="Preço Lista R.1"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 150 }}
                />
                
                <FrTextInput
                  value={data.PRECO_REGIAO_2 || ''}
                  onChange={handleChange}
                  name="PRECO_REGIAO_2"
                  id="PRECO_REGIAO_2"
                  color="#389492"
                  label="Preço Lista R.2"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 150 }}
                />
                <br/>
                
                <FrTextInput
                  value={data.PRECO_REGIAO_3 || ''}
                  onChange={handleChange}
                  name="PRECO_REGIAO_3"
                  id="PRECO_REGIAO_3"
                  color="#389492"
                  label="Preço Lista R.3"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 150 }}
                />
                
                <FrTextInput
                  value={data.PRECO_REGIAO_4 || ''}
                  onChange={handleChange}
                  name="PRECO_REGIAO_4"
                  id="PRECO_REGIAO_4"
                  color="#389492"
                  label="Preço Lista R.4"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 150 }}
                />
              </div>
              <div>
                <div>

                <FrTextInput
                  value={data.PRECO_VENDA_PROMO || ''}
                  onChange={handleChange}
                  name="PRECO_VENDA_PROMO"
                  id="PRECO_VENDA_PROMO"
                  color="#389492"
                  label="Preço Promo"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 180 }}
                />
                <FrTextInput
                  value={data.PRECO_PROM_REGIAO_1 || ''}
                  onChange={handleChange}
                  name="PRECO_PROM_REGIAO_1"
                  id="PRECO_PROM_REGIAO_1"
                  color="#389492"
                  label="Preço Promo R.1"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 180 }}
                  />
                
                <FrTextInput
                  value={data.PRECO_PROM_REGIAO_2 || ''}
                  onChange={handleChange}
                  name="PRECO_PROM_REGIAO_2"
                  id="PRECO_PROM_REGIAO_2"
                  color="#389492"
                  label="Preço Promo R.2"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 180 }}
                />
                
              
                
                <br/>
                <FrDatePicker
                  value={(data.DATA_VALID_PROMO || '').split('T')[0]}
                  name="DATA_VALID_PROMO"
                  id="DATA_VALID_PROMO"
                  color="#528b46"
                  label="Data Valid. Promo."
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  style={{ width: 180 }}
      
                />
                
                <FrTextInput
                  value={data.PRECO_PROM_REGIAO_3 || ''}
                  onChange={handleChange}
                  name="PRECO_PROM_REGIAO_3"
                  id="PRECO_PROM_REGIAO_3"
                  color="#389492"
                  label="Preço Promo R.3"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 180 }}
                />
                
                <FrTextInput
                  value={data.PRECO_PROM_REGIAO_4 || ''}
                  onChange={handleChange}
                  name="PRECO_PROM_REGIAO_4"
                  id="PRECO_PROM_REGIAO_4"
                  color="#389492"
                  label="Preço Promo R.4"
                  variant="outlined"
                  size="small"
                  required
                  style={{ width: 180 }}
                />
                </div>
                <div style={{marginTop: 20}}>
                  <div style={{display: 'flex', alignItems: 'left'}}>
                  <FrTextInput
                    value={data.PERC_DESC_PROMO || ''}
                    onChange={handleChange}
                    name="PERC_DESC_PROMO"
                    id="PERC_DESC_PROMO"
                    color="#389492"
                    label="% Desc. Promocional"
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: 180 }}
                  />
                  <FrDatePicker
                    value={(data.DATA_VALID_DESC || '').split('T')[0]}
                    name="DATA_VALID_DESC"
                    id="DATA_VALID_DESC"
                    color="#528b46"
                    label="Data Valid. Desc."
                    variant="outlined"
                    onChange={handleChange}
                    size="small"
                    style={{ width: 180 }}
      
                  />
                  </div>

                  <FrTextInput
                    value={data.OBS_PROMOCIONAL || ''}
                    onChange={handleChange}
                    name="OBS_PROMOCIONAL"
                    id="OBS_PROMOCIONAL"
                    color="#389492"
                    multiline
                    rows="8"
                    rowsMax="8"
                    label="Observações Promocional"
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: 550 }}
                  />

                </div>
              </div>

              
            </div>
          }
          
          />
          </form>
          <div className="regButtonsDiv">
            {enableEdit === 'S' ?
              (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
            <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
          </div>
          <FooterDelphus />
        </div>
      </div>
    </div>

  );
}

export default Produtos;
