import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import FrTextInput from '../FrTextInput'
import TableHead from '@material-ui/core/TableHead';
import { retweet } from 'react-icons-kit/fa/retweet'
import {ic_email} from 'react-icons-kit/md/ic_email'
import TablePagination from '@material-ui/core/TablePagination';
import { folderDownload } from "react-icons-kit/icomoon/folderDownload";
import TableRow from '@material-ui/core/TableRow';
import {documentInverted} from 'react-icons-kit/entypo/documentInverted'
import download from 'downloadjs'
import FrLoading from '../FrLoading'
import {ic_north_east} from 'react-icons-kit/md/ic_north_east'
import {eye} from 'react-icons-kit/icomoon/eye'
import FrTextField from '../FrTextField'
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import {download3} from 'react-icons-kit/icomoon/download3'
import {blocked} from 'react-icons-kit/icomoon/blocked'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'
import { ic_gavel } from 'react-icons-kit/md/ic_gavel'
import { checkCircle } from 'react-icons-kit/fa/checkCircle'
import { ic_thumbs_up_down } from 'react-icons-kit/md/ic_thumbs_up_down'
import { ic_work } from 'react-icons-kit/md/ic_work'
import TableDetail from '../TableDetail'
import api from '../../services/api'
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";
import Tooltip from '@material-ui/core/Tooltip';
import {ic_attach_money} from 'react-icons-kit/md/ic_attach_money'
import {colors} from '../../utils/vars'
import {getUserCode, swalCarregando} from '../../utils/functions'
import {camera} from 'react-icons-kit/icomoon/camera'

import './index.css';
import { withTheme } from 'styled-components';

const icons = [
  { fk_sne: 1, icon: (<Icon size={32} style={{ color: '#2A9D8F' }} icon={ic_thumbs_up_down} />) },
  { fk_sne: 2, icon: (<Icon size={32} style={{ color: '#E9C46A' }} icon={ic_work} />) },
  { fk_sne: 3, icon: (<Icon size={32} style={{ color: '#EF233C' }} icon={ic_gavel} />) },
]

const swal = withReactContent(Swal)

const theme = createTheme({
  palette: {
    primary: { main: '#389492' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'var(--cor-fonte-hover)',
    '&$checked': {
      color: 'var(--cor-fonte-hover)',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles(theme => ({
  root: {
    color:'var(--cor-fonte-hover)',
    backgroundColor:'var(--cor-tabela)',
    transitionDuration:"0.4s",
    "& .Mui-selected": {
      backgroundColor: 'var(--cor-tabela-selected)',
      transitionDuration:"0.4s",

    },
    
    "& .Mui-selected:hover": {
      backgroundColor: 'var(--cor-tabela-selected-hover)',
      transitionDuration:"0.4s",

    },
    "& .MuiTableCell-body": {
      color: 'var(--cor-fonte-hover)',
    },

    width: '100%',
    hover: {
      transitionDuration:"0.4s",
     
    }
  },
  

  row:{
    backgroundColor: 'rgba(133, 130, 130, 0.9)',
  },

  container: {
    borderRadius: '4px',
    maxHeight: ({ frame, relacionados }) => relacionados ? 'none' : (frame ? '40vh' : '58vh'),
    minHeight: ({ frame, relacionados }) => relacionados ? 'none' : (frame ? '40vh' : '58vh'),
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const StyledTableCell = withStyles((theme) => ({

  head: {
    backgroundColor: '#389492',
    color: 'var(--cor-fonte)',
    transitionDuration:"0.4s",

  },
  body: {
    backgroundColor: 'var(--cor-tabela)',
    color: 'var(--cor-fonte)',
    transitionDuration:"0.4s",
    fontSize: 30,
  },
}))(TableCell);



export default function FrTable(props) {
  const classes = useStyles({ frame: props.frame, relacionados: props.relacionados });
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.frame ? 25 : 10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState(props.startOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(props.startOrderBy ||props.columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [opened, setOpened] = React.useState([]);
  const [width, setWidth] = React.useState(props.width || 100);
  const [showModal, setShowModal] = React.useState(false);
  const [arquivo, setArquivo] = React.useState('');
  const [showModalEmail, setShowModalEmail] = useState(false)
  const [email, setEmail] = useState(null)
  const [id, setId] = useState(null)
  const [nome, setNome] = useState(null)
  const [dataVenc, setDataVenc] = useState(null)

  useEffect(() => {
    console.log(props)
  }, [])

  useEffect(() => {
    setData(props.data)
    setPage(0)
    if(props.firstSel) setSelected(props.firstSel)
  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])

  const deleteRecord = (e, id, table) =>{
    e.preventDefault()
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja excluir PERMANENTEMENTE o registro selecionado?</p>`,
      // text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        api.post('/' + table + '/delete/' + id).then(r => {
          // console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              
              title: `<h2 style='color:${colors.info900}'>Registro Excluído</h2>`,
              html: <p style={{color: colors.info900}}>O registro foi excluído com sucesso!</p>,
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
              text: "O registro não foi excluído! " + r.data,
              icon: 'warning',
            })
          }
        }).catch(e => {
          if (e.response?.data?.sqlMessage && e.response?.data?.sqlMessage.indexOf('Cannot delete or update a parent row: a foreign key constraint fails') >= 0) {
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
              text: "O registro não foi excluído pois possui outros registros vinculados! ",
              icon: 'warning',
            })
          } else {
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
              text: "Houve um problema na exclusão do registro!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function handleVerBoletos (e, id, row){
    swalCarregando(swal)
    api.get('/boletos?fk_ctr='+id).then(r=>{
      swal.close()
      if (r.data.length <= 0) {
        swal.fire('Nenhum boleto', 'Não há boleto gerado para esse registro!', 'warning')
      } else {
        window.location.href = "/macropecas_app/contas_receber/boletos?buscar=true&fk_ctr=" + id
      }

    })
  }

  function handleLiquidar(e, id, row) {
    e.preventDefault()
    // const funcMensagens = {
    //   liquidado : function () {
    //     return swal.fire({
    //       title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
    //       html: `<p style='color:${colors.info900}'>Registro já está liquidado!</p>`,
    //       icon: 'warning',
    //     })
    //   },

    //   cancelado : function () {
    //     return swal.fire({
    //       title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
    //       html: `<p style='color:${colors.info900}'>Registro cancelado não pode ser liquidado!</p>`,
    //       icon: 'warning',
    //     })
    //   },

    //   update : function () {
    //     return swal.fire({
    //       title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
    //       html: `<p style='color:${colors.info900}'>Não foi possível concluir a ação no momento! Aguarde e tente novamente</p>`,
    //       icon: 'warning',
    //     })
    //   },

    //   nenhum : function () {
    //     return swal.fire({
    //       title: `<h2 style='color: ${colors.info900}'>Sucesso!</h2>`,
    //       html: `<p style='color:${colors.info900}'>Registro Liquidado!</p>`,
    //       icon: 'success',
    //     })
    //   },
    // }


    // let erro =''
    // let mensagem
    // swal.fire({
    //   title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
    //   html: `<p style='color:${colors.info900}'>Deseja liquidar registro?</p>`,
    //   // text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: colors.success500,
    //   cancelButtonColor: colors.danger500,
    //   confirmButtonText: 'Sim',
    //   cancelButtonText: 'Não'
    // }).then((result) => {
    //   if (result.value){
    //     api.get('contas_receber?pk_ctr='+id).then(r=>{
    //       console.log(r.data[0])
    //       if (r.data[0].cancelado == 'S'){
    //         erro = 'cancelado'
    //       }
    //       if (r.data[0].valor_liquidado >= (r.data[0].valor - r.data[0].valor_desconto + r.data[0].valor_juros + r.data[0].valor_multa)){
    //         erro = 'liquidado'
    //       }
    //       console.log(r.data[0].valor_liquidado, r.data[0].valor , r.data[0].valor_desconto , r.data[0].valor_juros, r.data[0].valor_multa)

    //       if (erro == '') {
    //         api.post('contas_receber/liquidar', {pk_ctr: id}).then(res => {
    //           console.log('ta aqui sem erro')

    //           if (res.status==200){
    //             erro = 'nenhum'
    //           } else {
    //             erro = 'update'
    //           }

    //           mensagem = funcMensagens[erro]
    //           mensagem()
    //         })
    //       } else {
    //         console.log('ta aqui erro')
    //         mensagem = funcMensagens[erro]
    //         mensagem()
    //       }
    //     })        
    //   }
    // })

    // api.get('/contas_receber?pk_ctr=' + id).then(async r => {
      if (row.valor_pendente <= 0) {
        swal.fire('Registro já liquidado', 'Registro não está pendente!', 'warning')
      } else {
        window.location.href = "/macropecas_app/contas_receber/liquidar/?codigo=" + id
      }
    // })

    
  }

  function formatColumn(value, type){
    const columnValue = value

    const formats = {
      'currency': function() {
        return (columnValue || 0 ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
      },
    }
    
    return formats[type]()

  }

  function handleCancelar(e, id, table) {
    e.preventDefault()
    const funcMensagens = {
      cancelado : function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Registro já está cancelado!</p>`,
          icon: 'warning',
        })
      },

      liquidado : function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Registro liquidado não pode ser cancelado!</p>`,
          icon: 'warning',
        })
      },

      update : function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Não foi possível concluir a ação no momento! Aguarde e tente novamente</p>`,
          icon: 'warning',
        })
      },

      nenhum : function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Sucesso!</h2>`,
          html: `<p style='color:${colors.info900}'>Registro Cancelado!</p>`,
          icon: 'success',
        })
      },
    }
    let erro =''
    let mensagem
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja cancelar registro?</p>`,
      // text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if(result.value){
        api.get('contas_receber?pk_ctr='+id).then(r=>{
          
          if (r.data[0].valor_liquidado > 0){
            erro = 'liquidado'
          }
          if (r.data[0].cancelado == 'S'){
            erro = 'cancelado'
          }
        
          if (erro == ''){
            api.post('contas_receber/cancelar',{pk_ctr: id, fk_usu: getUserCode()}).then(res=>{
              if (res.status==200){
                erro = 'nenhum'
              } else {
                erro = 'update'
              }

              mensagem = funcMensagens[erro]
              mensagem()
            })
          } else {
            mensagem = funcMensagens[erro]
            mensagem()
          }

        })
        
      }

    })
  }

 

  const cancelarBoleto = (e, id, table) => {
    

    if(table.Status == 'Aberto'){
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.post('/boletos/cancelarBoleto', {id: id}).then(res=>{
        if(res.data == 'cancelado'){

          swal.fire('Sucesso!', 'Boleto cancelado', 'success').then(r =>{
            window.location.reload()
          })
        }else{
          swal.fire('Erro!', 'Falha ao cancelar boleto.', 'warning')
        }
      })

    } else {
      swal.fire('Erro!', 'Só é possível baixar boleto aberto.', 'warning')
    }
  }

  function handleShowModalEmail (e, id, table){
    e.preventDefault()
    var today = Date.parse(new Date())
    const diffDays = (x, y) => { return Math.floor((x - y) / (1000 * 60 * 60 * 24))}  
      
    e.preventDefault()

    
    
    if(diffDays(today, Date.parse(table.data_geracao))  > 150){
      swal.fire('Boleto Indisponível!', 'Boleto Disponível apenas no Cobexpress!', 'warning')
    } else {
      setId(id)
      setEmail(table.email_particular)
      setNome(table.nome)
      setDataVenc(table.data_vencimento)
  
      setShowModalEmail(true)

    }
    // api.get('/associados?')
  }

  function escondeModalEmail (e){
    e.preventDefault()
    setShowModalEmail(false)
    
    setId(null)
    setEmail(null)
  }

  function confirmaModalEmail(e, table) {
    e.preventDefault()
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.post('boletos/enviarEmail', {id: id, email: email, nome: nome, data: dataVenc}).then(r=>{
      escondeModalEmail(e)
      swal.fire('Boleto Enviado!', 'Boleto enviado para o email: '+email+ ' com sucesso!', 'success')

    }).catch(err =>{
      escondeModalEmail(e)
      swal.fire('Ero no envio do boleto.', 'Não foi possível finalizar o envio do boleto', 'error')
    })
  }
  
  function handleDownloadBoleto(e, id, table){
  
      var today = Date.parse(new Date())
      const diffDays = (x, y) => { return Math.floor((x - y) / (1000 * 60 * 60 * 24))}  
        
      e.preventDefault()

      
      if(table.baixado == 'S'){
        swal.fire('Boleto Cancelado!', 'Não é possível fazer download de boleto cancelado!', 'warning')
      } else {
        if(diffDays(today, Date.parse(table.data_geracao))  > 10){
          swal.fire('Boleto Indisponível!', 'Boleto Disponível apenas no Cobexpress!', 'warning')
        } else{
          api.post('/boletos/downloadBoleto', {id:id}, {responseType: 'blob'}).then((response) => {
            var blob = new Blob([response.data], {
              type: 'application/pdf'
            });
            download(blob, 'boleto.pdf')
          }).catch( (e) => {
            if (e){
              swal.fire('Boleto nao encontrado na base Unicred!', 'Esse boleto não foi encontrado na base de dados, gere um novo boleto.', 'error')
            }
          })

        }
      }
  
        // e.preventDefault()
        // const url = api.backendUrl + 'api/contas_receber/testeApi?pk='+selected.join();
        // console.log(url)
        // var link = document.createElement("a");
        // link.setAttribute('download', 'boleto.pdf');
        // link.href = url;
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
        
        
        
    
  }

  function handleDelete(e, id, table) {
    e.preventDefault()
    if(table == 'caixas'){
      api.get('/caixa/temLeitura?id_caixa='+id).then(r => {
        if (!r.data[0]){
          deleteRecord(e, id, table)
        }else {
          swal.fire({
            title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
            text: "Acionamento com Leitura Registrada!",
            icon: 'warning',
            confirmButtonColor: colors.success500,
            confirmButtonText: 'Ok'})
         }
      })
    } else {
      deleteRecord(e, id, table)
    }
    
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds);
      if (props.setSelected)
        props.setSelected(newSelecteds)

      return;
    }
    setSelected([]);
    if (props.setSelected)
      props.setSelected([])

  };

  

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    if (props.setSelected)
      props.setSelected(newSelected)
  };

  async function handleColor(index, codigo) {
    api.get()
    await api.get('/caixa/sufixo', { params: {
      pk: codigo ? codigo : null,
     
    } }).then(r => {

      
    if ((r.data[0].ativo == 'N') && (props.regName =='caixas')){

      return document.getElementById("tableRow").style.setProperty('background-color', 'red');
    }
    })
    
    
  }

  function liquidar(e, id, pkName) {
    e.preventDefault()
    // if (pkName == 'pk_tit') {
      api.get('/contas_pagar?pk_tit=' + id).then(async r => {
        if (r.data[0].pendente <= 0) {
          swal.fire('Registro já liquidado', 'Registro não está pendente!', 'warning')
        } else {
          window.location.href = "/macropecas_app/contas_pagar/liquidar/?codigo=" + id
        }
      })
    // } else {
      
    // }
  }

  async function handleClickEdit  (event, codigo) {
    await api.get('/caixa/sufixo', { params: {
      pk: codigo ? codigo : null,
     
    } }).then(r => {
      if ((r.data[0].sufixo == 'A') && (props.regName == 'caixas')){
        window.location.href="/macropecas_app/" + props.regName + "/registro?codigo=" + codigo
        // window.location.replace('/macropecas_app/' + props.regName+'/registro?codigo='+codigo)
      }
              // setData(r.data)
    })
    
  }
  

  const handleClickDetail = (event, name) => {
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }
    setOpened(newOpened);
  }
  function handleClickAcionamento () {
    localStorage['lock_cli'] =  JSON.stringify({ lock_cli: 'S' });
  }

  

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isOpened = (name) => opened.indexOf(name) !== -1;

  const escondeModal = () => setShowModal(false);

  const listaPrecosPdf = async (e, id) => {
    e.preventDefault();

    setArquivo(api.backendUrl + '/api/cliente_produto/listaPrecosPdf?fk_cli=' + id)
    setShowModal(true)
  }

  const listaPrecosExcel = async (e, id) => {
    e.preventDefault();
    window.location.href = api.backendUrl + '/api/cliente_produto/listaPrecosExcel?fk_cli=' + id
  }

  function reabrir(e, id, pkName) {
    e.preventDefault()
    
    window.location.href = "/macropecas_app/fotos?codigo=" + id
     

  }

  function handleChange(e){
    var change = {
      email: function () {
        setEmail(e.target.value)
      }
    }
    change[e.target.id]()
  }
  
  function reabrirCtr(e, id, pkName) {
    e.preventDefault()
    
    window.location.href = "/macropecas_app/contas_receber/reabrir?codigo=" + id
     

  }

  function visualizarNota(e,id, pk){
    setArquivo(api.backendUrl + '/api/notas_fiscais/pdf?pk_ntf='+id)
    setShowModal( true)
    
  }

  function downloadPdf(e, id, pk, page, row){
    api.post(`/${page.toLowerCase().replace(' ', '_')}/download?${pk.toLowerCase()}=${id}`,{pk_ntf: id}, {responseType: 'blob'}).then(r => {
      var blob = new Blob([r.data], {
        type: 'application/octet-stream'
      });
      console.log(r)
      // console.log(response.data)
      switch(pk.toLowerCase()){
        case 'pk_ntf':
          download(blob, `Nota_${id}.pdf`)
          break;
        case 'pk_ped':
          download(blob, `Pedido_${id}.pdf`)
          break;
        
        case 'pk_cat':
          download(blob, row['NOME_ARQUIVO'])
          
      }
    })
  }

  function testeClick(e, row){
    console.log('oi', row["LINK"])
    if(row["LINK"]){
      return window.open(row["LINK"], '_blank');
    }

    swal.fire('Link não encontrado!', 'Nenhum link registrado foi encontrado para esse catalogo', 'warning')
  }


  return (
    <ThemeProvider theme={theme}>

      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModalEmail} fullWidth={false} maxWidth={'lg'} >
          <div style={{ padding: '20px', paddingTop: '40px', backgroundColor:'var(--cor-tema)'  }}>

            <div className="defaultHeader">

              <h3>Envio de Boleto</h3>
            </div>
            <br />


            <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start', backgroundColor:'var(--cor-tema)' }}>

              <FrTextInput
                maxLength='200'
                value={email || ''}
                onChange={handleChange}
                name="email"
                id="email"
                color="#389492"
                label={'Email'}
                variant="outlined"
                size="small"
                style={{ width: 500 }}
              />  

            

              

            </div>
          </div>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv" >
              <button onClick={confirmaModalEmail}> Confirmar </button>
              <button onClick={escondeModalEmail}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>

      {(data.length === 0) ? (
        <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" size={((props.frame || props.readOnly) ? "small" : "medium")}>
              <TableHead>
                <TableRow>
                  {((!props.readOnly || props.mostraCheck=='S')) ? (
                    <StyledTableCell padding="checkbox">
                      <FrCheckBox
                        indeterminate={selected.length > 0 && selected.length < data.length}
                        checked={data.length > 0 && selected.length === data.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </StyledTableCell>) : (<></>)}

                  {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)}
                  {(!props.readOnly) ? (
                    <StyledTableCell
                      style={{ minWidth: width, width: width}}
                    >
                      Opções
                    </StyledTableCell>) : (<></>)}
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={column.order ? (orderBy === column.order) : (orderBy === column.id)}

                        direction={column.order? (orderBy === column.order ? order : 'asc'):(orderBy === column.id ? order : 'asc')}

                        onClick={createSortHandler((column.order) ? column.order : column.id)}
                        // className="labelOrder"
                        style={{ backgroundColor: orderBy === column.id ? '' : '' }}
                      >
                        {column.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody > 
                {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const isItemSelected = isSelected(row[props.codeName]);
                  const isItemOpened = isOpened(row[props.codeName]);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if (props.detail) {
                    
                    return (
                      <>
                      
                    
                        <TableRow  aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                          {(!props.readOnly || props.mostraCheck=='S') ? (
                            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                              <FrCheckBox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>) : (<></>)}
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                              {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          {(!props.readOnly) ? ((props.frame) ? (
                            <TableCell>
                              <Icon className="tableEdit" icon={ic_delete} size={22} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                            </TableCell>
                          ) : (
                            <TableCell>                         
                              <Link to={"/macropecas_app/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                              </Link>
                              <Icon className="tableDelete" icon={ic_delete} size={22} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                            
                            
                              {(props.downloadPdf) ? (
                                <Tooltip title='Imprimir'>
                                  <div style={{ display: 'inline' }}>
                                    <Icon className="tableDelete" icon={download3} size={18} onClick={(e) => downloadPdf(e, row[props.codeName], props.codeName, props.page, row)} />
                                  </div>
                                </Tooltip>)
                                : (<></>)}
                            </TableCell>
                          )) : (<></>)}

                        
                          
                          {columns.map((column) => {
                            
                            const value = row[column.id];
                            if (column.image) {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <image src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                </TableCell>
                              )
                            } else if (column.icon) {
                              let iconComponent = icons.find((item) => item.fk_sne === value)
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {iconComponent.icon}
                                </TableCell>
                              )
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      {/* {(props.regName == 'caixas') && (props.data[index].ativo) == 'N' ? */}
                        
                        <TableRow>
                          <TableDetail isOpen={isItemOpened} detail={row} detailName={props.detailName} codeName={props.codeName} columnDetails={props.columnDetails}/>
                        </TableRow>
                      </>
                    );

                  } else {
                    return (
                      <>
                      {(row.ativo == 'N') && (props.regName == 'caixas')? 
                      <TableRow className={classes.row} onClick={(event) => { if (!props.readOnly) handleClick(event, row[props.codeName]) }} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                      {(!props.readOnly || props.mostraCheck=='S') ? (
                        <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                          <FrCheckBox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>) : (<></>)}
                      {(!props.readOnly) ? (
                        <TableCell >
                          {/* {((props.regName == 'caixas') && (props.data[index].sufixo === 'A')) ?( */}
                            
                            
                          {(props.enableDel == 'S')  ? (
                          <Tooltip title="Excluir">   
                            <Link>
                              <Icon className="tableDelete" icon={ic_delete} size={20} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                            </Link> 
                          </Tooltip> 
                          )
                            : (<></>)}

                       

                          

                        </TableCell>
                      ) : (<></>)}



                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.image) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                            </TableCell>
                          )
                        } else if (column.icon) {
                          let iconComponent = icons.find((item) => item.fk_sne === value)
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {iconComponent.icon}
                            </TableCell>
                          )
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>: 
                    <TableRow onClick={(event) => { if (!props.readOnly) handleClick(event, row[props.codeName]) }} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                    {(!props.readOnly || props.mostraCheck=='S') ? (
                      <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                        <FrCheckBox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>) : (<></>)}
                    {(!props.readOnly) ? (
                      <TableCell >
                        {/* {((props.regName == 'caixas') && (props.data[index].sufixo === 'A')) ?( */}

                        {!props.enableEdit  ? <></>: <Tooltip title="Editar">
                            <Link to={"/macropecas_app/" + props.regName + "/registro?codigo=" + row[props.codeName]} >
                              <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                            </Link>                                
                          </Tooltip>
                          
                        } 
                        {!props.enableEditTit  ? <></> : <Tooltip title="Editar">
                            <Link to={"/macropecas_app/" + props.regName + "/?codigo=" + row[props.codeName]}>
                              <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                            </Link>                                
                          </Tooltip>
                          
                        }

                        {/* to={"/macropecas_app/" + props.regName + "/registro?codigo=" + row[props.codeName]} */}
                      
                          
                        {(props.enableDel == 'S') ? (
                        <Tooltip title="Excluir">   
                          <Link>
                            <Icon className="tableDelete" icon={ic_delete} size={20} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                          </Link> 
                        </Tooltip> 
                        )
                          : (<></>)}

                        {(props.regName == 'contas_receber') ? (
                        <Tooltip title="Liquidar">   
                          <Link>
                            <Icon className="tableDelete" icon={ic_attach_money} size={22} onClick={(e) => handleLiquidar(e, row[props.codeName], row)} />
                          </Link> 
                        </Tooltip> 
                        )
                          : (<></>)}  

                        {(props.verBoletos) ? (
                          <Tooltip title="Ver Boletos">   
                            <Link>
                              <Icon className="tableDelete" icon={documentInverted} size={20} onClick={(e) => handleVerBoletos(e, row[props.codeName], row)} />
                            </Link> 
                          </Tooltip> 
                          )
                            : (<></>)}  
                        
                        {/* {(props.emitirBoleto) ? (
                          <Tooltip title="Ver Boletos">   
                            <Link>
                              <Icon className="tableDelete" icon={documentInverted} size={20} onClick={(e) => handleEmitirBoleto(e, row[props.codeName], row)} />
                            </Link> 
                          </Tooltip> 
                          )
                            : (<></>)}  */}

                        {(props.baixarBoleto) ? (
                          <Tooltip title="Baixar Boletos">   
                            <Link>
                              <Icon className="tableDelete" icon={folderDownload} size={20} onClick={(e) => handleDownloadBoleto(e, row[props.codeName], row)} />
                            </Link> 
                          </Tooltip> 
                          )
                            : (<></>)}   

                        {(props.baixarBoleto) ? (
                          <Tooltip title="Enviar Boleto Email">   
                            <Link>
                              <Icon className="tableDelete" icon={ic_email} size={22} onClick={(e) => handleShowModalEmail(e, row[props.codeName], row)} />
                            </Link> 
                          </Tooltip> 
                          )
                            : (<></>)}   
                            

                        {(props.cancelarBoleto) ? (
                          <Tooltip title="Cancelar Boletos">   
                            <Link>
                              <Icon className="tableDelete" icon={blocked} size={20} onClick={(e) => cancelarBoleto(e, row[props.codeName], row)} />
                            </Link> 
                          </Tooltip> 
                          )
                            : (<></>)}

                        

                        {(props.regName == 'contas_receber') ? (
                        <Tooltip title="Cancelar">   
                          <Link>
                            <Icon className="tableDelete" icon={blocked} size={17} onClick={(e) => handleCancelar(e, row[props.codeName], props.regName)} />
                          </Link> 
                        </Tooltip> 
                        )
                          : (<></>)}  
                         {(props.regName == 'contas_receber') ? (
                          <Tooltip title='Reabrir'>
                            <div style={{ display: 'inline' }}>
                              <Icon className="tableDelete" icon={retweet} size={14} onClick={(e) => reabrirCtr(e, row[props.codeName], props.codeName)} />
                            </div>
                          </Tooltip>)
                          : (<></>)}

                        {(props.regName == 'contas_pagar') ? (
                          <Tooltip title='Liquidar'>
                            <div style={{ display: 'inline' }}>
                              <Icon className="tableDelete" icon={checkCircle} size={14} onClick={(e) => liquidar(e, row[props.codeName], props.codeName)} />
                            </div>
                          </Tooltip>)
                          : (<></>)}
 

                        {(props.regName == 'contas_pagar') ? (
                          <Tooltip title='Reabrir'>
                            <div style={{ display: 'inline' }}>
                              <Icon className="tableDelete" icon={retweet} size={14} onClick={(e) => reabrir(e, row[props.codeName], props.codeName)} />
                            </div>
                          </Tooltip>)
                          : (<></>)}

                        {(props.uploadFotos) ? (
                          <Tooltip title='Visualizar Fotos'>
                            <div style={{ display: 'inline' }}>
                              <Icon className="tableDelete" icon={camera} size={14} onClick={(e) => reabrir(e, row[props.codeName], props.codeName)} />
                            </div>
                          </Tooltip>)
                          : (<></>)}
                        {(props.visualizar) ? (
                          <Tooltip title='Visualizar'>
                            <div style={{ display: 'inline' }}>
                              <Icon className="tableDelete" icon={eye} size={14} onClick={(e) => visualizarNota(e, row[props.codeName], props.codeName)} />
                            </div>
                          </Tooltip>)
                          : (<></>)}
                        {(props.downloadPdf) ? (
                          <Tooltip title='Imprimir'>
                            <div style={{ display: 'inline' }}>
                              <Icon className="tableDelete" icon={download3} size={18} onClick={(e) => downloadPdf(e, row[props.codeName], props.codeName, props.page, row)} />
                            </div>
                          </Tooltip>)
                          : (<></>)}
                        {(props.link) ? (
                          <Tooltip title='Ver Link'>
                            <div style={{ display: 'inline' }} >
                              <Icon className="tableDelete" icon={ic_north_east} size={18} onClick={(e) => testeClick(e,  row)} />
                            </div>
                          </Tooltip>)
                          : (<></>)}

                        

                      </TableCell>
                    ) : (<></>)}



                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.image) {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                          </TableCell>
                        )
                      } else if (column.icon) {
                        let iconComponent = icons.find((item) => item.fk_sne === value)
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {iconComponent.icon}
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? formatColumn(value, column.format) : value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow> }
                      
                      </>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className="no-print"
          />
        </Paper>
      )
      }
    </ThemeProvider >
  );
}
