import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import AutoComp from '../components/AutoComp'

import api from '../services/api'
import { paramsToObject, objectToQueryString, onlyNumbers, populateForm, formToObject, isEmpty, getUserCode } from '../utils/functions'
import { mCNPJ, mCPF } from '../utils/masks'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Clientes() {
  let history = useHistory()
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [comboSeg, setComboSeg] = useState([{ value: '', display: '' }])
  const [cid, setCid] = useState(0)
  const [est, setEst] = useState(0)
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' },])
  const [comboEmp, setComboEmp] = useState([{ value: '', display: '' }])
  const [comboEst, setComboEst] = useState([{ value: '', display: '' }])
  const [comboRepresentante, setComboRepresentante] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'RAZAO_SOCIAL', numeric: false, label: 'Nome', align: 'left', minWidth: 300, isLink: true, linkTo: 'clientes', isNotCode: true },
    { id: 'NOME_FANTASIA', numeric: false, label: 'Nome Fantasia', align: 'left', minWidth: 200 },
    { id: 'CNPJ_FORMATADO', numeric: false, label: 'CNPJ', align: 'left', minWidth: 150 },
    { id: 'CPF_FORMATADO', numeric: false, label: 'CPF', align: 'left', minWidth: 100 },
    { id: 'INSCRICAO_ESTADUAL', numeric: false, label: 'Inscrição Estadual', align: 'left', minWidth: 120 },
    { id: 'ATIVO', numeric: false, label: 'Ativo', align: 'left', minWidth: 120 },

  ]


  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Clientes'
      let consulta = 'S'
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              history.push('/macropecas_app/home')
            }
          })
      } else {


        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        
        setComboCid(await api.getComboData('cidade'))
        setComboSeg(await api.getComboData('segmento'))
        setComboEst(await api.getComboData('estado'))
        // setComboCid(await api.getComboData('cidades'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  // useEffect(() => {
  //   const fetchCidades = async () => {
  //     if (filter.fk_est > 0) {
  //       swal.fire({
  //         html: <FrLoading text="Buscando Cidades..." />,
  //         showConfirmButton: false,
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       })
  //       setComboCid(await api.getComboData('cidades', filter.fk_est))
  //       swal.close()
  //     }
  //   }

  //   fetchCidades()
  // }, [filter.fk_est])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // var queryObject = { ...filter }

    if (!isEmpty(queryObject)) { //(1 == 1) {//
      queryObject.buscar = true
      queryObject.usu = getUserCode()
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/macropecas_app/clientes?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


      api.get('/clientes', { params: queryObject }).then(r => {
        if(r.data.length === 0){
          swal.fire('Nenhum registro encontrado!', 'Cliente não cadastrado ou não faz parte da sua carteira!', 'warning')
        } else {
          setData(r.data)
          swal.close()
        }
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/macropecas_app/clientes');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case 'cnpj_cpf':
        auxValues[e.target.name] = onlyNumbers(e.target.value).length <= 11 ? mCPF(e.target.value) : mCNPJ(e.target.value)
        break
      // case 'telefone':
      //   auxValues[e.target.name] = mTel(e.target.value)
      //   break

      default: auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_cid"] = cid;
      auxValues["NOMECID"] = ''
      if (cid > 0) {
        await api.get("/cidade?pk_cid=" + cid).then((r) => {
          if (r.data[0]){
            auxValues["NOMECID"] = r.data[0].NOME;
            setFilter(auxValues);

          }
        });
      }
    };
    fetchMdc();
  }, [cid]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_est"] = est;
      auxValues["NOMECID"] = ''
      if (est > 0) {
        await api.get("/cidade?pk_est=" + est).then((r) => {
          if (r.data[0]){
            auxValues["UFEST"] = r.data[0].UF;
            setFilter(auxValues);

          }
        });
      }
    };
    fetchMdc();
  }, [est]);

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Clientes</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div className='filters'>
              <div>

              <FrTextInput
                value={filter.pk_cli || ''}
                style={{ width: 100 }}
                name="pk_cli"
                id="pk_cli"
                color="#49573B"
                label="Código"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />


              <FrTextInput
                value={filter.cnpj || ''}
                style={{ width: 160 }}
                name="cnpj"
                id="cnpj"
                color="#49573B"
                label="CNPJ"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrTextInput
                value={filter.razao_social || ''}
                style={{ width: 330 }}
                name="razao_social"
                id="razao_social"
                color="#49573B"
                label="Razão Social"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />


              <FrTextInput
                value={filter.fantasia || ''}
                style={{ width: 230 }}
                name="fantasia"
                id="fantasia"
                color="#49573B"
                label="Nome Fantasia"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrSelect
                value={filter.ativo || ''}
                style={{ width: 110 }}
                name="ativo"
                id="ativo"
                label='Situação'
                data={comboAtivo}
                onChange={handleChange} 
              />


              </div>
              <div>

              <AutoComp
                value={filter.NOMECID || ""}
                valor={filter.fk_cid || ''}
                width={350}
                name="fk_cid"
                id="fk_cid"
                label='Cidade'
                data={comboCid}
                setValue={setCid}
              />

            <AutoComp
                value={filter.UFEST || ""}
                valor={filter.fk_est || ''}
                width={200}
                name="fk_est"
                id="fk_est"
                label='UF'
                data={comboEst}
                setValue={setEst}
              />

              <FrSelect
                value={filter.fk_seg || ''}
                style={{ width: 210 }}
                name="fk_seg"
                id="fk_seg"
                label='Segmento de Mercado'
                data={comboSeg}
                onChange={handleChange} 
              />
              </div>

              {/* 
               <div>

              <FrTextInput
                value={filter.contato || ''}
                style={{ width: 250 }}
                name="contato"
                id="contato"
                color="#49573B"
                label="Contato"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />


              <FrTextInput
                value={filter.telefone || ''}
                style={{ width: 130 }}
                name="telefone"
                id="telefone"
                color="#49573B"
                label="Telefone"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrTextInput
                value={filter.email || ''}
                style={{ width: 210 }}
                name="email"
                id="email"
                color="#49573B"
                label="E-mail"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />


              <FrSelect
                value={filter.fk_emp || ''}
                style={{ width: 210 }}
                name="fk_emp"
                id="fk_emp"
                label='Empresa'
                data={comboEmp}
                onChange={handleChange}
              />
              </div>
              <div>

              <FrSelect
                value={filter.fk_est || ''}
                style={{ width: 210 }}
                name="fk_est"
                id="fk_est"
                label='Estado'
                data={comboEst}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_cid || ''}
                style={{ width: 210 }}
                name="fk_cid"
                id="fk_cid"
                label='Cidade'
                data={comboCid}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_tcl || ''}
                style={{ width: 210 }}
                name="fk_tcl"
                id="fk_tcl"
                label='Tipo Pessoa'
                data={comboTcl}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_pro || ''}
                style={{ width: 210 }}
                name="fk_pro"
                id="fk_pro"
                label='Produto'
                data={comboPro}
                onChange={handleChange}

              />

              <FrTextInput
                value={filter.marca || ''}
                style={{ width: 210 }}
                name="marca"
                id="marca"
                color="#49573B"
                label="Marca"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              </div>
              <div>

              <FrSelect
                value={filter.fk_cli_rep || ''}
                style={{ width: 425 }}
                name="fk_cli_rep"
                id="fk_cli_rep"
                label='Representante'
                data={comboRepresentante}
                onChange={handleChange}
              /> 
              </div>
              */}
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/macropecas_app/clientes/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable widthOptions={130} replicar={true} regName="clientes" columns={columns} searched={filtered} codeName="PK_CLI" page={'Clientes'} data={data} enableEdit={enableEdit} enableDel={enableDel} rowsPerPage={25} orderBy={'razao_social'} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Clientes;