import React, { useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Icon } from 'react-icons-kit'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrTextField from '../components/FrTextField'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import moment from 'moment';

import api from '../services/api'
import { onlyNumbers, dateSql, paramsToObject, validarCNPJ, validarCPF, formToObject, compareDate, formataReal, getUserCode } from '../utils/functions'
import consultaCep from '../services/consultaCep'
import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker';
import FrTableReg from '../components/FrTableReg'
import FrModalConfirmaPed from '../components/FrModalConfirmaPed'
import FrSwitch from '../components/FrSwitch'
import { ic_analytics_twotone } from 'react-icons-kit/md/ic_analytics_twotone'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import Tabs from '../components/Tabs';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { mTel, mCPF, mCNPJCPF, mCEP, mVAL } from '../utils/masks'
import $ from 'jquery'
import FrTable from '../components/FrTable'
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";
import { SettingsPowerSharp } from '@material-ui/icons';
import {spinner8} from 'react-icons-kit/icomoon/spinner8'
import AutoComp from '../components/AutoComp'
import { CombosContext } from '../App';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import {camera} from 'react-icons-kit/icomoon/camera'
import {externalLink} from 'react-icons-kit/fa/externalLink'
import { BtnDelete, DivMostraFoto, Foto } from './styles/styles';

// flex-start: to position it at the top
// flex-end: to position it at the bottom
// center: to position it at the center

const sx = {
   "& .MuiDialog-container": {
     alignItems: "flex-start"
   }
};

moment.locale('pt-br',
  {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_'),
    monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_')
  })



const swal = withReactContent(Swal)

function Pedidos() {
  let history = useHistory()
  const [auth, setAuth] = useState(JSON.parse(localStorage['authMacroApp'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState({TIPOPED: 'P', FK_REP: 1})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [imagens, setImagens] = useState(null)
  const [modalMarca, setModalMarca] = useState(false)
  const [valPromoGrupo, setValPromoGrupo] = useState(0)
  const [valApliqPromoGrupo, setValApliqPromoGrupo] = useState(0)
  const [valDescLog, setValDescLog] = useState(0)
  const [valApliqDescLog, setValApliqDescLog] = useState(0)
  const [valDescPromo, setValDescPromo] = useState(0)
  const [valApliqDescPromo, setValApliqDescPromo] = useState(0)
  const [valDescIcms, setValDescIcms] = useState(0)
  const [valApliqDescIcms, setValApliqDescIcms] = useState(0)
  const [valDescCpg, setValDescCpg] = useState(0)
  const [valApliqDescCpg, setValApliqDescCpg] = useState(0)
  const [valDescClasCli, setValDescClasCli] = useState(0)
  const [valApliqDescClasCli, setValApliqDescClasCli] = useState(0)
  const [modalProdutos, setModalProdutos] = useState(false)
  const [recalculando, setRecalculando] = useState(false)
  const [modalDoc, setModalDoc] = useState(false)
  const [aplicaDescLog, setAplicaDescLog] = useState('N')
  const [comboCidCob, setComboCidCob] = useState([{ value: '', display: '' }])
  const [pos, setPos] = useState(0)
  const {comboCli, comboPro, clientes} = useContext(CombosContext)
  const [comboCpg, setComboCpg] = useState([{ value: '', display: '' },{ value: 'A', display: 'A' }, { value: 'A+', display: 'A+' }, { value: 'B', display: 'B' },{ value: 'C', display: 'C' }])
  const [sican, setSican] = useState('N')
  const [cpg, setCpg] = useState(0)
  const [cli, setCli] = useState(0)
  const [seg, setSeg] = useState(0)
  const [cid, setCid] = useState(0)
  const [st, setSt] = useState(null)
  const [dataIte, setDataIte] = useState([])
  const [envio_cob, setEnvio_cob] = useState('N')
  const [dataArquivos, setDataArquivos] = useState([{ value: '', display: '' }])
  const [promoGrupo, setPromoGrupo] = useState(0)
  const [descLogistico, setDescLogistico] = useState(0)
  const [descPromo, setDescPromo] = useState(0)
  const [descCpg, setDescCpg] = useState(0)
  const [descClasCli, setDescClasCli] = useState(0)

  const [dataCon, setDataCon] = useState([{ nome: '', display: '' }])
  const [con_tratamento, setCon_Tratamento] = useState('')
  const [con_nome, setCon_Nome] = useState('')
  const [con_cargo, setCon_Cargo] = useState('')
  const [con_fone_1, setCon_Fone_1] = useState('')
  const [con_dep, setCon_dep] = useState('')
  const [con_cpf, setCon_Cpf] = useState('')
  const [con_ddd, setCon_ddd] = useState('');
  const [con_observacoes, setCon_Observacoes] = useState('')
  const [con_email, setCon_Email] = useState('')
  const [con_msn, setCon_Msn] = useState('')
  const [con_mala_direta, setCon_Mala_Direta] = useState('')
  const [con_ct, setCon_Ct] = useState('')
  const [con_bbm, setCon_Bbm] = useState('')
  const [con_mes, setCon_Mes] = useState('')
  const [con_dia, setCon_Dia] = useState('')
  const [comboRep, setComboRep] = useState([{ nome: '', display: '' }])

  const [pk_cli, setPk_cli] = useState();

  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)

  const [showModal, setShowModal] = useState(false)
  const [showModalConfirma, setShowModalConfirma] = useState(false)

  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' },])
  const [comboRepresentante, setComboRepresentante] = useState([{ value: '', display: '' }])

  const columnsCon = [{ id: 'PRO', numeric: false, label: 'Código', align: 'left', minWidth: 200 },
  { id: 'NOMEPRO', numeric: false, label: 'Descrição', align: 'left', minWidth: 170 },
  { id: 'QTD', numeric: false, label: 'Quantidade', align: 'left', minWidth: 150 },
  { id: 'ALIQIPI', numeric: false, label: 'IPI', align: 'left', minWidth: 150 },
  { id: 'VALTOT', numeric: false, label: 'Valor Total', align: 'left', minWidth: 120 },
  { id: 'VALICMS', numeric: false, label: 'Valor ICMS', align: 'left', minWidth: 120 },
  ]

  const columns = [
    { id: 'arquivo', numeric: false, label: 'Arquivo', align: 'left', minWidth: 300 },
  ]


  const [dataCpr, setDataCpr] = useState([{ display: '' }])
  const [fk_pro, setfk_pro] = useState('')
  const [ficha_bbm, setficha_bbm] = useState('')

  const [dataMar, setDataMar] = useState([{ display: '' }])
  const [marca, setMarca] = useState('')

  const [dataCdo, setDataCdo] = useState([{ display: '' }])
  const [fk_tdo, setfk_tdo] = useState('')
  const [data_entrega, setdata_entrega] = useState('')
  const [especificacao, setespecificacao] = useState('')
  const [comissao_r, setComissao_r] = useState(0);

  const [erroCpfCnpj, setErroCpfCnpj] = useState(false)
  const [erroCpfCon, setErroCpfCon] = useState(false)

  const [regiao, setRegiao] = useState(null)
  const [pro, setPro] = useState('')
  const [nomePro, setNomePro] = useState('')
  const [ite_pro, setIte_pro] = useState(null)
  const [ite_qtd, setIte_qtd] = useState(0)
  const [ite_val, setIte_val] = useState(0)
  const [ite_obs, setIte_obs] = useState('')
  const [ite_desc1, setIte_desc1] = useState(0)
  const [ite_desc2, setIte_desc2] = useState(0)
  const [ite_desc3, setIte_desc3] = useState(0)
  const [ite_aliqIpi, setIte_aliqIpi] = useState(0)
  const [ite_valIpi, setIte_valIpi] = useState(0)
  const [ite_aliqIcms, setIte_aliqIcms] = useState(0)
  const [ite_valIcms, setIte_valIcms] = useState(0)
  const [ite_valTot, setIte_valTot] = useState(0)
  const [ite_valSemImposto, setIte_valSemImposto] = useState(0)
  // const [cidade, setCidade] = useState(0)

  const [erroDupliCnpjCpf, setErroDupliCnpjCpf] = useState(false)
  const [dupliCpfCnpj, setDupliCpfCnpj] = useState('')
  const [erroDupliInscEst, setErroDupliInscEst] = useState(false)

  const [excluidosCon, setExcluidosCon] = useState([])
  const [arrayExcluidos, setArrayExcluidos] = useState([])
  const [excluidosMar, setExcluidosMar] = useState([])
  const [excluidosCdo, setExcluidosCdo] = useState([])

  const repCombo = useRef(null)

  let tot = dataIte ? dataIte.reduce(function (acumulador, item) { return acumulador + item.VALTOT; }, 0) : 0
  let totIpi = dataIte ? dataIte.reduce(function (acumulador, item) { return acumulador + item.VALIPI; }, 0) : 0
  let totIcms = dataIte.reduce(function (acumulador, item) { return acumulador + item.VALICMS; }, 0);
  let totSemImposto = dataIte.reduce(function (acumulador, item) { return acumulador + item.VALSEMIMPOSTO; }, 0); 

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let conAuxValues;


      
      if (query.codigo > 0) {
        setEnableEdit('S')
        // setEnableEdit(await api.getAcessos('Pedidos/Fornecedores', 'Alteracao'))
      } else {
        setEnableEdit('S')
        // setEnableEdit(await api.getAcessos('Pedidos/Fornecedores', 'Inclusao'))
      }
      const a = await JSON.parse(localStorage['authMacroApp'])
      setComboCpg(await api.getComboData('cond_pag'))
      console.log(a)
      setComboRep(await api.getComboData('representadas', '', 'inativo=N' + (a.fk_gpu != 1 ? '&fk_ven=' + a.fk_ven : '')))
      

      if (query.codigo > 0) {
        setPk_cli(query.codigo)
        setUpdateType(true)
        api.get('/pedidos?pk_ped=' + query.codigo).then(r => {
          let auxValues = {}
          if (r.data[0]) {
            auxValues = r.data[0]
            setDescCpg(auxValues.DESCCPG || 0)
            api.get('/itens_pedido?pk_ped='+query.codigo).then(r => {
              if(r.data.length > 0)
                setDataIte(r.data)

            })
            
            // setVen(r.data[0].FK_VEN)
            // setVen2(r.data[0].FK_VEN2)
            // setSeg(r.data[0].FK_SEG)
            // setCid(r.data[0].FK_CID)
            // api.get('/pedidos/contatos?pk_cli=' + query.codigo).then(r => {
            //   // console.log(r.data)
            //   if (r.data.length > 0)
            //     conAuxValues = r.data;
            
            //   // for (let i = 0; i < r.data.length; i++) {
            //   //   conAuxValues[i].cpf = (conAuxValues[i].cpf ? mCPF(conAuxValues[i].cpf) : null)
            //   // }
            //   setDataCon(conAuxValues)
            // })
            auxValues.FK_REP = String(auxValues.FK_REP)
          
            setData(auxValues)
            console.log(auxValues)
            setCli(state => auxValues.FK_CLI)
            setCpg(state => auxValues.FK_CPG)
          

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  history.push('/macropecas_app/pedidos')
                }
              })
          }
        })
      } else {
        let dataHoje = new Date()
        setData({ FK_REP: '1', fk_emp: 1, ativo: 'S', DATA: dataHoje.toISOString().split('T')[0] })
        swal.close()
      }
    }

    fetchData()
  }, [])


  function handleCep(e) {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value) {

        let endereco = 'endereco';
        let bairro = 'bairro';
        let fk_est = 'fk_est';
        let fk_cid = 'fk_cid';

        if (e.target.id !== 'cep') {
          endereco = 'endereco_cob';
          bairro = 'bairro_cob';
          fk_est = 'fk_est_cob';
          fk_cid = 'fk_cid_cob';
        }

        consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(async r => {
          if (r.data.erro) {
            swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
          } else {
            const auxValues = { ...data };
            auxValues[endereco] = r.data.logradouro;
            auxValues[bairro] = r.data.bairro;

            await api.get('/estados?uf=' + r.data.uf).then(r => {
              if (r.data.length > 0)
                auxValues[fk_est] = r.data[0].pk_est
            })

            await api.get('/cidades?nomeExato=' + r.data.localidade).then(r => {
              if (r.data.length > 0)
                auxValues[fk_cid] = r.data[0].pk_cid
              // // console.log(r.data[0])
            })

            setData(auxValues)
          }
        }).catch(err => {
          swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
        })
      }
    }
  }



  function consistData(data) {
    let required = ['FK_CLI', 'FK_CPG']
    //   'fk_tcl'
    // ]

    
    // Tipo – 3 – Representante:
    // Obrigatórios: Cnpj ou CPF / Razão Social / Endereço / Fone 1 / No mínimo 1 Produto / No mínimo 1 contato

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'FK_CLI') fieldName = 'Cliente'
      if (curr === 'FK_CPG') fieldName = 'Condição de Pagamento'



      if (!data[curr]) return errors.push(fieldName)



    })


    // if ((data.fk_tcl == 1) || (data.fk_tcl == 2) || (data.fk_tcl == 3))
    //   if ((dataCon[0].nome === '') || (dataCon[0].display === ''))
    //     errors.push('Informe ao menos um contato!')

    // if ((data.fk_tcl == 1) || (data.fk_tcl == 2) || (data.fk_tcl == 3) || (data.fk_tcl == 4))
    //   if ((dataCpr[0].nome === '') || (dataCpr[0].display === ''))
    //     errors.push('Informe ao menos um produto!')

    return errors
  }
  
  function confirma() {
    setShowModalConfirma(true)
  }
  function closeConfirma() {
    setShowModalConfirma(false)
  }
  function handleSubmit(e) {
    e.preventDefault();
    closeConfirma()
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    const auxValues = { ...data };
    let dataHoje = new Date()

    auxValues.excluidosIte = arrayExcluidos
    auxValues.FK_CLI = cli
    auxValues.FK_CPG = cpg
    auxValues.usu = getUserCode()
    auxValues.DATA = auxValues.DATA.split("T")[0]
    auxValues.total = tot
    auxValues.totalIpi = totIpi
    auxValues.totalIpi = totIpi
    auxValues.totalIcms = totIcms
    // auxValues.fk_emp = auth.fk_emp
    
    // return console.log(auxValues)
    let errors = consistData(auxValues)
    auxValues.dataIte = dataIte

    // if (data.insc_estadual && !updateType) {
    //   await api.get('/pedidos?insc_estadual=' + onlyNumbers(data.insc_estadual) + '&codcli=' + data.pk_cli).then(r => {
    //     if (r.data.length > 0)
    //       errors.push(' Já existe cadastro com esta inscrição estadual!')
    //   })
    // }

    if (errors.length === 0) {
      if (updateType) {
        api.post('/pedidos/edit', auxValues).then(r => {
          // console.log(r)
          if (r.status === 200) {
            swal.fire({
              title: 'Registro Alterado',
              text: "O registro foi alterado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                history.push('/macropecas_app/pedidos')
              }
            })
          }
        })
          .catch(r => {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado! ",
              icon: 'warning',
            })
          })
      } else {
        api.post('/pedidos/add', auxValues).then(r => {
          console.log(r.data)
          if (r.data == "Added!") {
            swal.fire({
              title: 'Registro Adicionado!',
              text: "O registro foi adicionado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                history.push('/macropecas_app/pedidos')
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi adicionado! ",
              icon: 'warning',
            })
          }
        })
          .catch(r => {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi adicionado! ",
              icon: 'warning',
            })
          })

      }
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Verifique inconsistências: " + errors.join(','),
        icon: 'warning',
      })
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        history.push('/macropecas_app/pedidos')
      }
    })
  }

  const handleChange = (e) => {

    const auxValues = { ...data };
    switch (e.target.name) {
      
 
     
      case 'TIPOPED':
        if (auxValues[e.target.name] == 'P') {
          auxValues[e.target.name] = 'O'
        } else {
          auxValues[e.target.name] = 'P'
        }
        break;

      default: auxValues[e.target.name] = e.target.value;
    }
    // // console.log( auxValues[e.target.name], e.target.name)
   console.log(auxValues)
    

    // // console.log(auxValues)
    setData(auxValues);
  };

  function handleBlurData(e) {
    e.preventDefault()
    if (!validaData(e.target.value)) {
      swal.fire('Data inválida!', 'Verifique a data', 'warning')
    }
  }

  function validaData(valor) {
    // Verifica se a entrada é uma string
    if (typeof valor !== 'string') {
      return false
    }

    // // console.log(valor)

    // Verifica formado da data
    if (!/^\d{4}\-\d{2}\-\d{2}$/.test(valor)) {
      // // console.log(valor)
      return false
    }

    // Divide a data para o objeto "data"
    const partesData = valor.split('-')
    const data = {
      ano: partesData[0],
      mes: partesData[1],
      dia: partesData[2]
    }

    // Converte strings em número
    const dia = parseInt(data.dia)
    const mes = parseInt(data.mes)
    const ano = parseInt(data.ano)

    // // console.log(valor, '123')
    // Dias de cada mês, incluindo ajuste para ano bissexto
    const diasNoMes = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Atualiza os dias do mês de fevereiro para ano bisexto
    if (ano % 400 === 0 || ano % 4 === 0 && ano % 100 !== 0) {
      diasNoMes[2] = 29
    }
    // // console.log(ano)
    // Verifica se ano maior que 1900
    if (ano < 1900) {
      return false
    }

    // Regras de validação:
    // Mês deve estar entre 1 e 12, e o dia deve ser maior que zero
    if (mes < 1 || mes > 12 || dia < 1) {
      return false
    }
    // Valida número de dias do mês
    else if (dia > diasNoMes[mes]) {
      return false
    }

    // Passou nas validações
    return true
  }

  function mostraModal(e) {
    setShowModal(true)
    setIte_pro(null)
    setIte_aliqIcms(0)
    setIte_aliqIpi(0)
    setIte_desc1(0)
    setIte_desc2(0)
    setIte_desc3(0)
    setIte_obs('')
    setIte_qtd(0)
    setIte_val(0)
    setIte_valIcms(0)
    setIte_valIpi(0)
    setIte_valSemImposto(0)
    setIte_valTot(0)

    setModal(true)
  }

  function abreModalNovoRegistro(e){
    e.preventDefault()
    if(data.FK_CLI > 0 && data.FK_CPG > 0 && data.DATA){
      setNomePro(null)
      mostraModal(e)
      setIte_val(state => 0)
      return setUpdateModal(false)
    }

    swal.fire('Dados inválidos.', 'Preencha os campos Cliente, Condição de pagamento e Data!', 'warning')
  }

  


  function closeModal(e) {
    e.preventDefault()
    setModal(false)
    setModalDoc(false)
    setModalProdutos(false)
    setModalMarca(false)
    setIte_pro(null)
    setNomePro(null)
    setIte_val(state => 0)
    setIte_aliqIpi(0)
  }

  function handleSubmitModalContatos(e) {
    e.preventDefault()
    if ((ite_pro && ite_qtd)) {
      if (!updatemodal){
        handleIncluiContato(e)
      }
      else{
        updateCon(e)
        closeModal(e)
      }
    } else
      swal.fire({
        title: 'Erro!',
        text: `Informe o produto e quantidade!`,
        icon: 'warning',
      })

  }

  async function updateCon(e) {
    e.preventDefault();
    let nome = ''
    let itens = [] 
    let i
    await api.get('/produtos?pk_pro='+ite_pro).then(r=>{
      nome = r.data[0].NOME_MACROPECAS
    })
   
    itens = dataIte.forEach((item, i) => {
      if (item.pk == pkModal) {
        itens.push( {
          NOMEPRO: nome,
          PRO: ite_pro,
          QTD: ite_qtd,
          VAL: ite_val,
          OBS: ite_obs,
          DESC1: ite_desc1,
          DESC2: ite_desc2,
          DESC3: ite_desc3,
          ALIQIPI: ite_aliqIpi,
          link: pro.LINK,
          VALIPI: ite_valIpi,
          ALIQICMS: ite_aliqIcms,
          VALICMS: ite_valIcms,
          VALTOT: ite_valTot,
          VALSEMIMPOSTO: ite_valSemImposto,
          pk: pkModal,
        })
      } else {
        itens.push(item)

      }
      if (i + 1 == dataIte.length){
        setDataIte(itens)
      }
    })
    // for (i = 0; i < dataIte.length; i++) {
    //   if (dataIte[i].pk == pkModal) {

    //     item.push({
    //       NOMEPRO: nome,
    //       PRO: ite_pro,
    //       QTD: ite_qtd,
    //       VAL: ite_val,
    //       OBS: ite_obs,
    //       DESC1: ite_desc1,
    //       DESC2: ite_desc2,
    //       DESC3: ite_desc3,
    //       ALIQIPI: ite_aliqIpi,
    //       VALIPI: ite_valIpi,
    //       ALIQICMS: ite_aliqIcms,
    //       VALICMS: ite_valIcms,
    //       VALTOT: ite_valTot,
    //       VALSEMIMPOSTO: ite_valSemImposto,
    //       pk: pkModal,
    //     })
    //   } else {
    //     item.push({
    //       NOMEPRO: dataIte[i].NOMEPRO,
    //         PRO: dataIte[i].PRO,
    //         QTD: dataIte[i].QTD,
    //         VAL: dataIte[i].VAL,
    //         OBS: dataIte[i].OBS,
    //         DESC1: dataIte[i].DESC1,
    //         DESC2: dataIte[i].DESC2,
    //         DESC3: dataIte[i].DESC3,
    //         ALIQIPI: dataIte[i].ALIQIPI,
    //         VALIPI: dataIte[i].VALIPI,
    //         ALIQICMS: dataIte[i].ALIQICMS,
    //         VALICMS: dataIte[i].VALICMS,
    //         VALTOT: dataIte[i].VALTOT,
    //         VALSEMIMPOSTO: dataIte[i].VALSEMIMPOSTO,
    //         PK_ITE: dataIte[i].PK_ITE,
    //         pk: dataIte[i].pk
    //     })
    //   }

      // if (i + 1 == dataIte.length)
      //   setDataCon(item)
    // }

  }

  useEffect(()=>{
    // // console.log(pkModal)
    if (pkModal != 0){

      let auxValues=dataIte.filter(item => item.pk == pkModal || item.PK_ITE == pkModal)
      // // console.log(auxValues)
      setIte_pro(auxValues[0].PRO)
      setNomePro(auxValues[0].NOMEPRO)
      setIte_aliqIcms(auxValues[0].ALIQICMS)
      setIte_aliqIpi(auxValues[0].ALIQIPI)
      setIte_desc1(auxValues[0].DESC1)
      setIte_desc2(auxValues[0].DESC2)
      setIte_desc3(auxValues[0].DESC3)
      setIte_obs(auxValues[0].OBS)
      setIte_qtd(auxValues[0].QTD)
      setIte_val(auxValues[0].VAL)
      setIte_valIcms(auxValues[0].VALICMS)
      setIte_valIpi(auxValues[0].VALIPI)
      setIte_valSemImposto(auxValues[0].VALSEMIMPOSTO)
      setIte_valTot(auxValues[0].VALTOT)
    }
  }, [pkModal])



  /// -------------- ajuste de posição de cursor - impede que va para ultima posição quando aplica o replace do regex
  // useEffect(() => {
  //   let p = pos
  //   if (p == 1 || p == 9) {
  //     p = p + 1
  //   }
  //   if ( p == 4 ){
  //     p = p + 2
  //   }
  //   if (con_fone_1 && modal) {
  //     if (p < con_fone_1.length) {
  //       if (document.getElementById('con_fone_1')) {
  //         document.getElementById('con_fone_1').selectionStart = p;
  //         document.getElementById('con_fone_1').selectionEnd = p;
  //       }
  //     }
  //   }
  // }, [con_fone_1, modal])

 
  /// -------------- ajuste de posição de cursor - impede que va para ultima posição quando aplica o replace do regex


  const handleChangeModal = (e) => {
    var target = e.target.name
        // // console.log(target, e.target.value)
        switch (target) {
            case 'ite_pro': setIte_pro(e.target.value)
            break;
            case 'ite_qtd': setIte_qtd(e.target.value)
            break;
            case 'ite_val': setIte_val(e.target.value)
            break;
            case 'ite_obs': setIte_obs(e.target.value)
            break;
            case 'ite_desc1': setIte_desc1(e.target.value)
            break;
            case 'ite_desc2': setIte_desc2(e.target.value)
            break;
            case 'ite_desc3': setIte_desc3(e.target.value)
            break;
            case 'ite_aliqIpi': setIte_aliqIpi(e.target.value)
            break;
            case 'ite_valIpi': setIte_valIpi(e.target.value)
            break;
            case 'ite_aliqIcms': setIte_aliqIcms(e.target.value)
            break;
            case 'ite_valIcms': setIte_valIcms(e.target.value)
            break;
            case 'ite_valTot': setIte_valTot(e.target.value)
            break;
            case 'ite_valSemImposto': setIte_valSemImposto(e.target.value)
            break;
        }

  };

  const isDupliData = async (e, id) => {
    e.preventDefault()
    // // console.log(e, pk_cli)
    let registro;
    let campo = e ? e.target.name : ''
    let valor = e ? onlyNumbers(e.target.value) : ''



    switch (campo) {
      // case 'cnpj_cpf':
      //   if (valor != null && valor != '') {
      //     await api.get('/pedidos?cnpj_cpf=' + valor).then(r => {
      //       registro = r.data[0]
      //       // // console.log(registro.pk_cli)
      //     })
      //     // // console.log(registro)
      //     if (registro != null && registro.pk_cli != Number(pk_cli)) {
      //       setErroDupliCnpjCpf(true)
      //       if (id == 'cnpj') {
      //         setDupliCpfCnpj('CNPJ')
      //       } else {
      //         setDupliCpfCnpj('CPF')
      //       }
      //       window.setTimeout(function () {
      //         document.getElementById(campo).focus();
      //       }, 0);
      //     } else {
      //       setErroDupliCnpjCpf(false)
      //     }
      //     break;
      //   } else {
      //     setErroDupliCnpjCpf(false)
      //     break;
      //   }
      // case 'insc_estadual':
      //   if (valor != null && valor != '') {
      //     await api.get('/pedidos?insc_estadual=' + e.target.value).then(r => {
      //       registro = r.data[0]
      //     })
      //     // // console.log(registro)
      //     if (registro != null && registro.pk_cli != Number(pk_cli)) {
      //       setErroDupliInscEst(true)
      //       window.setTimeout(function () {
      //         document.getElementById(campo).focus();
      //       }, 0);
      //     } else {
      //       setErroDupliInscEst(false)
      //       break;
      //     }
      //   } else {
      //     setErroDupliInscEst(false)
      //   }
      //   break;
      default:
        return false;
    }
  }



  function handleIncluiContato(){

    let pk = -1
    let item = []
    let itens = dataIte.filter(item => item.pk || item.PK_ITE )
    // // console.log(itens)
    if (!dataIte) {
        setDataIte([{
        NOMEPRO: pro.NOME_MACROPECAS,
        PRO: ite_pro,
        QTD: ite_qtd,
        VAL: ite_val,
        OBS: ite_obs,
        DESC1: ite_desc1,
        DESC2: ite_desc2,
        DESC3: ite_desc3,
        ALIQIPI: ite_aliqIpi,
        link: pro.LINK,
        VALIPI: ite_valIpi,
        ALIQICMS: ite_aliqIcms,
        VALICMS: ite_valIcms,
        VALTOT: ite_valTot,
        VALSEMIMPOSTO: ite_valSemImposto,
        pk: -1,
        }])

    } else {

        var i;
        for (i = 0; i < itens.length; i++) {
        item.push({
            NOMEPRO: itens[i].NOMEPRO,
            PRO: itens[i].PRO,
            QTD: itens[i].QTD,
            VAL: itens[i].VAL,
            OBS: itens[i].OBS,
            DESC1: itens[i].DESC1,
            DESC2: itens[i].DESC2,
            DESC3: itens[i].DESC3,
            link: itens[i].LINK,
            ALIQIPI: itens[i].ALIQIPI,
            VALIPI: itens[i].VALIPI,
            ALIQICMS: itens[i].ALIQICMS,
            VALICMS: itens[i].VALICMS,
            VALTOT: itens[i].VALTOT,
            VALSEMIMPOSTO: itens[i].VALSEMIMPOSTO,
            PK_ITE: itens[i].PK_ITE,
            pk: itens[i].pk

        })
        pk--
        }
        item.push({
            NOMEPRO: pro.NOME_MACROPECAS,
            PRO: ite_pro,
            QTD: ite_qtd,
            VAL: ite_val,
            OBS: ite_obs,
            DESC1: ite_desc1,
            DESC2: ite_desc2,
            DESC3: ite_desc3,
            link: pro.LINK,
            ALIQIPI: ite_aliqIpi,
            VALIPI: ite_valIpi,
            ALIQICMS: ite_aliqIcms,
            VALICMS: ite_valIcms,
            VALTOT: ite_valTot,
            VALSEMIMPOSTO: ite_valSemImposto,
            pk: pk,
        })
        pk--
        setDataIte(item.filter(i => { return i.PRO }))
    }
    setNomePro(null)
    mostraModal()
    setIte_val(state => 0)
    // // console.log(dataIte, item)
}

  function escondeModal(e) {
    e.preventDefault();
    setShowModal(false)
  }
  useEffect(() => {
    console.log(showModal, '---------------------')
  }, [showModal])

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      auxValues["FK_CLI"] = cli;
      auxValues["NOMECLI"] = ''
      if (cli > 0) {
        await api.get("/clientes?pk_cli=" + cli).then((r) => {
          if (r.data[0]){
            auxValues["NOMECLI"] = r.data[0].RAZAO_SOCIAL;
            setData(state => {
              return {
                ...state,
                NOMECLI: r.data[0].RAZAO_SOCIAL,
                FK_CLI: r.data[0].PK_CLI
              }
            });
            

          }
        });
      }
    };
    fetchMdc();
  }, [cli]);

  useEffect(() => {
    

    console.log(cli, clientes.length> 1)
    if(cli > 0 && clientes.length>1){
      let cliente = clientes.filter(item => item.PK_CLI == cli)
      if(cliente && cliente[0].UF){
        setCid({FK_EST: cliente[0].FK_EST, PK_CID: cliente[0].FK_CID, UF: cliente[0].UF})
        let uf = cliente[0].UF;
        let r1 = [ 'RS', 'SP', 'SC', 'MG', 'RJ' ];
          let r3 = [ 'PE' ];
          let r4 = [ 'AL', 'BA', 'CE', 'MA', 'PI', 'PB', 'RN', 'SE' ];
          if (uf === 'PR') {
            setRegiao(0);
          } else if (r1.indexOf(uf) !== -1) {
            setRegiao(1);
          } else if (r3.indexOf(uf) !== -1) {
              setRegiao(3);
            } else if (r4.indexOf(uf) !== -1) {
              setRegiao(4);
            } else {
              setRegiao(2);
            }
          }else {
        // // console.log(cliente, cliente[0].UF)
          swal.fire('Atenção', 'Cliente Sem Região Cadastrada!', 'warning')
              
      }
      
    }
}, [cli, pro, clientes])

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...data };
      console.log(cpg)
      auxValues["NOMECPG"] = ''
      if (cpg > 0) {
        await api.get("/cond_pag?pk_cpg=" + cpg).then((r) => {
          if (r.data[0]){
            setDescCpg(r.data[0].DESCONTO)
            setData(state => {
              return {
                ...state,
                NOMECPG: r.data[0].NOME,
                FK_CPG: r.data[0].PK_CPG
              }
            });

          }
        });
      }
    };
    fetchMdc();
  }, [cpg]);

  useEffect(() => {
    const fetchMdc = async () => {
      if (ite_pro > 0) {
        await api.get("/produtos?pk_pro=" + ite_pro).then(async (r) => {
          if (r.data[0]){
            console.log(r.data[0].PK_PRO)
            setNomePro(r.data[0].NOME_MACROPECAS)
            setPro(r.data[0])
            setIte_obs(r.data[0].OBS_PROMOCIONAL)
            let imagem = await api.getFoto(1, r.data[0].PK_PRO).then(res => {
              console.log(res)
              if (res)
                setImagens(res)
              else setImagens(null)
            })
          }
        });
      }
    };
    fetchMdc();
  }, [ite_pro]);

  // useEffect(() => {
  //   setNomePro(pro.DESCRICAO_MACROPECAS)
  // }, [pro])

  // useEffect(() => {
  //   const fetchMdc = async () => {
  //     const auxValues = { ...data };
      
  //     if (pro > 0) {
  //       await api.get("/produtos?pk_pro=" + pro).then((r) => {
  //         if (r.data[0]){
  //           setNomePro(r.data[0].DESCRICAO_MACROPECAS);
  //           setIte_pro(pro)
  //           setData(auxValues);

  //         }
  //       });
  //     }
  //   };
  //   fetchMdc();
  // }, [pro]);




  useEffect(() => {
    let cidade = cid
    let percSt = 0;
    if (cidade.length > 0) {
      let destino = cidade[0].FK_EST;
      // // console.log(destino, ' destino aqui')
      if (regiao === 3 || regiao === 4) {
                api.post('/st_icms?destino='+destino+'&fk_pro='+ite_pro).then(r=> {
                    if(r){
                        setSt(r.data[0].value);
                    } else {
                        setSt(percSt)
                    }
                })
        
      } else {

        api.post('/st_icms?destino='+destino+'&fk_pro='+ite_pro+'origem=PR').then(r=> {
                    if(r.data){
                        setSt(r.data[0].value);
                    } else {
                        setSt(percSt)
                    }
                })
      }
    }
  }, [cid, ite_pro, regiao])

  function pegaPreco(tipo, regiao, produto) {
    let preco = 0;
    console.log('teste ----------', tipo, regiao, produto)
    if (tipo === 'PROMO') {
      if (regiao === 0) {
        preco = produto.PRECO_VENDA_PROMO;
        setPromoGrupo(0)
        if(preco === null || preco === 0){
          preco = produto.PRECO_VENDA_LISTA * 0.63;
          setPromoGrupo(37)
        }
      } else {
        preco = produto['PRECO_PROM_REGIAO_' + regiao];
        if(preco === null || preco === 0){
          preco = produto['PRECO_REGIAO_' + regiao] * 0.63;
          setPromoGrupo(37)
        }
      }
    } else {
      if (regiao === 0) {
        preco = produto.PRECO_VENDA_LISTA * 0.63;
        setPromoGrupo(37)
      } else {
        preco = produto['PRECO_REGIAO_' + regiao] ;
        setPromoGrupo(0)
      }
    }
    return preco;
  }



  function precoUnit(produto, recalculando) {
    let preco = 0;
    let testeDate = new Date(data.DATA)
    console.log(compareDate(testeDate,produto.DATA_VALID_PROMO), testeDate, produto.DATA_VALID_PROMO)
    if (compareDate(testeDate,produto.DATA_VALID_PROMO) === 1) {
      console.log('comparou data true')
      preco = pegaPreco('LISTA', regiao, produto);
      produto.OBS_PROMOCIONAL = '';
    } else {
      console.log('comparou data false')
      preco = pegaPreco('PROMO', regiao, produto);
    }
    if (preco > 0) {
      return preco;
    } else if (produto.codigo > 0 && recalculando !== true) {
          swal.fire('Atenção', 'Preço Unitário não foi encontrado! Verifique no menu "Produtos"', 'warning');

    return 0;
    }
  }

  useEffect(() => {
    if(data.FK_REP > 0){
      api.post('/desconto_logistico/aplica?fk_rep='+(Number(data.FK_REP))).then(r => {

          setAplicaDescLog(r.data[0].APLICA_DESC_LOGISTICO)
      })
    }

  }, [data.FK_REP])

  async function aplicaDescontos(preco) {
    let dataPedido = new Date();
    let dataPed = dataPedido.toISOString().split('T')[0]
    let descontoLog = 0;
    if(aplicaDescLog == 'S'){
      let desconto = await api.post('/desconto_logistico?mes='+(Number(dataPedido.getMonth()+ 1) )+'&ano='+
                      dataPedido.getFullYear()+'&data_limite='+dataPed)
      // console.log(desconto.data, '-----------------------aplica desc')
      if(desconto.data.length > 0){
          descontoLog = desconto.data[0].DESCONTO;
          setDescLogistico(desconto.data[0].DESCONTO)
      }else {
        setDescLogistico(0)
      }

    } else {
      setDescLogistico(0)
    }
    
    let calc = (preco - preco * (descontoLog / 100)).toFixed(2);
    preco = Number(calc);
    let descontoCpg = descCpg || 0;
    console.log(descontoCpg, preco, (preco - preco * (descontoCpg / 100)) )
    calc = (preco - preco * (descontoCpg / 100)).toFixed(2);
    preco = Number(calc);
    console.log(preco)
    return (preco);  

  }

  function descontosDigitados(preco) {
    preco =
    preco *
    (1 - (Number(ite_desc1) || 0) / 100) *
    (1 - (Number(ite_desc2) || 0) / 100) *
    (1 - (Number(ite_desc3) || 0) / 100);
    return Number(preco);
  }

  useEffect(()=>{
    async function aplicaValores(){

        if (!pro) return null
        let name
        let valor = 0;
        let valorIcms = 0;
        let percIcms = 0;
        let valorIpi = 0;
        let ipi = 0;
        let valSemImposto = 0;
        let total = 0;
        if (pro.IPI > 0) {
            ipi = pro.IPI;
        }
        // let st = Number(pegaStIcms());
        let preco = precoUnit(pro);
        preco = await aplicaDescontos(preco);

        valor = preco 

        percIcms = st || 0;
        let precodscto = descontosDigitados(preco);
        valSemImposto = Number(precodscto.toFixed(2)) * Number(ite_qtd);
        // console.log(precodscto.toFixed(2),ite_qtd, '-------preco')
        valorIpi = (ipi * (valSemImposto / 100)).toFixed(2);
        valorIpi = Number(valorIpi);
        valorIcms = Number(
            Number(Number(st) * (Number(Number(valSemImposto) + Number(valorIpi)) / 100)).toFixed(2)
        );
        valorIcms = Number(valorIcms);
        total = (valSemImposto +
            valorIpi +
            valorIcms).toFixed(2);
        total = Number(total);
        
        if (name === 'DESC_GRUPO_PROD' ||
            name === 'DESC_PROMOCIONAL' ||
            name === 'DESC_LOGISTICO' ||
            name === 'DESC_DIF_ICMS' ||
            name === 'DESC_COND_PAG' ||
            name === 'DESC_CLAS_CLIENTE'
        ) {
            // this.calculaPrecos()
        }
        setIte_val(valor)
        setIte_valSemImposto(valSemImposto)
        setIte_valIcms(valorIcms)
        setIte_valIpi(valorIpi)
        setIte_valTot(total)
        setIte_aliqIpi(ipi)
        setIte_aliqIcms(percIcms)
    }
    aplicaValores()
  },[ite_qtd, ite_desc1, ite_desc2, ite_desc3, pro ])

  useEffect(() => {
    setValPromoGrupo(pro.PRECO_VENDA_LISTA * promoGrupo / 100)
    setValApliqPromoGrupo(pro.PRECO_VENDA_LISTA - (pro.PRECO_VENDA_LISTA * promoGrupo / 100))
    
  }, [pro.PRECO_VENDA_LISTA, promoGrupo])

  useEffect(() => {
    setValDescPromo(valApliqPromoGrupo * descPromo / 100)
    setValApliqDescPromo(valApliqPromoGrupo - (valApliqPromoGrupo * descPromo / 100))
  }, [descPromo, valApliqPromoGrupo])

  useEffect(() => {
    setValDescLog(valApliqPromoGrupo * descLogistico / 100)
    setValApliqDescLog(valApliqPromoGrupo - (valApliqPromoGrupo * descLogistico / 100))
  }, [descLogistico, valApliqPromoGrupo])

  useEffect(() => {
    setValDescIcms(valApliqDescLog * st / 100)
    setValApliqDescIcms(valApliqDescLog - (valApliqDescLog * st / 100))
  }, [st, valApliqDescLog])

  useEffect(() => {
    setValDescCpg(valApliqDescIcms * (descCpg) / 100)
    setValApliqDescCpg(valApliqDescIcms - (valApliqDescIcms * (descCpg) / 100))
  }, [descCpg, valApliqDescIcms])

  useEffect(() => {
    setValDescClasCli(valApliqDescCpg * (descClasCli) / 100)
    setValApliqDescClasCli(valApliqDescCpg - (valApliqDescCpg * (descClasCli) / 100))
  }, [descClasCli, valApliqDescCpg])

  // useEffect(() => {
  //   console.log(ite_val)
  // }, [ite_val])

  
  useEffect(() => {
    if(data.FK_CPG > 0){
        api.post('/cond_pag?pk_cpg='+data.FK_CPG).then(r => {
          // // //console.log(r.data[0])
            if(r.data.length>0){
                setCpg(r.data[0].PK_CPG)
                setDescCpg(r.data[0].DESCONTO)
                if(dataIte.length>0){
                    recalculaItens()
                    setRecalculando(true)
                }
            }
        })
    }

   }, [data.FK_CPG])

    async function recalculaItens(){
        let itens = []
        let excluidos = [...arrayExcluidos]
        var pk = -1
        var i;
        console.log(dataIte)
        for (i = 0; i < dataIte.length; i++) {
            let name
            let valor = 0;
            let valorIcms = 0;
            let percIcms = 0;
            let valorIpi = 0;
            let ipi = 0;
            let valSemImposto = 0;
            let total = 0;
            if(dataIte[i].PK_IPE)
                excluidos.push(dataIte[i].PK_IPE)
            if (dataIte[i].ALIQIPI > 0) {
                ipi = dataIte[i].ALIQIPI;
            }
            // let st = Number(pegaStIcms()); 
            let pro = await api.post('/produtos?pk_pro='+dataIte[i].PRO)
            console.log(pro.data[0], 'antes do aplicadesc')
            let preco = precoUnit(pro.data[0]);
            preco = await aplicaDescontos(preco);
            //console.log(preco, '-------preco')
            valor = preco;
            percIcms = st || 0;
            let precodscto = preco *
                             (1 - (Number(dataIte[i].DESC1 || 0) || 0) / 100) *
                             (1 - (Number(dataIte[i].DESC2 || 0) || 0) / 100) *
                             (1 - (Number(dataIte[i].DESC3 || 0) || 0) / 100);
            valSemImposto = Number(precodscto.toFixed(2)) * Number(dataIte[i].QTD);
            valorIpi = (ipi * (valSemImposto / 100)).toFixed(2);
            valorIpi = Number(valorIpi);
            valorIcms = Number(
                Number(Number(st) * (Number(Number(valSemImposto) + Number(valorIpi)) / 100)).toFixed(2)
            );
            valorIcms = Number(valorIcms);
            total = (valSemImposto +
                valorIpi +
                valorIcms).toFixed(2);
            total = Number(total);
            console.log(pro,
                preco,
                valorIpi,
                valorIcms)
            
            if (name === 'DESC_GRUPO_PROD' ||
                name === 'DESC_PROMOCIONAL' ||
                name === 'DESC_LOGISTICO' ||
                name === 'DESC_DIF_ICMS' ||
                name === 'DESC_COND_PAG' ||
                name === 'DESC_CLAS_CLIENTE'
            ) {
                // this.calculaPrecos()
            }
            // setIte_val(valor)
            // setIte_valSemImposto(valSemImposto)
            // setIte_valIcms(valorIcms)
            // setIte_valIpi(valorIpi)
            // setIte_valTot(total)
            // setIte_aliqIpi(ipi)
            // setIte_aliqIcms(percIcms)
            // setLoadingPro(false)
            itens.push({
                NOMEPRO: dataIte[i].NOMEPRO,
                PRO: dataIte[i].PRO,
                QTD: dataIte[i].QTD,
                VAL: valor,
                OBS: dataIte[i].OBS,
                DESC1: dataIte[i].DESC1 || 0,
                DESC2: dataIte[i].DESC2 || 0,
                DESC3: dataIte[i].DESC3 || 0,
                ALIQIPI: ipi,
                VALIPI: valorIpi,
                ALIQICMS: percIcms,
                VALICMS: valorIpi,
                VALTOT: total,
                VALSEMIMPOSTO: valSemImposto,
                pk: pk,
            })
            pk--
        }
        console.log(itens, 'result itens')
        setDataIte(itens)
        setArrayExcluidos(excluidos)
        setRecalculando(false)
    }


  return (
    <div>

    
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={modal} fullWidth={true} maxWidth={'sm'}>
              <div className="register" hidden={!modal}>
              <Tabs  
                aba1_caption="Valores" 
                aba1_hidden={false} 
                aba2_caption="Descontos" 
                aba2_hidden={false} 
                aba3_hidden aba4_hidden aba5_hidden 
                aba6_hidden aba7_hidden 
                Aba1={
                  <div className="fields">
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
                      <AutoComp
                        value={nomePro || ""}
                        valor={ite_pro || ''}
                        width={300}
                        name="ite_pro"
                        id="ite_pro"
                        label='Produto'
                        data={comboPro}
                        setValue={setIte_pro}
                      />
                      {pro && imagens && 
                        <>
                          <Tooltip title='Fotos do produto'>
                            <DivMostraFoto size={150} >
                                <a href={pro.LINK} target='_blank'>
                            {/* <p>imagem</p> */}
                                    <Foto src={imagens.url} style={{}} size={150}/>
                                </a>  
                                
                            </DivMostraFoto>
                          </Tooltip>
                          {/* <Tooltip title='Link do produto'>
                            <a href={pro.LINK} target='_blank'>
                              <Icon
                                className="tableDelete"
                                icon={externalLink}
                                size={18}
                              />
                            </a>
                          </Tooltip> */}
                        </>
                      }
                    </div> 

                    
                    <div>
                        <FrTextInput 
                            label='Quantidade' 
                            value={ite_qtd || 0} 
                            id='ite_qtd' 
                            width={'40%'}
                            name='ite_qtd' 
                            variant="outlined"
                            size="small"
                            onChange={handleChangeModal} 
                        />
                        <FrTextInput 
                            label='Valor Unit.' 
                            value={ite_val} 
                            id='ite_val' 
                            disabled
                            variant="outlined"
                            size="small"
                            name='ite_val' 
                            onChange={handleChangeModal} 
                        />

                    </div>
                    <FrTextInput
                      maxLength='1000'
                      value={ite_obs || ''}
                      onChange={handleChangeModal}
                      name="ite_obs"
                      id="ite_obs"
                      color="#528b46"
                      label="Obs Promocional"
                      variant="outlined"
                      size="small"
                      style={{ width: 400 }}
                      multiline
                      disabled
                      rows="3"
                      rowsMax="8"
                      fullWidth
                    />
                    <div style={{flexDirection: 'row'}}>
                        <FrTextInput 
                            label='Desconto 1 (%)' 
                            value={ite_desc1} 
                            id='ite_desc1' 
                            name='ite_desc1' 
                            variant="outlined"
                            size="small"
                            width={'40%'}
                            onChange={handleChangeModal} 
                        />
                        <FrTextInput 
                            label='Desconto 2 (%)' 
                            variant="outlined"
                            size="small"
                            value={ite_desc2} 
                            id='ite_desc2' 
                            name='ite_desc2' 
                            width={'40%'}
                            onChange={handleChangeModal} 
                        />

                    </div>
                    <div style={{flexDirection: 'row'}}>
                        <FrTextInput 
                            label='Desconto 3 (%)' 
                            value={ite_desc3} 
                            id='ite_desc3' 
                            name='ite_desc3' 
                            variant="outlined"
                            size="small"
                            width={'40%'}
                            onChange={handleChangeModal} 
                        />
                        <FrTextInput 
                            label='Valor S/ Imposto' 
                            value={ite_valSemImposto} 
                            id='ite_valSemImposto' 
                            name='ite_valSemImposto' 
                            disabled
                            variant="outlined"
                            size="small"
                            width={'40%'}
                            readOnly
                            onChange={handleChangeModal} 
                        />

                    </div>
                    <div style={{flexDirection: 'row'}}>
                        <FrTextInput 
                            label='Aliq. IPI (%)' 
                            value={ite_aliqIpi} 
                            variant="outlined"
                            size="small"
                            id='ite_aliqIpi' 
                            disabled
                            name='ite_aliqIpi' 
                            width={'35%'}
                            marginRight={0}
                            readOnly
                            onChange={handleChangeModal} 
                        />
                        <FrTextInput 
                            label='Valor IPI' 
                            value={ite_valIpi} 
                            id='ite_valIpi' 
                            variant="outlined"
                            size="small"
                            disabled
                            name='ite_valIpi' 
                            width={'50.5%'}
                            marginLeft={'3%'}
                            readOnly
                            onChange={handleChangeModal} 
                        />

                    </div>
                    <div style={{flexDirection: 'row'}}>
                        <FrTextInput 
                            label='Aliq. STICMS (%)' 
                            value={ite_aliqIcms} 
                            id='ite_aliqIcms' 
                            variant="outlined"
                            size="small"
                            disabled
                            name='ite_aliqIcms' 
                            width={'35%'}
                            readOnly
                            marginRight={0}
                            onChange={handleChangeModal} 
                        />
                        <FrTextInput 
                            label='Valor STICMS' 
                            value={ite_valIcms} 
                            variant="outlined"
                            size="small"
                            id='ite_valIcms' 
                            disabled
                            name='ite_valIcms' 
                            width={'50.5%'}
                            marginLeft={'3%'}
                            readOnly
                            onChange={handleChangeModal} 
                        />

                    </div>
                      <FrTextInput 
                        label='Valor Total' 
                        value={ite_valTot} 
                        variant="outlined"
                        size="small"
                        id='ite_valTot' 
                        disabled
                        name='ite_valTot' 
                        width={'63.5%'}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />


                  </div> } 
                Aba2={
                  <div style={{display: 'flex', flexDirection:'column', paddingTop: 20, alignItems: 'center' }}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', marginBottom: 20, width: '100%'}}>
                      <FrTextInput 
                        label='Preço Venda Lista' 
                        value={formataReal(pro.PRECO_VENDA_LISTA || 0) || ''} 
                        id='ite_preco_venda_lista' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_preco_venda_lista' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                    </div>  
                    <div style={{display: 'flex', flexDirection: 'column',marginBottom: 20, width: '100%'}}> 
                      <p style={{marginLeft: 20}}>Grupo de Produto</p>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <FrTextInput 
                          label='(%)' 
                          value={promoGrupo} 
                          id='promoGrupo' 
                          variant="outlined"
                          size="small"
                          disabled
                          name='promoGrupo' 
                          style={{ width: "30%"}}
                          marginLeft={'3%'}
                          readOnly
                          onChange={handleChangeModal} 
                        />
                        <FrTextInput 
                          label='' 
                          value={formataReal(valPromoGrupo || 0) || ''} 
                          id='ite_valIpi' 
                          variant="outlined"
                          size="small"
                          disabled
                          name='ite_valIpi' 
                          style={{ width: "30%"}}
                          marginLeft={'3%'}
                          readOnly
                          onChange={handleChangeModal} 
                        />
                        <FrTextInput 
                          label='' 
                          value={formataReal(valApliqPromoGrupo || 0) || ''} 
                          id='ite_valIpi' 
                          variant="outlined"
                          size="small"
                          disabled
                          name='ite_valIpi' 
                          style={{ width: "30%"}}
                          marginLeft={'3%'}
                          readOnly
                          onChange={handleChangeModal} 
                        />
                      </div>


                    </div>
                    <div style={{display: 'flex', flexDirection: 'column',marginBottom: 20, width: '100%'}}> 
                    <p style={{marginLeft: 20}}>Promocional</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                      <FrTextInput 
                        label='(%)' 
                        value={descPromo} 
                        id='descPromo' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='descPromo' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                      <FrTextInput 
                        label='' 
                        value={formataReal(valDescPromo || 0) || ''} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                      <FrTextInput 
                        label='' 
                        value={formataReal(valApliqDescPromo || 0) || ''} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                    </div>

                    </div>
                    <div style={{display: 'flex', flexDirection: 'column',marginBottom: 20, width: '100%'}}> 
                    <p style={{marginLeft: 20}}>Logistico</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                      <FrTextInput 
                        label='(%)' 
                        value={descLogistico} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                      <FrTextInput 
                        label='' 
                        value={formataReal(valDescLog || 0) || ''} 
                        id='valDescLog' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='valDescLog' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                      <FrTextInput 
                        label='' 
                        value={formataReal(valApliqDescLog || 0) || ''} 
                        id='valApliqPromoGrupo' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='valApliqPromoGrupo' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                    </div>

                    </div>
                    <div style={{display: 'flex', flexDirection: 'column',marginBottom: 20, width: '100%'}}> 
                    <p style={{marginLeft: 20}}>Diferença ICMS</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                      <FrTextInput 
                        label='(%)' 
                        value={st || 0} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />

                      <FrTextInput 
                        label='' 
                        value={formataReal(valDescIcms || 0) || ''} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />

                      <FrTextInput 
                        label='' 
                        value={formataReal(valApliqDescIcms || 0) || ''} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                    </div>

                    </div>
                    <div style={{display: 'flex', flexDirection: 'column',marginBottom: 20, width: '100%'}}> 
                    <p style={{marginLeft: 20}}>Desconto da Cond. Pag</p> 
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                      <FrTextInput 
                        label='(%)' 
                        value={descCpg || '0'} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />

                      <FrTextInput 
                        label='' 
                        value={formataReal(valDescCpg || 0) || ''} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />

                      <FrTextInput 
                        label='' 
                        value={formataReal(valApliqDescCpg || 0) || ''} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                    </div>

                    </div>
                    <div style={{display: 'flex', flexDirection: 'column',marginBottom: 20, width: '100%'}}> 
                    <p style={{marginLeft: 20}}>Classificação Cliente</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                      <FrTextInput 
                        label='(%)' 
                        value={descClasCli || 0} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                      <FrTextInput 
                        label='' 
                        value={formataReal(valDescClasCli || 0) || ''} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                      <FrTextInput 
                        label='' 
                        value={formataReal(valApliqDescClasCli || 0) || ''} 
                        id='ite_valIpi' 
                        variant="outlined"
                        size="small"
                        disabled
                        name='ite_valIpi' 
                        style={{ width: "30%"}}
                        marginLeft={'3%'}
                        readOnly
                        onChange={handleChangeModal} 
                      />
                    </div>

                    </div>


                  </div>
                }
              />
              </div>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <div className="regButtonsDiv">
                <button onClick={handleSubmitModalContatos}>Salvar</button>
                <button onClick={closeModal}>Cancelar</button>
              </div>

            </div>
          </div>
        </Dialog>
        <FrModalConfirmaPed aria-labelledby="simple-dialog-title" open={showModalConfirma} fullWidth={true} maxWidth={'xs'}>
          
          <div className="register" hidden={!showModalConfirma} style={{padding: 20, backgroundColor: 'var(--cor-tema)'}}>
            <FrSelect
              value={data.ORCAMENTO || ''}
              onChange={handleChange}
              name="ORCAMENTO"
              id="ORCAMENTO"
              label='Tipo'
              required
              data={[{ value: 'N', display: 'Pedido' }, { value: 'S', display: 'Orçamento' }]}
              style={{ width: 240, marginBottom: 8}}
            />
            <p>
                {`Total Produtos:  R$ ${totSemImposto.toFixed(2)}`}
            </p>
            
            <p>
                {`Total IPI:  R$ ${totIpi.toFixed(2)}`}
            </p>
            
            <p>
                {`Total ST ICMS:  R$ ${totIcms.toFixed(2)}`}
            </p>
            
            <p>
                {`Total do Pedido:  R$ ${tot.toFixed(2)}`}
            </p>
                        
          </div>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <div className="regButtonsDiv">
                <button onClick={handleSubmit}>Salvar</button>
                <button onClick={closeConfirma}>Cancelar</button>
              </div>

            </div>
          </div>
        </FrModalConfirmaPed>
      </div>


      <div className="defaultReg">
        <NavBar />
        <div className="defaultRegContent">
          <div className="defaultHeader">
            <h3>{updateType ? 'Edição' : 'Inclusão'} de Pedidos</h3>
            <UserOptions />
          </div>
          <br />
          <form id="regForm" onSubmit={handleSubmit}>

            <div  style={{ paddingTop: '10px', display: 'flex', flexWrap: 'wrap', maxWidth: 1000, justifyContent: 'flex-start'}}>
                
              <div>


              <FrSelect
                  value={data.ORCAMENTO || ''}
                  onChange={handleChange}
                  name="ORCAMENTO"
                  id="ORCAMENTO"
                  label='Tipo'
                  required
                  data={[{ value: 'N', display: 'Pedido' }, { value: 'S', display: 'Orçamento' }]}
                  style={{ width: 140, marginBottom: 8}}
                />
                <FrSelect
                  value={data.FK_REP || ''}
                  onChange={handleChange}
                  name="FK_REP"
                  id="FK_REP"
                  label='Representada'
                  data={comboRep}
                  style={{ width: 240, marginBottom: 8}}
                />

                <FrTextInput
                  value={data.PK_PED || ''}
                  onChange={handleChange}
                  name="PK_PED"
                  id="PK_PED"
                  color="#528b46"
                  label="Nr. Pedido"
                  variant="outlined"
                  size="small"
                  disabled
                  style={{ width: 150 }}
                />

                <FrDatePicker
                  value={(data.DATA || '').split('T')[0]}
                  onChange={handleChange}
                  name="DATA"
                  id="DATA"
                  color="#49573B"
                  label="Data"
                  variant="outlined"
                  size="small"
                  disabled
                  style={{ width: 200 }}
                />
                </div>
                
                <AutoComp
                    value={data.NOMECLI || ""}
                    valor={data.FK_CLI || ''}
                    width={970}
                    name="FK_CLI"
                    id="FK_CLI"
                    label='Cliente'
                    reg
                    data={comboCli}
                    setValue={setCli}
                  />
                <AutoComp
                    value={data.NOMECPG || ""}
                    valor={data.FK_CPG || ''}
                    width={970}
                    name="FK_CPG"
                    id="FK_CPG"
                    reg
                    label='Condição de Pagamento'
                    data={comboCpg}
                    setValue={setCpg}
                  />
                  
                  <FrTextInput
                    maxLength='1000'
                    value={data.OBSERVACAO || ''}
                    onChange={handleChange}
                    name="OBSERVACAO"
                    id="OBSERVACAO"
                    color="#528b46"
                    label="Observações"
                    variant="outlined"
                    size="small"
                    style={{ width: 970 }}
                    multiline
                    rows="3"
                    rowsMax="8"
                    fullWidth
                  />
              

                {/* <div hidden={!updateType} className="regButtons">

                  <button onClick={(e) => mostraModalNegocios(e, data.pk_cli, 0)}>
                    Negócios Compra
                  </button>

                  <button onClick={(e) => mostraModalNegocios(e, 0, data.pk_cli)}>
                    Negócios Venda
                  </button>
                </div> */}
                <div style={{marginLeft: 10}}>
                
                  <br/>
                  <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                  
                  </div>
                 
                </div>

              <div class="row" style={{ paddingTop: '10px', paddingLeft: '30px' }}>
                <div className="grupoEdits">
                  <button onClick={(e) => abreModalNovoRegistro(e)} disabled={!data.FK_CLI || !data.FK_CPG || !data.DATA}>
                    <Icon icon={ic_add_box} size={18} />
                    Incluir
                  </button>
                  <div style={{width: 1086, height: 580}} className='tableReg'>
                  {recalculando ?
                  // <div style={{display: 'flex', flexDirection: 'rows',justifyContent: 'center', alignItems: 'center'}}>
                    <Icon icon={spinner8} size={18} className='loader' />
                   
                  // </div>

                  :
                    <FrTableReg 
                      width={95} 
                      setPkModal={setPkModal} 
                      setUpdateModal={setUpdateModal} 
                      setModal={setModal} 
                      setCon_Tratamento={setCon_Tratamento} 
                      setCon_Nome={setCon_Nome}
                      setCon_Cargo={setCon_Cargo} 
                      setCon_Fone_1={setCon_Fone_1}  
                      setCon_Cpf={setCon_Cpf} 
                      setCon_Mes={setCon_Mes} 
                      setCon_Dia={setCon_Dia} 
                      setCon_Observacoes={setCon_Observacoes} 
                      setCon_Email={setCon_Email}
                      setCon_Mala_Direta={setCon_Mala_Direta} 
                      setCon_Bbm={setCon_Bbm} 
                      setCon_Ct={setCon_Ct} 
                      setCon_Msn={setCon_Msn}
                      editar={true} 
                      setDataGrid={setDataIte} 
                      detail 
                      verFotos
                      verLink
                      regName="produtos" 
                      columns={columnsCon} 
                      arrayExcluidos={excluidosCon} 
                      setArrayExcluidos={setExcluidosCon} 
                      searched={false} 
                      codeName="display" 
                      page={''} 
                      data={dataIte}
                      functionDelete={'/pedidos/deletaPro/'} 
                      sortable={true} 
                      orderBy={'nome'}
                    />

                  }
                  </div>
                </div>
              </div>

             

            </div>

          </form>
          <div className="regButtonsDiv">
            {enableEdit === 'S' ?
              (<button onClick={confirma}>Salvar</button>) : (<></>)}
          
            <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
          </div>
          <FooterDelphus />
        </div>
      </div >
    </div>
  );
}

export default Pedidos;
