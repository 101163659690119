import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import CardActionArea from '@material-ui/core/CardActionArea';

import FrLoading from '../FrLoading'
import api from '../../services/api'
import FrTableReg from '../FrTableReg'
import './index.css';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 15,
    marginLeft: 0,
    minWidth: 345,
    '&:first-child': {
      marginLeft: 15,
    },
  }
})

function TableDetail(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const classes = useStyles();

  const columns = props.columnDetails || []

  let history = useHistory();

  const redirect = (e, codigo) => {
    e.preventDefault();
    // history.push('/sistema/lotes/registro?codigo=' + codigo)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (props.isOpen) {
        setLoading(true)
        api.get(`${props.detailName}/?${props.codeName.toLowerCase()}=${props.detail[props.codeName]}`).then(r => {
          console.log(r.data)
          setData(r.data)
          setLoading(false)
        })
      }
    }

    fetchData()
  }, [props.isOpen])

  if (loading) {
    return (
      <>
       
      </>
    )
  } else if (data.length > 0 ) {
    return (
      <>
         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
              {columns.length == 0 ? 
              
                <Box margin={3} >
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                    Código: {data[0].CODIGO_REPRESENTADA}
                    
                  </div> 
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                    Descrição: {data[0].NOME_REPRESENTADA}
                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                    NCM: {data[0].CLASSIFICACAO_FISCAL}

                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                    
                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                    EAN: {data[0].CODIGO_BARRAS}

                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                    IPI: {(data[0].IPI)+'%'}

                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45, marginTop: 20}}>
                    Substituído por: 

                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                    Código: {data[0].CODIGO_REPRESENTADA_NOVO}

                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                  Descrição: {data[0].NOME_REPRESENTADA_NOVO}

                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                  NCM: {data[0].CLASSIFICACAO_FISCAL}

                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                  EAN: {data[0].CODIGO_BARRAS}

                  </div>
                  <div style={{width: 600, display: 'flex', justifyContent: 'flex-start', marginLeft: 45}}>
                    IPI: {(data[0].IPI)+'%'}

                  </div>
                </Box>
                :
                <FrTableReg readonly={true} detail regName="" columns={columns} searched={false} codeName="display" page={''} rowsPerPage={10} data={data} />
              }
             
          </Collapse>
        </TableCell>
            
        
      </>
    )
  } else {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3}>
              Não há registros para detalhar
                  </Box>
          </Collapse>
        </TableCell>
      </>
    )
  }

}

export default TableDetail;