import React, {createContext, useEffect, useState} from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { routes, fallbackRoute } from './routes'

import './App.css';
import Login from './pages/Login';
import api from './services/api';

export const ThemeContext = createContext({})
export const CombosContext = createContext({})

function App() {
	const [auth, setAuth]  = useState(JSON.parse(localStorage['authMacroApp'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
	const [temaEscuro, setTemaEscuro] = useState(auth.temaEscuro || false)
	const [comboPro, setComboPro] = useState([{ value: '', display: '' }])
	const [comboCli, setComboCli] = useState([{ value: '', display: '' }])
	const [clientes, setClientes] = useState([{}])

	async function getCombo(tabela, parameter){
		return (await api.getComboData(tabela, parameter))
	}


	function setColor() {
		if(!temaEscuro){
			document.documentElement.style.setProperty('--cor-tema', "#F7EFEF");
			document.documentElement.style.setProperty('--cor-tabela', "#FFFFFF");
			document.documentElement.style.setProperty('--cor-tabela-hover', "rgba(0, 0, 0, 0.04)");
			document.documentElement.style.setProperty('--cor-tabela-selected', "rgba(133, 130, 130, 0.5)");
			document.documentElement.style.setProperty('--cor-tabela-selected-hover', "rgba(133, 130, 130, 0.8)");
			document.documentElement.style.setProperty('--cor-fonte', "white");
			document.documentElement.style.setProperty('--cor-fonte-hover', "black");
			document.documentElement.style.setProperty('--cor-switch', 'rgb(211 195 102)');
			document.documentElement.style.setProperty('--cor-label-focused', '#389492');
			document.documentElement.style.setProperty('--cor-label', "#389492");
			document.documentElement.style.setProperty('--cor-placeholder','rgba(0, 0, 0, 0.65)');
		} else {
			document.documentElement.style.setProperty('--cor-tema', "#222626");
			document.documentElement.style.setProperty('--cor-tabela', "#424242");
			document.documentElement.style.setProperty('--cor-tabela-hover', "#686868");
			document.documentElement.style.setProperty('--cor-tabela-selected', "#222222");
			document.documentElement.style.setProperty('--cor-tabela-selected-hover', "#2c2c2c");
			document.documentElement.style.setProperty('--cor-fonte', "black");
			document.documentElement.style.setProperty('--cor-fonte-hover', "white");
			document.documentElement.style.setProperty('--cor-switch', 'rgb(14 0 80)');
			document.documentElement.style.setProperty('--cor-label-focused', 'white');
			document.documentElement.style.setProperty('--cor-label', "#F7EFEF");
			document.documentElement.style.setProperty('--cor-placeholder','rgba(255, 255, 255, 0.65)');
		}


	}
	async function getDataClientes(count){
		return new Promise(async (resolve, reject ) => {
			api.post('/clientes/first?count='+count).then(r=> {
				if (r.data.length >0){
					console.log(r.data.length)
					resolve(r.data)
				} else {
					resolve()
				}
			})
		})
	}

	async function getTotalClientes(){
		return new Promise(async (resolve, reject) => {
			let promiseClientes = []
			api.post('/clientes/count').then(async res=>{
				let total = res.data[0].TOTAL
				let i = 0
				while (i < total){
					let cliSeg = getDataClientes(i)
					promiseClientes.push(cliSeg)
					i = i+1000
					if (i> total){
						resolve(promiseClientes)
					}
				}
			})
		})
	}

	async function getClientes(){
		let cli = {}
		// let promiseClientes = await getTotalClientes()
		
		// let clientes = await Promise.all(promiseClientes)
		// let arrCli = clientes.flat()
		
		// console.log(obj)
		api.get('/clientes/object').then(r=> {
			console.log(r.data)
			setClientes(r.data)
		})
			
	
		  // setClientes(r)
	
	}
	
	useEffect(() => {
		if (auth.authenticated){
			getCombo('produtos').then(r=>{
				setComboPro(r)
			})
			getCombo('clientes', auth.user).then(r=>{
				setComboCli(r)
			})
			getClientes()

		}
	}, [auth])
	
	
	useEffect(()=>{
	}, [temaEscuro])

	const routeComponents = routes.map((route, key) => {
		let Component = route.component
		setColor()
		if (route.protected) {
			return <ProtectedRoute path={'/macropecas_app'+route.path} exact={route.exact} component={route.component} temaEscuro={temaEscuro} setTemaEscuro={setTemaEscuro} key={key}/>
				
		} else {
			return <Route path={'/macropecas_app'+route.path} exact={true} component={route.component} temaEscuro={temaEscuro} setTemaEscuro={setTemaEscuro} key={key}/>
		}
	});

	return (
		<div className="App">
			<CombosContext.Provider value={{comboPro, setComboPro, comboCli, setComboCli, clientes}}>
				<ThemeContext.Provider value={{temaEscuro, setTemaEscuro}}>
					<Router>
						<Switch>
							{routeComponents}
							<ProtectedRoute component={fallbackRoute} temaEscuro={temaEscuro} setTemaEscuro={setTemaEscuro}/>
						</Switch>
					</Router>
				</ThemeContext.Provider>

			</CombosContext.Provider>
		</div>
	);
}

export default App;
