import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../services/api'

import './styles/Login.css';

import logo from '../imgs/logo.JPG';
import delphus from '../imgs/delphus.png';
import { CombosContext } from '../App';
import { Link, useHistory  } from 'react-router-dom';

const swal = withReactContent(Swal)

function Login(props) {
    const history = useHistory()
    const {setComboPro, setComboCli} = useContext(CombosContext)
    const [auth, setAuth] = useState(JSON.parse(localStorage['authMacroApp'] || JSON.stringify({ authenticated: false, user: 0})));


    useEffect(()=>{
        {

            // api.get('usuarios/hash?'+auth.hash).then( r =>{         
            //     if (r.data[0] == auth.hash) {
            //         window.location.href = '/macropecas_app/home'
            //     }             
            // }) 
            if (auth.authenticated){
                history.push('/macropecas_app/home'  )
            }           
        }

        // window.alert(JSON.stringify(auth))
    }, [auth])

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('login') === '') {
            swal.fire('Usuário não informado!', 'Informe o usuário.', 'warning')
            return
        }

        if (data.get('senha') === '') {
            swal.fire('Senha não informada!', 'Informe a senha.', 'warning')
            return
        }
        //Converte FormData em JSON
        var object = {};

        data.forEach(function(value, key){
            object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/login', object)
        .then(function (response) {
            console.log(response.data[0])
            let { PK_USU, NOME, ultimo_login, grupo, ativo, FK_VEN, FK_GPU } = response.data[0];
            // if(ativo == 'S'){
                if (PK_USU && PK_USU > 0) {
                    localStorage['authMacroApp'] =  JSON.stringify({ authenticated: true, user: PK_USU, name: NOME, ultimo_login, last_activity: new Date(), fk_gpu: FK_GPU, grupo, temaEscuro: false, fk_ven: FK_VEN })
                    
                    
                    window.location.href = '/macropecas_app/home' 
                } else {
                    swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
                }
            // } else {
            //     console.log(response.data)
            //     swal.fire(
            //         "Login inválido!",
            //         "Usuário inativo! Acesso não permitido!",
            //         "warning"
            //       );
            // }            
        })
        .catch(function (error) {
            swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
        });
    }

    return (
        <div className="Login">
            <div className="form">
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <img alt="logo" src={logo} title="ejr" className="logo"/>
                    <div className="form-label-group">
                        <input type="text" id="login" name="login" className="loginInput" placeholder="Usuário"/>
                        <label htmlFor="login">Login</label>
                    </div>
                    <div className="form-label-group">
                        <input type="password" id="senha" name="senha" className="loginInput" placeholder="Senha"/>
                        <label htmlFor="senha">Senha</label>
                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>Entrar</p></span>
                    </button>
                    <div className="forgotPwDiv">
                        <Link to='/macropecas_app/forgotPw'>Esqueci minha senha</Link>
                    </div>

                </form>
            </div>
            <div className="delphus">
                <img alt="delphus" src={delphus} title="delphus" className="logo_delphus" />
            </div>
        </div>
    );
}

export default Login;
