import axios from 'axios';


// const backendUrl = 'http://localhost:21589'
// var backendUrl = 'https://apps.delphus.inf.br:29501' // homologação
// const backendUrl = 'http://192.168.0.194:21590' // homologação
var backendUrl = 'https://apps.delphus.inf.br:30501' // produção
if(window.location.origin == 'http://localhost:3000') {
    backendUrl = 'http://localhost:29500'
} else if(window.location.origin == 'https://apps.delphus.inf.br' ){
    backendUrl = 'https://apps.delphus.inf.br:29501'
} else if (window.location.origin == 'http://145.223.29.193') {
    backendUrl = 'http://145.223.29.193:30500' //nova vps teste
}


console.log(backendUrl)
console.log(window.location.origin)

const api = axios.create({
    baseURL: backendUrl + `/api/`
});

api.getComboData = async (table, parameter, req) => {
    return new Promise(async (resolve, reject) => {
        console.log('/' + table + '/combo' + (parameter ? '/' + parameter : '') + (req ? '?' + req : ''))
        await api.get('/' + table + '/combo' + (parameter ? '/' + parameter : '') + (req ? '?' + req : '')).then(async r => {
            resolve(r.data)
        })
    })
}

api.getComboDataWhere = async (table, where) => {
    return new Promise(async (resolve, reject) => {
        console.log('/' + table + '/combo' + where)
        await api.get('/' + table + '/combo' + where).then(async r => {
            resolve(r.data)
        })
    })
}

api.getComboDataAsync = async (table, setCombo, parameter) => {
    return new Promise(async (resolve, reject) => {
        console.log('Searching for parameter ' + parameter)
        await api.get('/' + table + '/combo' + (parameter ? '/' + parameter : '')).then(async r => {
            console.log(r.data)
            resolve(setCombo(r.data))
        })
    })
}

api.getAcessos = async (tela, opcao) => {
    return new Promise(async (resolve, reject) => {
        // console.log('Searching for parameter '+tela+' '+opcao)
        let auth = JSON.parse(localStorage['authMacroApp']);

        await api.get('/grupo_usuarios/permissoes?tela=' + tela.toUpperCase() + '&pk_usu=' + auth.user + '&acao=' + opcao.toUpperCase()).then(async r => {
            console.log(r.data)
            console.log(r.data[0])
            
            // resolve(r.data[0][opcao] === '1' ? 'S' : 'N')
            if (opcao === 'Consulta') {
                try {
                    resolve(r.data[0].CONSULTA)
                }
                catch { resolve('N') }
            }

            if (opcao === 'Inclusao') {
                try {
                    resolve(r.data[0].INCLUSAO)
                }
                catch { resolve('N') }
            }

            if (opcao === 'Alteracao') {
                try {
                    resolve(r.data[0].ALTERACAO)
                }
                catch { resolve('N') }
            }

            if (opcao === 'Exclusao') {
                try {
                    resolve(r.data[0].EXCLUSAO)
                }
                catch { resolve('N') }
            }

            resolve(r.data)
        })
    })
}


api.getListData = async (table, parameter) => {
    return new Promise(async (resolve, reject) => {
        await api.get('/' + table + '/lista' + (parameter ? '/' + parameter : '')).then(async r => {
            resolve(r.data)
        })
    })
}

api.getImage = async (regName, pk, image) => {
    return new Promise(async (resolve, reject) => {
        if (pk && image) {
            axios.get(backendUrl + '/static/upload/fotos/' + regName + '/' + pk + '/' + image).then(r => {
                resolve(backendUrl + '/static/upload/fotos/' + regName + '/' + pk + '/' + image)
            }).catch(e => resolve(backendUrl + '/static/upload/no-image.jpg'))
        } else resolve(backendUrl + '/static/upload/no-image.jpg')
    })
}

api.getTipoDocumento = async (fk_mdl) => {
    return new Promise(async (resolve, reject) => {
        resolve(await api.getComboData('tipo_documento', fk_mdl))
    })
}

api.getFoto = async (foto, pk) => {
    return new Promise(async (resolve, reject) => {
        
        await api.get('/produtos/dadosFoto?foto='+foto+'&pk_pro=' + pk).then(res=> {
            console.log('/produtos/dadosFoto?foto='+foto+'&pk_pro=' + pk)
            if(res.data[0]){
                console.log(res.data[0])
              api.get('/produtos/foto?pk_ipr=' + res.data[0].PK_IPR+'&nome='+res.data[0].NOME_ARQUIVO, {responseType: 'blob', contentType: 'image/jpeg"'}).then(r=>{
                
                resolve({arquivo: res.data[0].NOME_ARQUIVO, url: URL.createObjectURL(r.data), file:r.data, link: res.data[0].LINK, key: res.data[0].PK_IPR})
               
              })
            } else {
                resolve(null)
            }
          })
    })
}


api.noImageUrl = backendUrl + '/static/upload/no-image.jpg'
api.backendUrl = backendUrl

export default api