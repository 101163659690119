import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import { Icon } from 'react-icons-kit'
import {plusSquareO} from 'react-icons-kit/fa/plusSquareO'
import {colors} from '../utils/vars'
import Dialog from '@material-ui/core/Dialog';
import Tabs from '../components/Tabs';
import Barcode from 'react-barcode';
import AutoComp from '../components/AutoComp'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Hidden } from '@material-ui/core';
import Fotos from '../components/Fotos';
import { useHistory } from 'react-router-dom';

const swal = withReactContent(Swal)

function FotosProdutos(props) {
  let history = useHistory()
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [img, setImg] = useState(0)
  const [imagens, setImagens] = useState([])
  const [showModalImg, setShowModalImg] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Produtos', 'ALTERACAO'))
      } else {
        setEnableEdit(await api.getAcessos('Produtos', 'INCLUSAO'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/produtos?pk_pro=' + query.codigo).then(async (r) => {
          let auxValues={...r.data[0]}
          if (r.data[0]) {
            let dadosImagens = []
            
            let imagem = {}
            imagem = await api.getFoto(1, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
            }
            imagem = await api.getFoto(2, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
            }
            imagem = await api.getFoto(3, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
            }
            imagem = await api.getFoto(4, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
            }
            imagem = await api.getFoto(5, query.codigo)
            if (imagem){
              dadosImagens.push(imagem)
            }
            console.log(dadosImagens)
            setImagens(dadosImagens)
          
            console.log(r.data[0])
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  history.push('/macropecas_app/produtos')
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'CODIGO_MACROPECAS',
      'NOME_MACROPECAS',
      'CODIGO_REPRESENTADA',
      'NOME_REPRESENTADA',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function abreModalNovaImg(imagem) {
    setShowModalImg(true)
    console.log(imagem)
    setImg(imagem)
  }


  function escondeModalImg() {
    setShowModalImg(false)
    setImg({arquivo: '', link: ''})
  }

  function confirmaModalImg() {
    console.log(img)
  }

  useEffect(() => {
    console.log(data)
  },[data])

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
        console.log(auxValues)
       
		    const formData = new FormData();
        
        formData.append('PK_PRO', auxValues.PK_PRO|| '')
        console.log(imagens)
        if (imagens[0]){
          formData.append('foto1', imagens[0].file, imagens[0].arquivo)
          formData.append('link1', imagens[0].link)
        }
        if (imagens[1]){
          formData.append('foto2', imagens[1].file, imagens[1].arquivo)
          formData.append('link2', imagens[1].link)

        }
        if (imagens[2]){
          formData.append('foto3', imagens[2].file, imagens[2].arquivo)
          formData.append('link3', imagens[2].link)

        }
        if (imagens[3]){
          formData.append('foto4', imagens[3].file, imagens[3].arquivo)
          formData.append('link4', imagens[3].link)

        }
        if (imagens[4]){
          formData.append('foto5', imagens[4].file, imagens[4].arquivo)
          formData.append('link5', imagens[4].link)

        }


        if (errors.length === 0) {
          if (updateType) {
            api.post('/produtos/fotos', formData).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    history.push('/macropecas_app/produtos')
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/fotosprodutos/add', formData).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      history.push('/macropecas_app/produtos')
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleSelectedFile(e){
    let file = e.target.files[0];
    let i = {...img}
    console.log(e.target.files)
    if (file) {
        i.arquivo = file.name
        i.url = URL.createObjectURL(file)
        i.file = file
        setImg(i)
      }
    
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        history.push('/macropecas_app/produtos')
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const handleChangeImg = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setImg(auxValues);
  };

  

  function adicionaImagem(){
    let imgs = [...imagens]
    console.log(img)
    let i = {arquivo: img.arquivo, file: img.file, url: img.url, link: img.link, key: imgs[imgs.length-1]?.key+1 || 1}
    console.log(i)
    imgs.push(i)
    setImagens(imgs)
    setImg({arquivo: '', link: '', file: '', url: ''})
    setShowModalImg(false)
  }

  function excluirImagem(key){
    console.log(key)
    let imgs = imagens.filter(item => {return item.key !== key})
    setImagens(imgs)
  }


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Fotos dos Produtos</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit} className='regForm' >
          <div style={{width: '100%', display: 'flex', flex: 1, gap: 20,}}>
              <Fotos onOpen={abreModalNovaImg} imagens={imagens} onDelete={excluirImagem} size={300} naoAdiciona/>

          </div>
        
              
      
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
      <Dialog aria-labelledby="simple-dialog-title" open={showModalImg} fullWidth={true} maxWidth={'sm'} >
          <div style={{ padding: '20px', paddingTop: '20px', backgroundColor:'var(--cor-tema)'  }}>

            <div className="defaultHeader">
              <h3>Edição de Foto</h3>
            </div>
            <br />


            <div style={{ display: 'flex', flex: 1, flexDirection: 'column',  backgroundColor:'var(--cor-tema)' }}>

              
              <FrTextInput
                maxLength='200'
                value={img.link || ''}
                onChange={handleChangeImg}
                name="link"
                id="link"
                color="#389492"
                label={'Link'}
                variant="outlined"
                size="small"
                style={{ width: '100%' }}
              />  
              {/* <FrTextInput
                value={img.arquivo || ''}
                onChange={handleChangeImg}
                name="img"
                id="img"
                color="#389492"
                label={'Arquivo'}
                variant="outlined"
                size="small"
                style={{width: '100%' }}
              />   */}
              <input
                type="file"
                name="arquivo"
                id={"html_btn_foto"}
                accept=".png, .jpg, .jpeg"
                onChange={handleSelectedFile}

              />

              

            </div>
          </div>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv" >
              <button onClick={adicionaImagem}> Confirmar </button>
              <button onClick={escondeModalImg}> Sair </button>
            </div>
          </div>
        </Dialog>
    </div>
  );
}

export default FotosProdutos;
