import React, { useState, useEffect } from 'react';

import './styles/Login.css';
import api from '../services/api'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import logo from '../imgs/logo.JPG';
import delphus from '../imgs/delphus.png';

const swal = withReactContent(Swal)


function ForgotPw() {
 

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('login') === '') {
            swal.fire('Informe seu email de login para receber o link de mudança de senha!', 'Informe o email.', 'warning')
            return
        }

        //Converte FormData em JSON
        var object = {};

        data.forEach(function (value, key) {
            object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/reset_password', {...object, host: window.location.href})
            .then(function (response) {
                console.log(response.data[0])
                let { pk_usu } = response.data[0];
                if (pk_usu > 0) {
                    // swal.fire('O link para mudança de senha foi enviado para o seu email!', '', 'success')
                    swal.fire('O link para mudança de senha foi enviado para o seu email!', '','success')
                    .then((result) => {
                      if (result.value) {
                        window.location.href = '/macropecas_app/login' 
                    }
                    })                    
                } else {
                    swal.fire('Usuário não localizado!', '', 'error')
                }
            })
            .catch(function (error) {
                swal.fire('Usuário não localizado!', '', 'error')
            });
    }

    console.log(logo)

    return (
        <div className="Login">
            <div className="form">
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <img alt="fr_logo" src={logo} title="ejr" className="logo"/>
                    <div className="form-label-group">
                        <input type="text" id="login" name="login" className="loginInput" placeholder="Usuário"/>
                        <label htmlFor="login">Informe seu e-mail</label>
                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>OK</p></span>
                    </button>
                </form>
            </div>
            <div className="delphus">
                <img alt="delphus" src={delphus} title="delphus" className="logo_delphus" />
            </div>
        </div>
    );

}

export default ForgotPw;
