import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import {upload2} from 'react-icons-kit/icomoon/upload2'
import AutoComp from '../components/AutoComp'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Produtos(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [lin, setLin] = useState(0)
  const [rep, setRep] = useState(0)
  const [gru, setGru] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [arquivo, setArquivo] = useState()

  const [comboLinha, setComboLinha] = useState([{ VALUE: '', DISPLAY: '' }])
  const [comboRep, setComboRep] = useState([{ VALUE: '', DISPLAY: '' }])
  const [comboGrupo, setComboGrupo] = useState([{ VALUE: '', DISPLAY: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ VALUE: 'S', DISPLAY: 'Sim' }, { VALUE: 'N', DISPLAY: 'Não' }])

  const columns = [
    { id: 'CODIGO_MACROPECAS', numeric: false, label: 'Código', align: 'left', minWidth: 200 },
    { id: 'NOME_MACROPECAS', numeric: false, label: 'Descrição', align: 'left', minWidth: 150 },
    { id: 'NOMELINHA', numeric: false, label: 'Linha', align: 'left', minWidth: 200 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setenableAdd(await api.getAcessos('Produtos', 'Inclusao'))
      setenableEdit(await api.getAcessos('Produtos', 'Alteracao'))
      setenableDel(await api.getAcessos('Produtos', 'Exclusao'))
      setComboLinha(await api.getComboData('linhas_produtos'))
      setComboGrupo(await api.getComboData('grupos_produtos'))
      setComboRep(await api.getComboData('representadas'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_lin"] = lin;
      setFilter(state => auxValues)
      auxValues["nomelin"] = ''
      if (lin > 0) {
        await api.get("/linhas_Produtos?pk_lin=" + lin).then((r) => {
          console.log(lin)
          if (r.data[0])
            auxValues["nomelin"] = r.data[0].NOME;
        });
      }
      setFilter(state => {console.log(state)
        return auxValues})
    };
    fetchMdc();
  }, [lin]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_rep"] = rep;
      setFilter(state => auxValues)
      auxValues["nomerep"] = ''
      if (rep > 0) {
        await api.get("/representadas?pk_rep=" + rep).then((r) => {
          console.log(rep)
          if (r.data[0])
            auxValues["nomerep"] = r.data[0].RAZAO_SOCIAL;
        });
      }
      setFilter(state => {console.log(state)
        return auxValues})
    };
    fetchMdc();
  }, [rep]);

  useEffect(() => {
    const fetchMdc = async () => {
      const auxValues = { ...filter };
      auxValues["fk_gru"] = gru;
      setFilter(state => auxValues)
      auxValues["nomegru"] = ''
      if (gru > 0) {
        await api.get("/grupos_produtos?pk_gru=" + gru).then((r) => {
          console.log(gru)
          if (r.data[0])
            auxValues["nomegru"] = r.data[0].RAZAO_SOCIAL;
        });
      }
      setFilter(state => {console.log(state)
        return auxValues})
    };
    fetchMdc();
  }, [gru]);

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    if (!isEmpty(queryObject)) {
    // if (true) {
      queryObject.buscar = true
      setFiltered(true)
      !props.modal && window.history.replaceState({ filtered: true }, 'filter', "/macropecas_app/produtos?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/produtos', { params: queryObject }).then(r => {
        
        setData(r.data)
        console.log(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    !props.modal && window.history.replaceState({ filtered: false }, 'filter', '/macropecas_app/produtos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  function mostraModal(){
    setShowModal(true)
  }

  function escondeModal(){
    setShowModal(false)
  }

  function confirmaModal(){
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })

    setShowModal(false)
    let formData = new FormData();
      formData.append('file', arquivo, arquivo.name)
      api.post('/produtos/importacao/' , formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(r => {
        console.log('foi')
        // setArquivo(file.name)
        if(r.data == 'Importação Concluida!'){
          swal.fire("Sucesso!", "Arquivo importado com sucesso!", 'success')
        } else {
          swal.fire("Erro!", "Falha na importação do arquivo", 'error')
        }
      }).catch(r => {
        swal.fire("Erro!", "Falha na importação do arquivo", 'error')
      })
    console.log(arquivo)
  }
  
  


  return (
    <div className="Manut">
      {!props.modal && <NavBar />}

      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Produtos</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
       
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData} className='searchForm'>
          <div>
              <FrTextInput
                value={filter.codigo_macropecas || ''}
                style={{ width: 350 }}
                name="codigo_macropecas"
                id="codigo_macropecas"
                color="#389492"
                label="Código Macropeças"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
                 <FrTextInput
                value={filter.nome_macropecas || ''}
                style={{ width: 600 }}
                name="nome_macropecas"
                id="nome_macropecas"
                color="#389492"
                label="Nome Macropeças"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
            </div>
            <div>
              <FrTextInput
                value={filter.codigo_representada || ''}
                style={{ width: 350 }}
                name="codigo_representada"
                id="codigo_representada"
                color="#389492"
                label="Código Representada"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
                 <FrTextInput
                value={filter.nome_representada || ''}
                style={{ width: 600 }}
                name="nome_representada"
                id="nome_representada"
                color="#389492"
                label="Nome Representada"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
            </div>
            <div>

            <AutoComp
                value={filter.nomerep || ""}
                valor={filter.fk_rep || ''}
                width={350}
                name="fk_rep"
                id="fk_rep"
                label='Representada'
                data={comboRep}
                setValue={setRep}
              />
           
              <AutoComp
                value={filter.nomelin || ""}
                valor={filter.fk_lin || ''}
                width={250}
                name="fk_lin"
                id="fk_lin"
                label='Linha do Produto'
                data={comboLinha}
                setValue={setLin}
              />

              <AutoComp
                value={filter.nomegru || ""}
                valor={filter.fk_gru || ''}
                width={250}
                name="fk_gru"
                id="fk_gru"
                label='grupo de Produto'
                data={comboGrupo}
                setValue={setGru}
              />

              <FrSelect
                value={filter.ativo || ''}
                style={{ width: 150 }}
                name="ativo"
                id="ativo"
                label='Ativo'
                data={comboAtivo}
                onChange={handleChange} 
              />
              

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm} type="button" >
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/macropecas_app/produtos/registro">
                  <button type="button"  >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
                {/* <button type="button" onClick={mostraModal}>
                    <Icon icon={upload2} size={18} />
                    Upload Planilha
                </button> */}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
        {props.modal ?
            <FrTableReg 
              escondeModal={props.escondeModal}
              visualizar={props.modal ? 'N' : 'S'}
              editar={false}
              f2={true}
              pro={props.pro}
              setPro={props.setPro}
              regName="produtos"
              columns={columns}
              searched={filtered}
              codeName="PK_PRO"
              page={'Produtos'}
              data={data}
              readOnly
              enableEdit={false}
              enableDel={false}
            />
            :
          <FrTable 
            regName="produtos" 
            columns={columns} 
            uploadFotos 
            width={150}
            searched={filtered} 
            codeName="PK_PRO" 
            page={'Produtos'} 
            data={data} 
            enableEdit={enableEdit} 
            enableDel={enableDel} 
          />
        }
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Produtos;
