import {useState} from 'react'
import { Icon } from 'react-icons-kit'
import {plusSquareO} from 'react-icons-kit/fa/plusSquareO'
import { BtnDelete, DivAdicionaFoto, DivMostraFoto, Foto, DivFotos } from './styles'
import {trash} from 'react-icons-kit/fa/trash'


function Fotos(props) {



    return props.imagens.length === 0 ? 
        <DivAdicionaFoto  size={props.size}
            onClick={() => props.onOpen({arquivo: '', link: '', key: ''})} 
            // onClick={(adicionaImagem)}
            >
            <Icon icon={plusSquareO} size={60} style={{ color: '#389492' }} />
            <p style={{color: '#389492'}}>Nova Imagem</p>
        </DivAdicionaFoto>

        :
        <DivFotos id='i'>
            {props.imagens.map((item, index) => {
            console.log(props.imagens[1])

            return (
                <DivMostraFoto size={props.sizeFoto}  key={item.key}>
                    <a href={item.link} target='_blank'>
                {/* <p>imagem</p> */}
                        <Foto src={item.url} style={{}} size={props.sizeFoto}/>
                    </a>  
                    <BtnDelete onClick={() => {props.onDelete(item.key)}}>
                        <Icon icon={trash} size={20}  />
                    </BtnDelete>
                    
                </DivMostraFoto>)
            })
            }
            {/* <Foto src={'https://m.psecn.photoshelter.com/img-get2/I0000zF99yDAAWbQ/fit=/fill=250x250/I0000zF99yDAAWbQ.jpg'} size={props.sizeFoto}/> */}
            {
            props.imagens.length < 5 && !props.naoAdiciona &&
            <DivAdicionaFoto size={props.size}
            // onClick={() => abreModalNovaImg({arquivo: '', link: ''})} 
                onClick={() => props.onOpen({arquivo: '', link: '', key: ''})}
                >
                <Icon icon={plusSquareO} size={60} style={{ color: '#389492' }} />
                <p style={{color: '#389492'}}>Nova Imagem</p>
            </DivAdicionaFoto>
            }

        </DivFotos>
      
}


export default Fotos