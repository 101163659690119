import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'

import api from '../services/api'
import { paramsToObject, objectToQueryString, onlyNumbers, populateForm, formToObject, isEmpty } from '../utils/functions'
import { mCNPJ, mCPF } from '../utils/masks'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Catalogos() {
  let history = useHistory()
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboRep, setComboRep] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' },])
  const [comboRepresentante, setComboRepresentante] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'RAZAO_SOCIAL', numeric: false, label: 'Representada', align: 'left', minWidth: 300, },
    { id: 'FORMATDATA', numeric: false, label: 'Data', align: 'left', minWidth: 200 },
    { id: 'ATIVO', numeric: false, label: 'Ativo', align: 'left', minWidth: 150 },
  ]


  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Catalogos'
      let consulta = 'S'
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              history.push('/macropecas_app/home')
            }
          })
      } else {


        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))

        setComboRep(await api.getComboData('representadas'))
        // setenableAdd('S')
        // setenableDel('S')
        // setenableEdit('S')

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  
  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // var queryObject = { ...filter }

    if  (1 == 1) {//(!isEmpty(queryObject)) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/macropecas_app/catalogos?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


      api.get('/catalogos', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/macropecas_app/catalogos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case 'cnpj_cpf':
        auxValues[e.target.name] = onlyNumbers(e.target.value).length <= 11 ? mCPF(e.target.value) : mCNPJ(e.target.value)
        break
      // case 'telefone':
      //   auxValues[e.target.name] = mTel(e.target.value)
      //   break

      default: auxValues[e.target.name] = e.target.value;
    }

    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Catalogos</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div className='filters'>
              <div>
                <FrSelect
                  value={filter.fk_rep || ''}
                  style={{ width: 400 }}
                  name="fk_rep"
                  id="fk_rep"
                  label='Representada'
                  data={comboRep}
                  onChange={handleChange} 
                />

                <FrDatePicker
                  value={(filter.data || '')}
                  name="data"
                  id="data"
                  color="#528b46"
                  label="Data"
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  style={{ width: 190 }}
                />

                <FrSelect
                  value={filter.ativo || ''}
                  style={{ width: 120 }}
                  name="ativo"
                  id="ativo"
                  label='Situação'
                  data={comboAtivo}
                  onChange={handleChange} 
                />


              </div>
              
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/macropecas_app/catalogos/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable 
            width={160} 
            replicar={true} 
            regName="catalogos" 
            columns={columns} 
            searched={filtered} 
            codeName="PK_CAT" 
            page={'Catalogos'} 
            data={data} 
            link={'S'}
            enableEdit={enableEdit} 
            enableDel={enableDel} 
            rowsPerPage={25} 
            downloadPdf={'S'}
            rderBy={'razao_social'} 
          />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Catalogos;