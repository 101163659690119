import Login from '../pages/Login';
import Usuarios from '../pages/Usuarios';
import Grupo_Usuarios from '../pages/Grupo_Usuarios';
import RegGrupo_Usuarios from '../pages/RegGrupo_Usuarios';
import Home from '../pages/Home';
import ChangePw from '../pages/ChangePw';
import ForgotPw from '../pages/ForgotPw';
import Produtos from '../pages/Produtos';
import RegProdutos from '../pages/RegProdutos';
import FotosProdutos from '../pages/FotosProdutos';
import Clientes from '../pages/Clientes';
import RegClientes from '../pages/RegClientes';
import NotasFiscais from '../pages/NotasFiscais';
import Produtos_Inativos from '../pages/Produtos_Inativos';
import Catalogos from '../pages/Catalogos';
import RegCatalogos from '../pages/RegCatalogos';
import Pedidos from '../pages/Pedidos';
import RegPedidos from '../pages/RegPedidos';

// types{
//     'cadastro':      String
//     'operacional':   String
//     'relatorios':    String 
//     '':              String
// }

export const routes = [
    {
        path: '/forgotPw',
        component: ForgotPw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/usuarios',
        component: Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'USUARIOS',
        caption: 'Usuários'
    },
    {
        path: '/grupo_usuarios',
        component: Grupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'GRUPO USUARIOS',
        caption: 'Grupos Usuários'
    },
    {
        path: '/grupo_usuarios/registro',
        component: RegGrupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/changePw',
        component: ChangePw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/login',
        component: Login,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/home',
        component: Home,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/produtos',
        component: Produtos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'PRODUTOS',
        caption: 'Produtos',
        type: 'cadastro',
    },
    {
        path: '/produtos/registro',
        component: RegProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/fotos',
        component: FotosProdutos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/clientes',
        component: Clientes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'CLIENTES',
        caption: 'Clientes',
        type: 'cadastro',
    },
    {
        path: '/clientes/registro',
        component: RegClientes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/notas_fiscais',
        component: NotasFiscais,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'NOTAS FISCAIS',
        caption: 'Notas Fiscais',
        type: 'operacional',
    },
    {
        path: '/produtos_inativos',
        component: Produtos_Inativos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'PRODUTOS INATIVOS',
        caption: 'Produtos Inativos',
        type: 'cadastro',
    },
    {
        path: '/catalogos',
        component: Catalogos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'CATALOGOS',
        caption: 'Catalogos',
        type: 'operacional',
    },
    {
        path: '/catalogos/registro',
        component: RegCatalogos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/pedidos',
        component: Pedidos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'PEDIDOS',
        caption: 'Pedidos/Orçamentos',
        type: 'operacional',
    },
    {
        path: '/pedidos/registro',
        component: RegPedidos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
];

export const fallbackRoute = Home

