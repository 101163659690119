import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { routes } from '../routes'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrTablePermissions from '../components/FrTablePermissions';
import $ from 'jquery'
import { useHistory } from 'react-router-dom';

const swal = withReactContent(Swal)

function Catalogos(props) {
  let history = useHistory()
  const [data, setData] = useState({})
  const [dataPermissions, setDataPermissions] = useState(routes.filter((item) => (item.register === false) && item.name))
  const [updateType, setUpdateType] = useState(false)
  const [comboRep, setComboRep] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Ativo' }, { value: 'N', display: 'Inativo' },])
  const [selected, setSelected] = useState([])
  const [enableEdit, setEnableEdit] = useState('N')
  const [arquivo, setArquivo] = useState('N')
  const [nomeArquivo, setNomeArquivo] = useState('Nenhum arquivo escolhido')

  const columns = [
    { id: 'name', numeric: false, label: 'Tela', align: 'left', minWidth: 100 },
    { id: 'VISUALIZAR', numeric: false, label: 'Visualizar', align: 'left', minWidth: 130 },
    { id: 'INCLUIR', numeric: false, label: 'Incluir', align: 'left', minWidth: 130 },
    { id: 'EDITAR', numeric: false, label: 'Editar', align: 'left', minWidth: 100 },
    { id: 'EXCLUIR', numeric: false, label: 'Excluir', align: 'left', minWidth: 130 }
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('USUARIOS', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('USUARIOS', 'Inclusao'))
      }

      setComboRep(await api.getComboData('representadas'))

        if (query.codigo > 0) {
          setUpdateType(true)

          api.get('/catalogos?pk_cat=' + query.codigo).then(r => {
            if (r.data[0]) {
              console.log(api.backendUrl+'/static/upload/'+r.data[0].NOME_ARQUIVO)
              console.log(r.data[0])
              setData(r.data[0])
              if (r.data[0].NOME_ARQUIVO){
                let i = {}
                let file = api.backendUrl+'/static/upload/'+r.data[0].NOME_ARQUIVO
                i.arquivo = r.data[0].NOME_ARQUIVO
                // i.url = URL.createObjectURL(file)
                // i.file = file
                setArquivo(i)
              }

              swal.close()
            } else {
              swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
                .then((result) => {
                  if (result.value) {
                    history.push('/macropecas_app/catalogos')
                  }
                })
            }
          })
        } else swal.close()
      }

      fetchData()
    }, [])

  function consistData(data) {
    let required = [
      'DESCRICAO'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log(arquivo)
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        auxValues.pk_pgr = selected;
        const formData = new FormData();
        formData.append('PK_CAT', auxValues.PK_CAT|| '')
        formData.append('DATA_CADASTRO', auxValues.DATA_CADASTRO|| '')
        formData.append('ATIVO', auxValues.ATIVO|| '')
        formData.append('FK_REP', auxValues.FK_REP|| '')
        formData.append('DESCRICAO', auxValues.DESCRICAO|| '')
        formData.append('DATA', auxValues.DATA|| '')
        if (arquivo.file){
          formData.append('arquivo', arquivo.file, arquivo.arquivo)
          console.log(arquivo)
        }
        formData.append('LINK', auxValues.LINK)
        // console.log(auxValues)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/catalogos/edit',  formData ).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    history.push('/macropecas_app/catalogos')
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/catalogos/add', formData).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    history.push('/macropecas_app/catalogos')
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        history.push('/macropecas_app/catalogos')
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  function handleArquivo(e) {
    let file = e.target.files[0];
    let i = {...arquivo}
    console.log(file)
    if (file) {
        i.arquivo = file.name
        i.url = URL.createObjectURL(file)
        i.file = file
        setArquivo(i)
      }
    
  }
  function handleSelectFile(e) {
		e.preventDefault();
		$('#html_btn_foto').click();
	}


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Caatálogos</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <div style={{ display: 'flex'}}>

            <FrDatePicker
              value={(data.DATA?.split('T')[0] || '')}
              name="DATA"
              id="DATA"
              color="#528b46"
              label="Data"
              variant="outlined"
              onChange={handleChange}
              size="small"
              style={{ width: 190 }}
              />
            <FrTextInput
              maxLength='100'
              value={data.DESCRICAO || ''}
              onChange={handleChange}
              name="DESCRICAO"
              id="DESCRICAO"
              color="#389492"
              label="Descrição"
              variant="outlined"
              size="small"
              required
              style={{ width: 500 }}
              />
          </div >
          <div style={{ display: 'flex'}}>

            <FrSelect
              value={data.FK_REP || ''}
              style={{ width: 400 }}
              name="FK_REP"
              id="FK_REP"
              label='Representada'
              data={comboRep}
              onChange={handleChange} 
            />


            <FrDatePicker
              value={(data.DATA_CADASTRO?.split('T')[0] || '')}
              name="DATA_CADASTRO"
              id="DATA_CADASTRO"
              color="#528b46"
              label="Data Cadastro"
              variant="outlined"
              onChange={handleChange}
              size="small"
              style={{ width: 190 }}
            />

            <FrSelect
              value={data.ATIVO || ''}
              style={{ width: 200 }}
              name="ATIVO"
              id="ATIVO"
              label='Ativo'
              data={comboAtivo}
              onChange={handleChange} 
            />

          </div>
          <div style={{ display: 'flex'}}>
            <FrTextInput
              maxLength='100'
              value={data.LINK || ''}
              onChange={handleChange}
              name="LINK"
              id="LINK"
              color="#389492"
              label="Link"
              variant="outlined"
              size="small"
              required
              style={{ width: 700 }}
            />

          </div>
          <div style={{ display: 'flex'}}>
            <input
              type="file"
              
              name="arquivo"
              id={"html_btn_foto"}
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={handleArquivo}
              hidden
            />
            <button
                className="ModalButton"
                type="file"
                onClick={handleSelectFile}
                style={{ width: '140px', color: 'black'}}
              >Escolher Arquivo</button>
            {arquivo.arquivo || "Nenhum arquivo escolhido"}
            {/* <button onClick={handleCancel}>Arquivo</button> */}
          </div>

         
        </form>
        <div className="regButtonsDiv">
          {(enableEdit === 'S') ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{(enableEdit === 'S') ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Catalogos;
