import React, { useContext, useEffect, useState } from 'react';
import { Icon } from 'react-icons-kit'
import { userCircle } from 'react-icons-kit/fa/userCircle'
import { ic_exit_to_app } from 'react-icons-kit/md/ic_exit_to_app'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ThemeContext } from '../../App';
import {colors} from '../../utils/vars'
import DefineTema from '../DefineTema'

import './index.css';

const swal = withReactContent(Swal)

function UserOptions(props) {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authMacroApp'] || JSON.stringify({ authenticated: false, user: 0 })));
    const {temaEscuro, setTemaEscuro} = useContext(ThemeContext)


    function trocaTema (e, propriedades) {
        e.preventDefault()
        console.log(localStorage['authMacroApp'])
        let obj = JSON.parse(localStorage['authMacroApp'])
        if(obj.temaEscuro){
            obj.temaEscuro = false
        } else {
            obj.temaEscuro = true
        }
        localStorage['authMacroApp'] = JSON.stringify(obj)
        setAuth(obj)
        setTemaEscuro(obj.temaEscuro)
    }

    function logout(e) {
        e.preventDefault();
        e.preventDefault();
        swal.fire({
        title: 'Deseja sair do sistema?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                localStorage['authMacroApp'] =  JSON.stringify({ authenticated: false, user: 0 })
                window.location.href = '/macropecas_app/login'
            }
        })
 
    }

    return (
        <div className="userOptions">
            {/* <DefineTema temaEscuro={temaEscuro} setTemaEscuro={setTemaEscuro} /> */}
            <Icon icon={userCircle} size={24} style={{ marginRight: '5px', marginLeft: '5px' }}/>
            <div className="userName">{auth.name}</div>
            <button onClick={logout}>
                <Icon icon={ic_exit_to_app} size={24} />
            </button>
        </div>
    );
}

export default UserOptions;