
import { useCallback, useEffect } from 'react'
import {useDropzone} from 'react-dropzone'
import Icon from 'react-icons-kit'
import {cloudUpload} from 'react-icons-kit/icomoon/cloudUpload'
import {fileText2} from 'react-icons-kit/icomoon/fileText2'

export function FrDragFile(props){

    console.log(props.value)
    const onDrop = useCallback(AccepetedFiles => {

    }, [])
    
    const {getRootProps, acceptedFiles, getInputProps, isDragActive} = useDropzone({onDrop, onChange: () => console.log('oiii')})
  
    const selectedFile = acceptedFiles[0]
  
    console.log(selectedFile, isDragActive)

    useEffect(() => {
        if (selectedFile){
            props.onChange(selectedFile)
        }
    }, [selectedFile])

    return  <div {...getRootProps()} style={{display: 'flex', flexDirection: 'column', gap: 10, flexWrap: 'wrap',
                                                                justifyContent: 'center', height: 150, 
                                                                alignItems: 'center', borderWidth: 1, 
                                                                borderStyle: 'dotted', borderColor:'black',
                                                                borderRadius: 4, width: '100%'}}>
                <input 
                    onChange={() => {console.log('oieiiie')}}
                    {...getInputProps()} 
                />
                
                {
                    props.value ?
                    <>
                        <Icon icon={fileText2} size={26} />
                        <p>{props.value.name}</p> 
                    </>
                    :
                    isDragActive ?

                    <p>Solte o arquivo aqui ...</p> :
                    <>
                        <Icon icon={cloudUpload} size={26} />
                        <p>Arraste o arquivo aqui, ou clique para selecionar</p>
                    </>
                }
            </div>
}