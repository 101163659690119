import Dialog from '@material-ui/core/Dialog';
import {
    makeStyles,
    withStyles,
    createTheme,
    ThemeProvider,
  } from "@material-ui/core/styles";

const StyledDialog = withStyles(({theme}) => ({
    "& .MuiDialog-container": {
      alignItems: "flex-start"
    }
}))(Dialog);

export default function FrModalConfirmaPed(props){

    return (

        <StyledDialog
            open={props.open}
            fullWidth={true} 
            maxWidth={'xs'}
        >
            {props.children}
        </StyledDialog>
    )

}