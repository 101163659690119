import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import Nav from 'react-bootstrap/Nav'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'

export function NavBarLink(props){

    return (
        <Tooltip title={props.hint || props.caption }>
            <Link to={"/macropecas_app"+props.path} className={(props.hide ? 'hide-' : '') + "toggleMenuItem"}>
                <Icon icon={ic_chevron_right} size={15} />
                {props.hide ? '' : <strong style={{ paddingLeft: '5px' }}>{props.caption}</strong>}
            </Link>
        </Tooltip>
    )
}